import { Icons, IconSize } from '@pdcfrontendui/components';
import AccordionContent from '@pdcfrontendui/components/AccordionItem/AccordionContent';
import { TeamShiftStatusEnum } from '../../api/enumLib_api';
import { Person, Team, TeamShift } from '../../api/TeamPlan_api';
import { Item } from '../../components';
import ids from '../../testing/ids';
import { currentLanguage } from '../../currentLanguage';
import classNames from 'classnames';
import scss from './ActionRequiredCard.module.scss';

/**
 * Should always be rendered
 */
export function ActionRequiredCard({
  team,
  shift,
  employee,
  className,
  children,
  callFindSubstitute,
  callMarkAsHandled,
  openOfferView,
  isCreatedShift,
  isAssigningShift,
  openEditShift,
}: {
  team: Team;
  shift: TeamShift;
  employee?: Person;
  children: React.ReactNode;
  isCreatedShift: boolean;
  className?: string;
  isAssigningShift: boolean;
  openOfferView: () => void;
  callFindSubstitute: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callMarkAsHandled: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  openEditShift?: () => void;
}) {
  const expanded =
    shift.status === TeamShiftStatusEnum.actionRequired &&
    !isCreatedShift &&
    !isAssigningShift;

  const isFictive = !!employee?.isFictive;

  const assigningCreated = isCreatedShift || isFictive;

  const showOfferOnShiftExchange = !isCreatedShift || isFictive;
  const showFindSubstitute = true;
  const showEditAndFindSubstitute = true;
  const showMarkAsHandled = !assigningCreated;
  return (
    <AccordionContent
      expanded={expanded}
      id=""
      outerClass={classNames(scss.comp, className, !expanded && scss.hidden)}
      innerClass={scss.inner}
      fadeStartMult={0.9}
      duration={500}
    >
      {children}
      {showOfferOnShiftExchange && (
        <Item
          className={classNames(scss.action, !team.canOffer && scss.disabled)}
          id={ids.ShiftView.exchange}
          onClick={!team.canOffer ? undefined : openOfferView}
          ariaLabel={currentLanguage.offerOnShiftExchange}
        >
          <div className={scss.label}>
            <Icons.Swap size={IconSize.XSmall} className={scss.icon} />
            {currentLanguage.offerOnShiftExchange}
          </div>
          {!team.canOffer && (
            <div className={scss.warning}>
              {currentLanguage.MissingSwapGroup_1(
                employee?.initials.toUpperCase() ||
                  employee?.name ||
                  currentLanguage.Employee
              )}
            </div>
          )}
        </Item>
      )}
      {showFindSubstitute && (
        <Item
          className={scss.action}
          id={ids.ShiftView.substitute}
          onClick={() => {
            callFindSubstitute(team.id, shift.id, shift.personId);
          }}
          ariaLabel={
            assigningCreated
              ? currentLanguage.FindEmployee
              : currentLanguage.findSubstitute
          }
        >
          <div className={scss.label}>
            <Icons.User size={IconSize.XSmall} className={scss.icon} />
            {assigningCreated
              ? currentLanguage.FindEmployee
              : currentLanguage.findSubstitute}
          </div>
        </Item>
      )}
      {showEditAndFindSubstitute && openEditShift && (
        <Item
          ariaLabel={currentLanguage.EditAndFindSubstitute}
          className={scss.action}
          onClick={openEditShift}
        >
          <div className={scss.label}>
            <Icons.Edit size={IconSize.XSmall} />
            {currentLanguage.EditAndFindSubstitute}
          </div>
        </Item>
      )}
      {showMarkAsHandled && (
        <Item
          className={scss.action}
          id={ids.ShiftView.handled}
          onClick={() => {
            callMarkAsHandled(team.id, shift.id, shift.personId);
          }}
          ariaLabel={currentLanguage.markAsHandled}
        >
          <div className={scss.label}>
            <Icons.Checkmark size={IconSize.XSmall} className={scss.icon} />
            {currentLanguage.markAsHandled}
          </div>
        </Item>
      )}
    </AccordionContent>
  );
}
