import { attemptSendNotificationLog } from '../appActions';

import { ThunkDispatch } from '../types';
import getApi from '../getApi';
import { FavoriteStatusEnum, NotificationStatusEnum } from '../api/enumLib_api';
import { attemptLoadTeams } from '../ChooseTeam/ChooseTeamAction';

export type MenuAction =
  | {
      type: 'SET_FAVORITE_STATUS';
      teamId: string;
      status: FavoriteStatusEnum;
    }
  | {
      type: 'SET_FAVORITE_STATUS_SUCCESS';
      teamId: string;
      status: FavoriteStatusEnum;
    }
  | {
      type: 'SET_FAVORITE_STATUS_FAILURE';
      teamId: string;
      errorMessage: string;
    }
  | {
      type: 'SET_NOTIFICATION_STATUS';
      teamId: string;
      status: NotificationStatusEnum;
    }
  | {
      type: 'SET_NOTIFICATION_STATUS_SUCCESS';
      teamId: string;
      status: NotificationStatusEnum;
    }
  | {
      type: 'SET_NOTIFICATION_STATUS_FAILURE';
      teamId: string;
      errorMessage: string;
    };

export const setFavoriteStatus = (
  teamId: string,
  status: FavoriteStatusEnum
): MenuAction => ({
  type: 'SET_FAVORITE_STATUS',
  teamId,
  status,
});

export const setFavoriteStatusSuccess = (
  teamId: string,
  status: FavoriteStatusEnum
): MenuAction => ({
  type: 'SET_FAVORITE_STATUS_SUCCESS',
  teamId,
  status,
});

export const setFavoriteStatusFailure = (
  teamId: string,
  errorMessage: string
): MenuAction => ({
  type: 'SET_FAVORITE_STATUS_FAILURE',
  teamId,
  errorMessage,
});

export function attemptSetFavoriteStatus(
  teamId: string,
  status: FavoriteStatusEnum
) {
  return (dispatch: ThunkDispatch) => {
    return getApi()
      .setFavoriteStatus(teamId, status)
      .then((team) => {
        void dispatch(attemptLoadTeams());
        dispatch(setFavoriteStatusSuccess(team.id, team.favoriteStatus));
      })
      .catch((err) => {
        dispatch(setFavoriteStatusFailure(teamId, err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export const setNotificationStatus = (
  teamId: string,
  status: NotificationStatusEnum
): MenuAction => ({
  type: 'SET_NOTIFICATION_STATUS',
  teamId,
  status,
});

export const setNotificationStatusSuccess = (
  teamId: string,
  status: NotificationStatusEnum
): MenuAction => ({
  type: 'SET_NOTIFICATION_STATUS_SUCCESS',
  teamId,
  status,
});

export const setNotificationStatusFailure = (
  teamId: string,
  errorMessage: string
): MenuAction => ({
  type: 'SET_NOTIFICATION_STATUS_FAILURE',
  teamId,
  errorMessage,
});

export function attemptSetNotificationStatus(
  teamId: string,
  status: NotificationStatusEnum
) {
  return (dispatch: ThunkDispatch) => {
    return getApi()
      .setNotificationStatus(teamId, status)
      .then((team) => {
        void dispatch(attemptLoadTeams());
        dispatch(
          setNotificationStatusSuccess(team.id, team.notificationStatus)
        );
      })
      .catch((err) => {
        dispatch(setNotificationStatusFailure(teamId, err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}
