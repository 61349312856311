import { LangLanguageEnum } from './enums';

export interface Language {
    languageCode: string;
    capitalizeMonths: boolean;
    capitalizeWeekdays: boolean;
    languageEnum: LangLanguageEnum;

    AndXOtherShifts_1(x:string|number) :string;
    ChangedToXHasBeenSaved_1(x:string|number) :string;
    ChatWith_1(x:string|number) :string;
    ConfirmOfferOnShiftExchangeAllAvailable_1(x:string|number) :string;
    ConfirmOfferOnShiftExchangeCandidateFilter_1(x:string|number) :string;
    DeviatingPayerNotificationBody_1(x:string|number) :string;
    DeviatingWorkplacePleaseNote_1(x:string|number) :string;
    HandoverApproveRejectText_1(x:string|number) :string;
    IsNowCreated_1(x:string|number) :string;
    MessageModal_1(x:string|number) :string;
    MessageModalRead_1(x:string|number) :string;
    MessageModalUnread_1(x:string|number) :string;
    MessagesEmptyStateDescription_1(x:string|number) :string;
    MissingSwapGroup_1(x:string|number) :string;
    NumApplicantsFromShiftExchange_1(x:string|number) :string;
    OfferShiftTo_1(x:string|number) :string;
    ShiftTypeNotAvailablePleaseNote_1(x:string|number) :string;
    UnfollowDialogBody_1(x:string|number) :string;
    ConfirmEndAbsence_2(x:string|number, y:string|number) :string;
    ConfirmShiftExchangeContent_2(x:string|number, y:string|number) :string;
    EndAbsenceMultiPleaseNote_2(x:string|number, y:string|number) :string;
    IsNowAssigned_2(x:string|number, y:string|number) :string;
    NoChangesHaveBeenMade_2(x:string|number, y:string|number) :string;
    NothingPlannedForGroup_2(x:string|number, y:string|number) :string;
    NothingToTakeActionOn_2(x:string|number, y:string|number) :string;
    ReadMessagesEmptyStateDescription_2(x:string|number, y:string|number) :string;
    RegisteredAbsent_2(x:string|number, y:string|number) :string;
    SwapApproveRejectText_2(x:string|number, y:string|number) :string;
    UnreadMessagesEmptyStateDescription_2(x:string|number, y:string|number) :string;
    ConfirmRemoveShiftBody_3(x:string|number, y:string|number, z:string|number) :string;
    RemoveShiftToast_3(x:string|number, y:string|number, z:string|number) :string;
    absence_plural :string;
    absenceType :string;
    accept :string;
    Activities :string;
    ActivitiesMightBeDeleted :string;
    ActivitiesMightBeDeletedPleaseNote :string;
    ActivitiesWillBeDeleted :string;
    ActivitiesWillBeDeletedPleaseNote :string;
    AddEndDate :string;
    additionalInformation :string;
    AddNote :string;
    Alert :string;
    all :string;
    allEmployees :string;
    AllowNotifications :string;
    alsoShifts :string;
    and :string;
    And1OtherShift :string;
    appInformation :string;
    Applicants :string;
    Approve :string;
    ApproveRejectHandover :string;
    ApproveRejectSwap :string;
    assignShift :string;
    auditActionAcceptShiftExchange :string;
    auditActionCalendar :string;
    auditActionCancelExchange :string;
    auditActionChangeTeam :string;
    auditActionInit :string;
    auditActionSelectShift :string;
    auditActionToggleAllowNotifications :string;
    AvailableFictivePerson :string;
    AwaitingApproveHandover :string;
    AwaitingApproveSwap :string;
    Back :string;
    back :string;
    callList :string;
    Cancel :string;
    CancelOffer :string;
    cancelOfferOnShiftExchange :string;
    candidates :string;
    candidates_short :string;
    caretDown :string;
    ChangeOrganization :string;
    ChangePersonelGroup :string;
    Changes :string;
    ChangeTeam :string;
    Chat :string;
    ChatAndInternalMessages :string;
    ChatWithEmployee :string;
    check :string;
    chooseAbsenceType :string;
    ChooseApplicants :string;
    ChooseGroups :string;
    chooseLanguage :string;
    ChoosePredefined :string;
    chooseTeam :string;
    clear :string;
    Close :string;
    CloseMenu :string;
    ConfirmCancellationOfOffer :string;
    ConfirmCancellationOfOfferContent :string;
    ConfirmKeepChanges :string;
    ConfirmLeavePage :string;
    ConfirmLeavePageContentCreated :string;
    ConfirmLeavePageContentEdited :string;
    ConfirmLogout :string;
    ConfirmMarkAsHandled :string;
    ConfirmMarkAsHandledContent :string;
    ConfirmOfferExchangeCandidatesHelperText :string;
    ConfirmOfferOnShiftExchange :string;
    ConfirmOfferOnShiftExchangeAllAvailable :string;
    ConfirmOfferOnShiftExchangeCandidateFilter :string;
    ConfirmOfferOnShiftExchangeContent :string;
    ConfirmRemoveShiftBodyHandover :string;
    ConfirmRemoveShiftTitle :string;
    ConfirmSaveChanges :string;
    ConfirmShiftAssignment :string;
    ConfirmShiftAssignmentContent :string;
    ConfirmShiftExchange :string;
    Copy :string;
    CopyDetails :string;
    CopyErrorTextAndContactSupport :string;
    Create :string;
    CreateNew :string;
    CreateNewShift :string;
    Date :string;
    Delete :string;
    DeletedDueToShiftTypeChange :string;
    DeviatingDutylinesWillBeAffectedExplanation :string;
    DeviatingDutylinesWillBeDeleted :string;
    DeviatingDutylinesWillBeDeletedExplanation :string;
    DeviatingPayerNotificationTitle :string;
    DeviatingWorkplace :string;
    Duties :string;
    Edit :string;
    EditAndFindSubstitute :string;
    EditShift :string;
    Employee :string;
    employeeDefiniteArticle :string;
    EndAbsence :string;
    EndDate :string;
    EndImperative :string;
    enterPassword :string;
    enterUserName :string;
    Error :string;
    ErrorCode :string;
    exchange :string;
    exchangeCandidateFilter :string;
    exchangeCandidateFilter_callinEsque :string;
    exchangeCandidateFilter_classic :string;
    exchangeCandidateFilter_noCallin :string;
    expectedToBeBack :string;
    Eye :string;
    FetchData :string;
    FictiveEmployees :string;
    FictivePerson :string;
    FilterOutEmployeesWith :string;
    FindEmployee :string;
    findSubstitute :string;
    FollowGroup :string;
    FormerAssignee :string;
    From :string;
    FromHours :string;
    FromMinutes :string;
    GoToSwap :string;
    Group :string;
    Groups :string;
    GroupsFollowed :string;
    Hello :string;
    HhMmSeparator :string;
    HowMany :string;
    HowManyRead :string;
    HowManyUnread :string;
    HttpStatus :string;
    Info :string;
    InternalError :string;
    InternalMessage :string;
    InternalMessages :string;
    Keep :string;
    KeepChanges :string;
    KeepExchange :string;
    KeepOffer :string;
    Language :string;
    languageName :string;
    LeftArrow :string;
    Loading :string;
    LoadingShifts :string;
    loadMoreDays :string;
    Location :string;
    Lock :string;
    login :string;
    LoginLoadingData :string;
    loginTitleWelcome :string;
    Logout :string;
    mail :string;
    ManyPersonGroupsContent :string;
    ManyPersonGroupsTitle :string;
    markAsHandled :string;
    MaxLengthError :string;
    MeAlt :string;
    Menu :string;
    MessagesEmptyStateTitle :string;
    ModifiedDuties :string;
    ModifiedDuty :string;
    MomentLoadingData :string;
    Month_Array :string[];
    netError :string;
    NetworkErrorOccured :string;
    New :string;
    Next :string;
    No :string;
    NoAccessToTeamPlan :string;
    noAppInformation :string;
    NoCandidates :string;
    NoCandidatesLong :string;
    NoChanges :string;
    NoChangesHaveBeenMade :string;
    noDepartmentsSelected :string;
    noDepartmentsToShow :string;
    noEmployeeToShow :string;
    NoOffersInSearch :string;
    NoPersonelGroupFollowedTextMobile :string;
    NoPersonelGroupFollowedTextWeb :string;
    NoPersonelGroupFollowedTitle :string;
    Norm :string;
    NoShiftMatch :string;
    noShiftsToShow :string;
    noSortedCallListToShow :string;
    NotAssignable :string;
    Note :string;
    NothingPlanned :string;
    notification :string;
    NotificationsOff :string;
    NotificationsOn :string;
    NoUnresolvedIssues :string;
    Offer :string;
    offerOnShiftExchange :string;
    OfferShift :string;
    OfferWillBeCancelled :string;
    OfferWillBeCancelledPleaseNote :string;
    ongoingAbsence :string;
    onThisDay :string;
    Original :string;
    OriginalBelongingTo :string;
    OtherEmployees :string;
    OtherGroups :string;
    Overview :string;
    password :string;
    passwordIsRequired :string;
    Pending :string;
    PersonelGroupInfoBody1 :string;
    PersonelGroupInfoBody2NonTouch :string;
    PersonelGroupInfoBody2Touch :string;
    PersonelGroupInfoBodyTouchSymbolExplanation :string;
    PersonelGroupInfoBodyTouchSymbolExplanationFetchData :string;
    PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat :string;
    PersonelGroupInfoBodyTouchSymbolExplanationUnresolved :string;
    PersonelGroupInfoTitle :string;
    PersonGroups :string;
    PersonGroupsNotLoaded :string;
    phone :string;
    Planned :string;
    Planner :string;
    PleaseNoteTheFollowingDuties :string;
    Problem :string;
    Read :string;
    ReadMessagesAndNotes :string;
    ReadMessagesEmptyStateTitle :string;
    ReadPluralPastTense :string;
    RecentlyUsedShiftDefs :string;
    RegardingShort :string;
    registerAbsence :string;
    Reject :string;
    RejectShiftAssignment :string;
    RejectShiftAssignmentContent :string;
    RejectShiftExchange :string;
    RejectShiftExchangeContent :string;
    reload :string;
    RemoveShift :string;
    ResponseFromServer :string;
    responsibility :string;
    RightArrow :string;
    RuleBreak :string;
    RuleViolationInfo :string;
    RuleViolationInfoApplicants :string;
    RuleViolationInfoSortedCallList :string;
    Save :string;
    SaveChanges :string;
    search :string;
    SelectAbsenceBack :string;
    SelectAbsenceType :string;
    SelectLanguage :string;
    SelectShiftType :string;
    SelectThePersonGroup :string;
    SelectTheShift :string;
    settings :string;
    shift :string;
    shift_plural :string;
    ShiftHoursHaveBeenModified :string;
    ShiftStatusAbsent :string;
    ShiftStatusActionRequired :string;
    ShiftStatusNoActivities :string;
    ShiftStatusOffered :string;
    ShiftStatusPlanned :string;
    ShiftStatusReplaced :string;
    ShiftStatusSubstitute :string;
    ShiftTypeNotAvailable :string;
    ShiftTypeNotAvailableExplanation :string;
    somethingWentWrong :string;
    StarShift :string;
    StartOrEndTimeHasBeenChanged :string;
    Status :string;
    Tasks :string;
    Team :string;
    teamPlan :string;
    TheErrorMessageWasCopied :string;
    TheSpecifiedTimeOverlapsWith :string;
    TheSpecifiedTimeOverlapsWithTheFollowing :string;
    TheSpecifiedTimesOverlapWith :string;
    TheSpecifiedTimesOverlapWithTheFollowing :string;
    ThisShiftHasBeenModified :string;
    To :string;
    today :string;
    ToHours :string;
    ToMinutes :string;
    TooManyGroupsAlertBody :string;
    TooManyGroupsAlertTitle :string;
    Type :string;
    UnfollowDialogTitle :string;
    UnfollowGroup :string;
    Unread :string;
    UnreadMessagesAndNotes :string;
    UnreadMessagesEmptyStateTitle :string;
    UnreadPluralPastTense :string;
    Url :string;
    User :string;
    userName :string;
    userNameIsRequired :string;
    userNameNoBlackSpacesAtStartOrEnd :string;
    VersionNotSupported :string;
    Week :string;
    Weekday_Array :string[];
    WeekdayInitials_Array :string[];
    Weeks :string;
    WhereToFindDepartments :string;
    WillBeDeletedWithTheShift :string;
    WriteMessage :string;
    Yes :string;
    YesLeavePage :string;
    YesLogout :string;
    YourAppIsHavingProblemsWithTheServer :string;
}

const Language_En: Language = {
    languageCode: "En",
    capitalizeMonths: true,
    capitalizeWeekdays: true,
    languageEnum: LangLanguageEnum.En,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " other shifts."; },
ChangedToXHasBeenSaved_1: (x:string|number) => { return "The changes to " + x + " have been saved."; },
ChatWith_1: (x:string|number) => { return "Chat with " + x + ""; },
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Are you sure you want to offer the shift to all available staff in the " + x + " selected personel groups?"; },
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Are you sure you want to offer the shift in the " + x + " selected personel groups?"; },
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Please note that this shift is registered at \"" + x + "\"."; },
DeviatingWorkplacePleaseNote_1: (x:string|number) => { return "Please note that this shift is registered at \"" + x + "\"."; },
HandoverApproveRejectText_1: (x:string|number) => { return "Choose the applicant who should get " + x + "'s shift."; },
IsNowCreated_1: (x:string|number) => { return "" + x + " is now created."; },
MessageModal_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves unread messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the unread messages of this period will also be displayed here."; },
MessageModalRead_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves read messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the read messages of this period will also be displayed here."; },
MessageModalUnread_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves unread messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the unread messages of this period will also be displayed here."; },
MessagesEmptyStateDescription_1: (x:string|number) => { return "No messages have been received or sent in the staff group that relate to the next " + x + " days."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " has no swap group"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " applicants from shift exchange"; },
OfferShiftTo_1: (x:string|number) => { return "Offer shift to " + x + ""; },
ShiftTypeNotAvailablePleaseNote_1: (x:string|number) => { return "Please note that changing the shift type from \"" + x + "\" cannot be reverted after saving changes."; },
UnfollowDialogBody_1: (x:string|number) => { return "Are you sure, that you no longer wish to follow the group \"" + x + "\"?"; },
ConfirmEndAbsence_2: (x:string|number, y:string|number) => { return "Are you sure you wish to end " + x + "'s absence effective " + y + "?"; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Are you sure you want to approve the shift exchange between " + x + " and " + y + "?"; },
EndAbsenceMultiPleaseNote_2: (x:string|number, y:string|number) => { return "Please note that you have also ended " + x + "'s absence: " + y + ""; },
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " is now assigned to " + y + "."; },
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "No shifts have been changed for the period\n" + x + " - " + y + ""; },
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "There are no scheduled shifts for the period\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "There are no pending issues for the period\n" + x + " - " + y + ""; },
ReadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "There are no read messages concerning the period " + x + " - " + y + ""; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Please note that you have also registered " + x + " as absent: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " and " + y + " would like to swap shifts."; },
UnreadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "There are no unread messages concerning the period " + x + " - " + y + ""; },
ConfirmRemoveShiftBody_3: (x:string|number, y:string|number, z:string|number) => { return "Are you sure that you would like to delete " + x + "'s shift \"" + y + "\" " + z + ""; },
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "'s shift \"" + y + "\" " + z + " is now deleted."; },
absence_plural: "Absence",
absenceType: "Absence type",
accept: "Accept",
Activities: "Activities",
ActivitiesMightBeDeleted: "Activities might be deleted",
ActivitiesMightBeDeletedPleaseNote: "Please note that the following activities might be deleted if they are no longer covered by a shift:",
ActivitiesWillBeDeleted: "Activities will be deleted",
ActivitiesWillBeDeletedPleaseNote: "Please note that the following activities will be deleted:",
AddEndDate: "Add end date",
additionalInformation: "Additional information",
AddNote: "Internal messages",
Alert: "Advarsel",
all: "All",
allEmployees: "All employees",
AllowNotifications: "Allow notifications",
alsoShifts: "also shifts",
and: "and",
And1OtherShift: "+ 1 other shift.",
appInformation: "App Information",
Applicants: "Applicants",
Approve: "Approve",
ApproveRejectHandover: "Approve/Reject hand over",
ApproveRejectSwap: "Approve/Reject shift swap",
assignShift: "Assign shift",
auditActionAcceptShiftExchange: "Accept shift exchange",
auditActionCalendar: "Calendar",
auditActionCancelExchange: "Cancel shift exchange",
auditActionChangeTeam: "Change person group",
auditActionInit: "init",
auditActionSelectShift: "Select shift",
auditActionToggleAllowNotifications: "Toggle allow notifications",
AvailableFictivePerson: "Available fictive person",
AwaitingApproveHandover: "Awaiting approval of hand over",
AwaitingApproveSwap: "Awaiting approval of shift swap",
Back: "Back",
back: "Back",
callList: "Sorted call list",
Cancel: "Cancel",
CancelOffer: "Cancel offer",
cancelOfferOnShiftExchange: "Cancel offer on shift exchange",
candidates: "Candidates",
candidates_short: "Cand..",
caretDown: "Down arrow",
ChangeOrganization: "Change organization",
ChangePersonelGroup: "Change person group",
Changes: "Changes",
ChangeTeam: "Change team",
Chat: "Chat",
ChatAndInternalMessages: "Chat and internal messages",
ChatWithEmployee: "Chat with employee",
check: "Check",
chooseAbsenceType: "Choose absence type",
ChooseApplicants: "Choose applicants",
ChooseGroups: "Choose the personnel groups to whom you want to offer the shift.",
chooseLanguage: "Choose language",
ChoosePredefined: "Choose a predefined shift",
chooseTeam: "Choose person group",
clear: "Clear",
Close: "Close",
CloseMenu: "Close menu",
ConfirmCancellationOfOffer: "Confirm cancellation of offer",
ConfirmCancellationOfOfferContent: "Are you sure you want to cancel the offer of this shift for all personnel?",
ConfirmKeepChanges: "Are you sure you want to keep the changes?",
ConfirmLeavePage: "Are you sure you want to leave the page?",
ConfirmLeavePageContentCreated: "If you leave the page now, then the shift that you were creating will be deleted.",
ConfirmLeavePageContentEdited: "If you leave the page now without saving, all changes will be lost.",
ConfirmLogout: "Are you sure you want to log out?",
ConfirmMarkAsHandled: "Confirm mark as handled",
ConfirmMarkAsHandledContent: "Are you sure you want to mark this as handled without finding a replacement?",
ConfirmOfferExchangeCandidatesHelperText: "Filter which employees are not offered the shift.",
ConfirmOfferOnShiftExchange: "Confirm offer on shift exchange",
ConfirmOfferOnShiftExchangeAllAvailable: "Are you sure you want to offer the shift to all available staff in the selected personel group?",
ConfirmOfferOnShiftExchangeCandidateFilter: "Are you sure you want to offer the shift in the selected personel group?",
ConfirmOfferOnShiftExchangeContent: "Are you sure you want to offer this shift on the shift exchange for all available personnel?",
ConfirmRemoveShiftBodyHandover: "The offer on the shift exchange is also canceled as a result.",
ConfirmRemoveShiftTitle: "Delete shift",
ConfirmSaveChanges: "Are you sure you want to save the changes?",
ConfirmShiftAssignment: "Confirm shift assignment",
ConfirmShiftAssignmentContent: "Are you sure you want to assign this shift to",
ConfirmShiftExchange: "Confirm approval of exchange",
Copy: "Copy",
CopyDetails: "Copy details",
CopyErrorTextAndContactSupport: "Help to solve the issue. Copy the details below and send them to IT support at your workplace",
Create: "Create",
CreateNew: "Create new",
CreateNewShift: "Create new shift",
Date: "Date",
Delete: "Delete",
DeletedDueToShiftTypeChange: "Will be deleted due to change of shift type:",
DeviatingDutylinesWillBeAffectedExplanation: "Your change in shift hours will affect the following duties:",
DeviatingDutylinesWillBeDeleted: "Deviating duties will be deleted",
DeviatingDutylinesWillBeDeletedExplanation: "Will be deleted due to change of shift type:",
DeviatingPayerNotificationTitle: "Deviating workplace",
DeviatingWorkplace: "Deviating workplace",
Duties: "Duties",
Edit: "Edit",
EditAndFindSubstitute: "Edit the shift and find substitute",
EditShift: "Edit shift",
Employee: "Employee",
employeeDefiniteArticle: "the employee",
EndAbsence: "End absence",
EndDate: "End date",
EndImperative: "End",
enterPassword: "Enter password",
enterUserName: "Enter username",
Error: "Error",
ErrorCode: "Error code",
exchange: "Exchange",
exchangeCandidateFilter: "Candidate filter",
exchangeCandidateFilter_callinEsque: "Shifts and protected absence (more cand..)",
exchangeCandidateFilter_classic: "Shifts and absence (fewest candidates)",
exchangeCandidateFilter_noCallin: "Protected absence (most candidates)",
expectedToBeBack: "Expected to be back",
Eye: "Eye",
FetchData: "Fetch data without opening",
FictiveEmployees: "Fictive employees",
FictivePerson: "Fictive person",
FilterOutEmployeesWith: "Filter out employees with...",
FindEmployee: "Find employee",
findSubstitute: "Find substitute",
FollowGroup: "Follow group",
FormerAssignee: "Former Assignee",
From: "From",
FromHours: "From hours",
FromMinutes: "From minutes",
GoToSwap: "Go to the shift exchange",
Group: "Group",
Groups: "Groups",
GroupsFollowed: "Groups you follow",
Hello: "Hello",
HhMmSeparator: ":",
HowMany: "How many unread are being loaded?",
HowManyRead: "How many read have been loaded?",
HowManyUnread: "How many unread have been loaded?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "An unexpected error occured. The error has been reported to our operations department.",
InternalMessage: "Internal message",
InternalMessages: "Internal messages",
Keep: "Keep",
KeepChanges: "Keep changes",
KeepExchange: "Keep exchange",
KeepOffer: "Keep offer",
Language: "Language",
languageName: "English",
LeftArrow: "Left arrow",
Loading: "Loading",
LoadingShifts: "Loading shifts",
loadMoreDays: "Load more days",
Location: "Location",
Lock: "Lock",
login: "Log in",
LoginLoadingData: "Just a moment,\nyour app is loading data",
loginTitleWelcome: "Welcome to",
Logout: "Logout",
mail: "Mail",
ManyPersonGroupsContent: "You have access to more than 10 person groups, and therefore the app loads data for a person group when it is opened.",
ManyPersonGroupsTitle: "Many person groups",
markAsHandled: "Mark as handled",
MaxLengthError: "You cannot type any more characters",
MeAlt: "Me",
Menu: "Menu",
MessagesEmptyStateTitle: "No messages",
ModifiedDuties: "Modified duties",
ModifiedDuty: "Modified duty",
MomentLoadingData: "Just a moment, we're loading data",
Month_Array: ["January","February","March","April","May","June","July","August","September","October","November","December"],
netError: "Sorry, there is a problem with the network, status:",
NetworkErrorOccured: "A network error occured",
New: "New",
Next: "Next",
No: "No",
NoAccessToTeamPlan: "Unfortunately, you do not have access to TeamPlan.",
noAppInformation: "No App Information found",
NoCandidates: "No ideal candidates",
NoCandidatesLong: "Unfortunately, there are no ideal candidates to show in the sorted call list.",
NoChanges: "No changes",
NoChangesHaveBeenMade: "No shifts have been changed for this person group.",
noDepartmentsSelected: "No person groups selected",
noDepartmentsToShow: "No person groups to show",
noEmployeeToShow: "No employee to show",
NoOffersInSearch: "There is no groups that match your search.",
NoPersonelGroupFollowedTextMobile: "Swipe left on a group and choose",
NoPersonelGroupFollowedTextWeb: "Hover over a group and press the three dots to follow.",
NoPersonelGroupFollowedTitle: "You are not following any groups",
Norm: "Norm",
NoShiftMatch: "There are no shifts\nthat matches your search.",
noShiftsToShow: "No shifts with unread messages to show",
noSortedCallListToShow: "No candidates in the call list",
NotAssignable: "Not assignable",
Note: "Note",
NothingPlanned: "Nothing planned",
notification: "Notification",
NotificationsOff: "Disable notifications",
NotificationsOn: "Enable notifications",
NoUnresolvedIssues: "No unresolved issues",
Offer: "Offer",
offerOnShiftExchange: "Offer on shift exchange",
OfferShift: "Offer shift",
OfferWillBeCancelled: "Offer will be cancelled",
OfferWillBeCancelledPleaseNote: "Please note that the shift is being offered on shift exchange. If the shift is deleted, the offer is cancelled.",
ongoingAbsence: "Ongoing Absence",
onThisDay: "on this day",
Original: "Original",
OriginalBelongingTo: "Original belonging to",
OtherEmployees: "Other employees",
OtherGroups: "Other groups",
Overview: "Overview",
password: "Password",
passwordIsRequired: "Password is required",
Pending: "Pending",
PersonelGroupInfoBody1: "Are you using a group often, it can be a good idea to follow it. Then the app will automatically fetch data and you will receive notifications.",
PersonelGroupInfoBody2NonTouch: "Hover the mouse above a group and click the 3 dots to follow, unfollow or enable/disable notifications",
PersonelGroupInfoBody2Touch: "Swipe left on a group, to follow or enable/disable notifications.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbol explanation:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Fetch data for a group without opening it",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Unread chat and internal messages",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Pending",
PersonelGroupInfoTitle: "Fetch and follow person groups",
PersonGroups: "Person groups",
PersonGroupsNotLoaded: "Person groups whose data are not loaded are marked with the icon: ",
phone: "Phone",
Planned: "Planned",
Planner: "Planner",
PleaseNoteTheFollowingDuties: "Please note the following duties:",
Problem: "Sorry, there's a problem.",
Read: "Read",
ReadMessagesAndNotes: "Read chat and internal messages",
ReadMessagesEmptyStateTitle: "No read messages",
ReadPluralPastTense: "Read",
RecentlyUsedShiftDefs: "Recently used",
RegardingShort: "On",
registerAbsence: "Register absence",
Reject: "Reject",
RejectShiftAssignment: "Confirm cancellation of offer",
RejectShiftAssignmentContent: "Are you sure you want to cancel the offer on this shift?",
RejectShiftExchange: "Confirm rejection of exchange",
RejectShiftExchangeContent: "Are you sure you want to reject this shift exchange?",
reload: "Reload",
RemoveShift: "Delete shift",
ResponseFromServer: "Response from server",
responsibility: "Responsibility",
RightArrow: "Right arrow",
RuleBreak: "Rule violation",
RuleViolationInfo: "In the sorted call list employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
RuleViolationInfoApplicants: "The list of applicants, employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
RuleViolationInfoSortedCallList: "In the sorted call list employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
Save: "Save",
SaveChanges: "Save changes",
search: "Search",
SelectAbsenceBack: "Select date",
SelectAbsenceType: "Select absence type",
SelectLanguage: "Select language",
SelectShiftType: "Select shift type",
SelectThePersonGroup: "Select the person group",
SelectTheShift: "Select the shift",
settings: "Settings",
shift: "shift",
shift_plural: "Shifts",
ShiftHoursHaveBeenModified: "Shift hours have been modified",
ShiftStatusAbsent: "Absent",
ShiftStatusActionRequired: "Action required",
ShiftStatusNoActivities: "Planned work w/o activities",
ShiftStatusOffered: "Offered",
ShiftStatusPlanned: "Planned work",
ShiftStatusReplaced: "Replaced",
ShiftStatusSubstitute: "Substitute",
ShiftTypeNotAvailable: "The shift type is not available for this person group",
ShiftTypeNotAvailableExplanation: "Changing the shift type cannot be reverted after saving changes.",
somethingWentWrong: "Something went wrong",
StarShift: "* shift",
StartOrEndTimeHasBeenChanged: "The start and/or end time for this shift has been changed.",
Status: "Status",
Tasks: "Tasks",
Team: "Person groups",
teamPlan: "TeamPlan",
TheErrorMessageWasCopied: "The error message was copied to the clipboard",
TheSpecifiedTimeOverlapsWith: "The specified time overlaps with",
TheSpecifiedTimeOverlapsWithTheFollowing: "The specified time overlaps with the following shifts:",
TheSpecifiedTimesOverlapWith: "The specified times overlap with",
TheSpecifiedTimesOverlapWithTheFollowing: "The specified times overlap with the following shifts:",
ThisShiftHasBeenModified: "This shift has been modified",
To: "To",
today: "Today",
ToHours: "To hours",
ToMinutes: "To minutes",
TooManyGroupsAlertBody: "For optimal performance, there is a limit on the number of employees the app can fetch data from simultaneously.\n\nTherefore, you need to free up space by removing one of the existing groups if you want to add a new one.\n\nPlease note that it might be necessary to remove several small groups to make room for a larger one.",
TooManyGroupsAlertTitle: "No more room to follow any more groups",
Type: "Type",
UnfollowDialogTitle: "Unfollow group",
UnfollowGroup: "Unfollow group",
Unread: "Unread",
UnreadMessagesAndNotes: "Unread chat and internal messages",
UnreadMessagesEmptyStateTitle: "No unread messages",
UnreadPluralPastTense: "Unread",
Url: "URL",
User: "User",
userName: "Username",
userNameIsRequired: "Username is required",
userNameNoBlackSpacesAtStartOrEnd: "Your username cannot start or end with spaces",
VersionNotSupported: "Version not supported",
Week: "Week",
Weekday_Array: ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],
WeekdayInitials_Array: ["M","T","W","T","F","S","S"],
Weeks: "Weeks",
WhereToFindDepartments: "You will find the person groups under \"Change team\" at the bottom of the screen.",
WillBeDeletedWithTheShift: "Will be deleted with the shift:",
WriteMessage: "Write a message",
Yes: "Yes",
YesLeavePage: "Yes, leave page",
YesLogout: "Yes, log out",
YourAppIsHavingProblemsWithTheServer: "Your app is having problems with the server",
};

const Language_Da: Language = {
    languageCode: "Da",
    capitalizeMonths: false,
    capitalizeWeekdays: false,
    languageEnum: LangLanguageEnum.Da,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " andre vagter."; },
ChangedToXHasBeenSaved_1: (x:string|number) => { return "Ændringerne for " + x + " er blevet gemt."; },
ChatWith_1: (x:string|number) => { return "Chat med " + x + ""; },
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Er du sikker på du vil udbyde vagten til alt ledigt personale i de " + x + " valgte personalegrupper?"; },
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Er du sikker på, du vil udbyde vagten i de " + x + " valgte personalegrupper?"; },
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Vær opmærksom på at denne vagt er registreret på \"" + x + "\"."; },
DeviatingWorkplacePleaseNote_1: (x:string|number) => { return "Vær opmærksom på, at denne vagt er registreret på \"" + x + "\"."; },
HandoverApproveRejectText_1: (x:string|number) => { return "Vælg den ansøger der skal have " + x + "s vagt."; },
IsNowCreated_1: (x:string|number) => { return "" + x + " er nu oprettet."; },
MessageModal_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessageModalRead_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan læste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes læste beskeder også vises her."; },
MessageModalUnread_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessagesEmptyStateDescription_1: (x:string|number) => { return "Der er ikke modtaget eller sendt nogen beskeder i personalegruppen, der vedrører de kommende " + x + " dage."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " mangler en byttegruppe"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " ansøgere fra vagtbørs"; },
OfferShiftTo_1: (x:string|number) => { return "Udbyd vagt til " + x + ""; },
ShiftTypeNotAvailablePleaseNote_1: (x:string|number) => { return "Vær opmærksom på, at du ikke kan skifte tilbage til vagttypen \"" + x + "\", da den ikke er tilgængelig for denne personalegruppe."; },
UnfollowDialogBody_1: (x:string|number) => { return "Er du sikker på, at du ikke længere vil følge gruppen \"" + x + "\"?"; },
ConfirmEndAbsence_2: (x:string|number, y:string|number) => { return "Er du sikker på at du vil afslutte " + x + "s fravær fra og med den " + y + "?"; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil godkende vagtbytte mellem " + x + " og " + y + "?"; },
EndAbsenceMultiPleaseNote_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har afsluttet " + x + "s fravær: " + y + ""; },
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " er nu tildelt " + y + "."; },
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "Der er ikke ændret nogen vagter i perioden\n" + x + " - " + y + ""; },
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "Der er ingen planlagte vagter i perioden\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "Der er ingen udeståender i perioden\n" + x + " - " + y + ""; },
ReadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "Der er ingen læste beskeder der vedrører perioden " + x + " - " + y + ""; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har registreret " + x + " fraværende: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " og " + y + " vil gerne bytte vagt."; },
UnreadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "Der er ingen ulæste beskeder der vedrører perioden " + x + " - " + y + ""; },
ConfirmRemoveShiftBody_3: (x:string|number, y:string|number, z:string|number) => { return "Er du sikker på, at du vil slette " + x + "s vagt \"" + y + "\" " + z + ""; },
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "s vagt \"" + y + "\" " + z + " er nu slettet."; },
absence_plural: "Fravær",
absenceType: "Fraværstype",
accept: "Accepter",
Activities: "Aktiviteter",
ActivitiesMightBeDeleted: "Aktiviteter slettes muligvis",
ActivitiesMightBeDeletedPleaseNote: "Vær opmærksom på, at følgende aktiviteter muligvis slettes, hvis de ikke længere dækkes af en vagt:",
ActivitiesWillBeDeleted: "Aktiviteter slettes",
ActivitiesWillBeDeletedPleaseNote: "Vær opmærksom på, at følgende aktiviteter slettes:",
AddEndDate: "Tilføj slutdato",
additionalInformation: "Supplerende information",
AddNote: "Interne beskeder",
Alert: "Alert",
all: "Alle",
allEmployees: "Alle medarbejdere",
AllowNotifications: "Tillad notifikationer",
alsoShifts: "også vagter",
and: "og",
And1OtherShift: "+ 1 anden vagt.",
appInformation: "App Information",
Applicants: "Ansøgere",
Approve: "Godkend",
ApproveRejectHandover: "Godkend/Afvis overdragelse",
ApproveRejectSwap: "Godkend/Afvis vagtbytte",
assignShift: "Tildel vagt",
auditActionAcceptShiftExchange: "Accepter vagtbytte",
auditActionCalendar: "Kalender",
auditActionCancelExchange: "Fortryd vagtbytte",
auditActionChangeTeam: "Skift personalegruppe",
auditActionInit: "init",
auditActionSelectShift: "Vælg vagt",
auditActionToggleAllowNotifications: "Skift tillad Notifikationer",
AvailableFictivePerson: "Ledig fiktiv person",
AwaitingApproveHandover: "Afventer godkendelse af overdragelse",
AwaitingApproveSwap: "Afventer godkendelse af vagtbytte",
Back: "Tilbage",
back: "Tilbage",
callList: "Sorteret tilkaldeliste",
Cancel: "Fortryd",
CancelOffer: "Afbryd udbud",
cancelOfferOnShiftExchange: "Afbryd udbud på vagtbørs",
candidates: "Kandidater",
candidates_short: "Kand..",
caretDown: "Pil ned",
ChangeOrganization: "Skift organisation",
ChangePersonelGroup: "Skift personalegruppe",
Changes: "Ændringer",
ChangeTeam: "Skift gruppe",
Chat: "Chat",
ChatAndInternalMessages: "Chat og interne beskeder",
ChatWithEmployee: "Chat med medarbejder",
check: "Flueben",
chooseAbsenceType: "Vælg fraværstype",
ChooseApplicants: "Vælg ansøgere",
ChooseGroups: "Vælg den eller de personalegrupper du vil udbyde vagten til.",
chooseLanguage: "Vælg sprog",
ChoosePredefined: "Vælg en prædefineret vagt",
chooseTeam: "Vælg personalegruppe",
clear: "Ryd",
Close: "Luk",
CloseMenu: "Luk menu",
ConfirmCancellationOfOffer: "Bekræft afbrydelse af udbud",
ConfirmCancellationOfOfferContent: "Er du sikker på du vil afbryde udbudet af denne vagt til alt personale?",
ConfirmKeepChanges: "Er du sikker på, at du vil beholde dine ændringer?",
ConfirmLeavePage: "Vil du forlade siden?",
ConfirmLeavePageContentCreated: "Hvis du forlader siden nu, vil den vagt du var i gang med at oprette blive slettet.",
ConfirmLeavePageContentEdited: "Hvis du forlader siden nu uden at gemme, mister du alle ændringer.",
ConfirmLogout: "Vil du logge ud?",
ConfirmMarkAsHandled: "Bekræft accept uden afløser",
ConfirmMarkAsHandledContent: "Er du sikker på du vil acceptere fravær uden at finde en afløser?",
ConfirmOfferExchangeCandidatesHelperText: "Filtrér på hvilke medarbejdere der ikke får vagten tilbudt.",
ConfirmOfferOnShiftExchange: "Bekræft udbud af vagt",
ConfirmOfferOnShiftExchangeAllAvailable: "Er du sikker på du vil udbyde vagten til alt ledigt personale i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeCandidateFilter: "Er du sikker på, du vil udbyde vagten i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeContent: "Er du sikker på du vil udbyde vagten til alt ledigt personale?",
ConfirmRemoveShiftBodyHandover: "Udbuddet på vagtbørsen aflyses også som følge heraf.",
ConfirmRemoveShiftTitle: "Slet vagt",
ConfirmSaveChanges: "Er du sikker på, at du vil gemme dine ændringer?",
ConfirmShiftAssignment: "Bekræft tildeling af vagt",
ConfirmShiftAssignmentContent: "Er du sikker på du vil tildele vagten til",
ConfirmShiftExchange: "Bekræft godkendelse af bytte",
Copy: "Kopier",
CopyDetails: "Kopier detaljer",
CopyErrorTextAndContactSupport: "Hjælp med at løse problemet. Kopier detaljerne herunder og send dem til IT-support på din arbejdsplads.",
Create: "Opret",
CreateNew: "Opret ny",
CreateNewShift: "Opret ny vagt",
Date: "Dato",
Delete: "Slet",
DeletedDueToShiftTypeChange: "Slettes pga. ændret vagttype:",
DeviatingDutylinesWillBeAffectedExplanation: "Din ændring i arbejdstiden vil påvirke følgende tjenester:",
DeviatingDutylinesWillBeDeleted: "Afvigende tjenester slettes",
DeviatingDutylinesWillBeDeletedExplanation: "Slettes pga. ændret vagttype:",
DeviatingPayerNotificationTitle: "Afvigende arbejdssted",
DeviatingWorkplace: "Afvigende arbejdssted",
Duties: "Tjenester",
Edit: "Rediger",
EditAndFindSubstitute: "Rediger vagten og find afløser",
EditShift: "Rediger vagt",
Employee: "Employee",
employeeDefiniteArticle: "medarbejderen",
EndAbsence: "Afslut fravær",
EndDate: "Slutdato",
EndImperative: "Afslut",
enterPassword: "Skriv adgangskode",
enterUserName: "Skriv brugernavn",
Error: "Fejl",
ErrorCode: "Fejlkode",
exchange: "Byt",
exchangeCandidateFilter: "Kandidatfilter",
exchangeCandidateFilter_callinEsque: "Vagter og beskyttet fravær (flere kand..)",
exchangeCandidateFilter_classic: "Vagter og fravær (færrest kandidater)",
exchangeCandidateFilter_noCallin: "Beskyttet fravær (flest kandidater)",
expectedToBeBack: "Forventet tilbage",
Eye: "Øje",
FetchData: "Hent data uden at åbne",
FictiveEmployees: "Fiktive medarbejdere",
FictivePerson: "Fiktiv person",
FilterOutEmployeesWith: "Frasortér medarbejdere med..",
FindEmployee: "Find medarbejder",
findSubstitute: "Find afløser",
FollowGroup: "Følg gruppe",
FormerAssignee: "Tidligere tildelt",
From: "Fra",
FromHours: "Fra timer",
FromMinutes: "Fra minutter",
GoToSwap: "Gå til vagtbytte",
Group: "Gruppe",
Groups: "Grupper",
GroupsFollowed: "Grupper du følger",
Hello: "Hej",
HhMmSeparator: ".",
HowMany: "Hvor mange ulæste hentes?",
HowManyRead: "Hvor mange læste hentes?",
HowManyUnread: "Hvor mange ulæste hentes?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "Der skete en uventet fejl. Fejlen er rapporteret til vores driftsafdeling.",
InternalMessage: "Intern besked",
InternalMessages: "Interne beskeder",
Keep: "Behold",
KeepChanges: "Behold ændringer",
KeepExchange: "Behold byt",
KeepOffer: "Behold udbud",
Language: "Sprog",
languageName: "Dansk",
LeftArrow: "Venstre pil",
Loading: "Indlæser",
LoadingShifts: "Henter vagter",
loadMoreDays: "Indlæs flere dage",
Location: "Sted",
Lock: "Lås",
login: "Log ind",
LoginLoadingData: "Lige et øjeblik,\ndin app henter data",
loginTitleWelcome: "Velkommen til",
Logout: "Log ud",
mail: "Mail",
ManyPersonGroupsContent: "Da du har adgang til mere end 10 personalegrupper, henter appen først data fra en personalegruppe når denne tilgåes.",
ManyPersonGroupsTitle: "Mange personalegrupper",
markAsHandled: "Accepter uden afløser",
MaxLengthError: "Du kan ikke skrive flere tegn",
MeAlt: "Mig",
Menu: "Menu",
MessagesEmptyStateTitle: "Ingen beskeder",
ModifiedDuties: "Tilpassede tjenester",
ModifiedDuty: "Tilpasset tjeneste",
MomentLoadingData: "Øjeblik, vi henter data",
Month_Array: ["Januar","Februar","Marts","April","Maj","Juni","Juli","August","September","Oktober","November","December"],
netError: "Beklager, der er et netværksproblem, status:",
NetworkErrorOccured: "Der skete en netværksfejl",
New: "Ny",
Next: "Næste",
No: "Nej",
NoAccessToTeamPlan: "Du har desværre ikke adgang til TeamPlan.",
noAppInformation: "Ingen App Information fundet",
NoCandidates: "Ingen oplagte kandidater",
NoCandidatesLong: "Der er desværre ingen kandidater at vise i den sorterede tilkaldeliste.",
NoChanges: "Ingen ændringer",
NoChangesHaveBeenMade: "Der er ikke ændret nogen vagter for denne personalegruppe.",
noDepartmentsSelected: "Ingen personalegruppe valgt",
noDepartmentsToShow: "Ingen personalegrupper at vise",
noEmployeeToShow: "Ingen ansat at vise",
NoOffersInSearch: "Der er ingen personalegrupper der matcher din søgning.",
NoPersonelGroupFollowedTextMobile: "Swipe til venstre på en gruppe og vælg",
NoPersonelGroupFollowedTextWeb: "Hold musen hen over en gruppe og tryk på de tre prikker, for at følge.",
NoPersonelGroupFollowedTitle: "Du følger ingen grupper",
Norm: "Norm",
NoShiftMatch: "Der er ingen vagter\nder matcher din søgning.",
noShiftsToShow: "Ingen vagter med ulæste beskeder",
noSortedCallListToShow: "Ingen kandidater i tilkaldelisten",
NotAssignable: "Kan ikke tildeles",
Note: "Note",
NothingPlanned: "Intet planlagt",
notification: "Notifikation",
NotificationsOff: "Slå notifikationer fra",
NotificationsOn: "Slå notifikationer til",
NoUnresolvedIssues: "Ingen udeståender",
Offer: "Udbyd",
offerOnShiftExchange: "Udbyd på vagtbørs",
OfferShift: "Udbyd vagt",
OfferWillBeCancelled: "Udbud afbrydes",
OfferWillBeCancelledPleaseNote: "Vær opmærksom på, at denne vagt er udbudt på vagtbørsen. Hvis vagten slettes afbrydes udbuddet.",
ongoingAbsence: "Løbende fravær",
onThisDay: "på denne dag",
Original: "Oprindelig",
OriginalBelongingTo: "Oprindelig tilhørende",
OtherEmployees: "Øvrige medarbejdere",
OtherGroups: "Øvrige grupper",
Overview: "Overblik",
password: "Adgangskode",
passwordIsRequired: "Password skal udfyldes",
Pending: "Udestående",
PersonelGroupInfoBody1: "Bruger du en gruppe ofte, kan det være en god ide at følge den. Så henter app’en automatisk data for dig og du vil modtage notifikationer fra gruppen.",
PersonelGroupInfoBody2NonTouch: "Hold musen henover en gruppe og tryk på de tre prikker for at følge, stoppe med at følge eller slå notifikationer til og fra.",
PersonelGroupInfoBody2Touch: "Swipe mod venstre på en gruppe, for at følge eller slå notifikationer til/fra.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbolforklaring:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Hent data for en gruppe uden at åbne den",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Ulæste chat og interne beskeder",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Udeståender",
PersonelGroupInfoTitle: "Hent og følg personalegrupper",
PersonGroups: "Personalegrupper",
PersonGroupsNotLoaded: "Personalegrupper hvis data ikke er hentet er markeret med ikonet: ",
phone: "Telefon",
Planned: "Plan",
Planner: "Planlægger",
PleaseNoteTheFollowingDuties: "Vær opmærksom på følgende tjenester:",
Problem: "Beklager, der er et problem.",
Read: "Læst",
ReadMessagesAndNotes: "Læste chat- og interne beskeder",
ReadMessagesEmptyStateTitle: "Ingen læste beskeder",
ReadPluralPastTense: "Læste",
RecentlyUsedShiftDefs: "Senest anvendt",
RegardingShort: "Vedr.",
registerAbsence: "Registrer fravær",
Reject: "Afvis",
RejectShiftAssignment: "Bekræft afbrydelse af udbud",
RejectShiftAssignmentContent: "Er du sikker på, at du vil afbryde udbuddet i denne vagt?",
RejectShiftExchange: "Bekræft afvisning af bytte",
RejectShiftExchangeContent: "Er du sikker på, at du vil afvise dette vagtbytte?",
reload: "Genindlæs",
RemoveShift: "Slet vagt",
ResponseFromServer: "Svar fra server",
responsibility: "Ansvar",
RightArrow: "Højre pil",
RuleBreak: "Regelbrud",
RuleViolationInfo: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoApplicants: "Listen af ansøgere er sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoSortedCallList: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
Save: "Gem",
SaveChanges: "Gem ændringer",
search: "Søg",
SelectAbsenceBack: "Vælg dato",
SelectAbsenceType: "Vælg fraværstype",
SelectLanguage: "Vælg sprog",
SelectShiftType: "Vælg vagttype",
SelectThePersonGroup: "Vælg personalegruppen",
SelectTheShift: "Vælg vagten",
settings: "Indstillinger",
shift: "vagt",
shift_plural: "Vagter",
ShiftHoursHaveBeenModified: "Arbejdstiden er blevet tilpasset",
ShiftStatusAbsent: "Fraværende",
ShiftStatusActionRequired: "Kræver handling",
ShiftStatusNoActivities: "Arbejde uden aktiviteter",
ShiftStatusOffered: "Udbudt",
ShiftStatusPlanned: "Planlagt arbejde",
ShiftStatusReplaced: "Afløst",
ShiftStatusSubstitute: "Afløser",
ShiftTypeNotAvailable: "Vagttypen er ikke tilgængelig for denne personalegruppe",
ShiftTypeNotAvailableExplanation: "Hvis du ændrer vagttypen, kan du ikke skifte tilbage, efter du har gemt.",
somethingWentWrong: "Noget gik galt",
StarShift: "*-vagt",
StartOrEndTimeHasBeenChanged: "Start- og/eller sluttidspunktet for denne vagt er blevet ændret.",
Status: "Status",
Tasks: "Opgaver",
Team: "Personalegrupper",
teamPlan: "TeamPlan",
TheErrorMessageWasCopied: "Fejlteksten blev kopieret til udklipsholderen",
TheSpecifiedTimeOverlapsWith: "Den angivne tid overlapper med",
TheSpecifiedTimeOverlapsWithTheFollowing: "Den angivne tid overlapper med følgende vagter:",
TheSpecifiedTimesOverlapWith: "De angivne tider overlapper med",
TheSpecifiedTimesOverlapWithTheFollowing: "De angivne tider overlapper med følgende vagter:",
ThisShiftHasBeenModified: "Denne vagt er blevet tilpasset",
To: "Til",
today: "I dag",
ToHours: "Til timer",
ToMinutes: "Til minutter",
TooManyGroupsAlertBody: "For at undgå at app'en bliver langsom, er der begrænsning på hvor mange medarbejdere den kan hente data på samtidig.\n\nDerfor skal du først frigøre plads ved at fjerne en af de eksisterende grupper, hvis du ønsker at tilføje en ny.\n\nVær opmærksom på at det kan være nødvendigt at fjerne flere små grupper for at gøre plads til en stor.",
TooManyGroupsAlertTitle: "Der er ikke plads til at følge flere grupper",
Type: "Type",
UnfollowDialogTitle: "Følg ikke længere gruppe",
UnfollowGroup: "Følg ikke længere",
Unread: "Ulæst",
UnreadMessagesAndNotes: "Ulæste chat- og interne beskeder",
UnreadMessagesEmptyStateTitle: "Ingen ulæste beskeder",
UnreadPluralPastTense: "Ulæste",
Url: "URL",
User: "Bruger",
userName: "Brugernavn",
userNameIsRequired: "Brugernavn skal udfyldes",
userNameNoBlackSpacesAtStartOrEnd: "Dit brugernavn må ikke starte eller slutte med mellemrum",
VersionNotSupported: "Version ikke understøttet",
Week: "Uge",
Weekday_Array: ["Mandag","Tirsdag","Onsdag","Torsdag","Fredag","Lørdag","Søndag"],
WeekdayInitials_Array: ["M","T","O","T","F","L","S"],
Weeks: "Uger",
WhereToFindDepartments: "Du finder personalegrupperne under \"Skift gruppe\" i bunden af skærm.",
WillBeDeletedWithTheShift: "Slettes sammen med vagten:",
WriteMessage: "Skriv besked",
Yes: "Ja",
YesLeavePage: "Ja, forlad side",
YesLogout: "Ja, log ud",
YourAppIsHavingProblemsWithTheServer: "Din app har problemer med serveren",
};

const Language_Se: Language = {
    languageCode: "Se",
    capitalizeMonths: false,
    capitalizeWeekdays: false,
    languageEnum: LangLanguageEnum.Se,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " andre vagter."; },
ChangedToXHasBeenSaved_1: (x:string|number) => { return "Ændringerne for " + x + " er blevet gemt."; },
ChatWith_1: (x:string|number) => { return "Chat med " + x + ""; },
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Er du sikker på du vil udbyde vagten til alt ledigt personale i de " + x + " valgte personalegrupper?"; },
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Er du sikker på, du vil udbyde vagten i de " + x + " valgte personalegrupper?"; },
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Vær opmærksom på at denne vagt er registreret på \"" + x + "\"."; },
DeviatingWorkplacePleaseNote_1: (x:string|number) => { return "Vær opmærksom på, at denne vagt er registreret på \"" + x + "\"."; },
HandoverApproveRejectText_1: (x:string|number) => { return "Vælg den ansøger der skal have " + x + "s vagt."; },
IsNowCreated_1: (x:string|number) => { return "" + x + " er nu oprettet."; },
MessageModal_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessageModalRead_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan læste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes læste beskeder også vises her."; },
MessageModalUnread_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessagesEmptyStateDescription_1: (x:string|number) => { return "Der er ikke modtaget eller sendt nogen beskeder i personalegruppen, der vedrører de kommende " + x + " dage."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " mangler en byttegruppe"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " ansøgere fra vagtbørs"; },
OfferShiftTo_1: (x:string|number) => { return "Udbyd vagt til " + x + ""; },
ShiftTypeNotAvailablePleaseNote_1: (x:string|number) => { return "Vær opmærksom på, at du ikke kan skifte tilbage til vagttypen \"" + x + "\", da den ikke er tilgængelig for denne personalegruppe."; },
UnfollowDialogBody_1: (x:string|number) => { return "Er du sikker på, at du ikke længere vil følge gruppen \"" + x + "\"?"; },
ConfirmEndAbsence_2: (x:string|number, y:string|number) => { return "Er du sikker på at du vil afslutte " + x + "s fravær fra og med den " + y + "?"; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil godkende vagtbytte mellem " + x + " og " + y + "?"; },
EndAbsenceMultiPleaseNote_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har afsluttet " + x + "s fravær: " + y + ""; },
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " er nu tildelt " + y + "."; },
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "Der er ikke ændret nogen vagter i perioden\n" + x + " - " + y + ""; },
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "Der er ingen planlagte vagter i perioden\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "Der er ingen udeståender i perioden\n" + x + " - " + y + ""; },
ReadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "Der er ingen læste beskeder der vedrører perioden " + x + " - " + y + ""; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har registreret " + x + " fraværende: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " og " + y + " vil gerne bytte vagt."; },
UnreadMessagesEmptyStateDescription_2: (x:string|number, y:string|number) => { return "Der er ingen ulæste beskeder der vedrører perioden " + x + " - " + y + ""; },
ConfirmRemoveShiftBody_3: (x:string|number, y:string|number, z:string|number) => { return "Er du sikker på, at du vil slette " + x + "s vagt \"" + y + "\" " + z + ""; },
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "s vagt \"" + y + "\" " + z + " er nu slettet."; },
absence_plural: "Fravær",
absenceType: "Frånvaro typ",
accept: "Acceptera",
Activities: "Aktiviteter",
ActivitiesMightBeDeleted: "Aktiviteter slettes muligvis",
ActivitiesMightBeDeletedPleaseNote: "Vær opmærksom på, at følgende aktiviteter muligvis slettes, hvis de ikke længere dækkes af en vagt:",
ActivitiesWillBeDeleted: "Aktiviteter slettes",
ActivitiesWillBeDeletedPleaseNote: "Vær opmærksom på, at følgende aktiviteter slettes:",
AddEndDate: "Tilføj slutdato",
additionalInformation: "Ytterligare information",
AddNote: "Interna meddelanden",
Alert: "Advarsel",
all: "Allt",
allEmployees: "Alla anställda",
AllowNotifications: "Tillåt meddelanden",
alsoShifts: "også vagter",
and: "og",
And1OtherShift: "+ 1 anden vagt.",
appInformation: "Appinformation",
Applicants: "Ansøgere",
Approve: "Godkänd",
ApproveRejectHandover: "Godkend/Afvis overdragelse",
ApproveRejectSwap: "Godkend/Afvis vagtbytte",
assignShift: "Tilldela pass",
auditActionAcceptShiftExchange: "Accepter vagtbytte",
auditActionCalendar: "Kalender",
auditActionCancelExchange: "Fortryd vagtbytte",
auditActionChangeTeam: "Ändra personsgrupp",
auditActionInit: "init",
auditActionSelectShift: "Välj pass",
auditActionToggleAllowNotifications: "Ändra Tillåt meddelanden",
AvailableFictivePerson: "Ledig fiktiv person",
AwaitingApproveHandover: "Afventer godkendelse af overdragelse",
AwaitingApproveSwap: "Afventer godkendelse af vagtbytte",
Back: "Tillbaka",
back: "Tillbaka",
callList: "Sorterad samtalslista",
Cancel: "Annullera",
CancelOffer: "Annullera erbjudande",
cancelOfferOnShiftExchange: "Avbryt pass erbjudande",
candidates: "Kandidater",
candidates_short: "Kand..",
caretDown: "Nedåtpil",
ChangeOrganization: "Skift organisation",
ChangePersonelGroup: "Skift personalegruppe",
Changes: "Ändringar",
ChangeTeam: "Ändra grupp",
Chat: "Chat",
ChatAndInternalMessages: "Chat og interne beskeder",
ChatWithEmployee: "Chatta med anställd",
check: "Kontrollera",
chooseAbsenceType: "Välj frånvaro typ",
ChooseApplicants: "Vælg ansøgere",
ChooseGroups: "Vælg den eller de personalegrupper du vil udbyde vagten til.",
chooseLanguage: "Välj språk",
ChoosePredefined: "Vælg en prædefineret vagt",
chooseTeam: "Välj personsgrupp",
clear: "Klar",
Close: "Stänga",
CloseMenu: "Luk menu",
ConfirmCancellationOfOffer: "Bekräfta annullering av erbjudande",
ConfirmCancellationOfOfferContent: "Är du säker på att du vill återkalla detta övertagande förfrågan til allt tillgänglig personal?",
ConfirmKeepChanges: "Er du sikker på, at du vil beholde dine ændringer?",
ConfirmLeavePage: "Vil du forlade siden?",
ConfirmLeavePageContentCreated: "Hvis du forlader siden nu, vil den vagt du var i gang med at oprette blive slettet.",
ConfirmLeavePageContentEdited: "Hvis du forlader siden nu uden at gemme, mister du alle ændringer.",
ConfirmLogout: "Vil du logge ud?",
ConfirmMarkAsHandled: "Bekräfta markerat som hanterat",
ConfirmMarkAsHandledContent: "Är du säker på att du vill markera detta som hanterat utan att hitta en ersättare?",
ConfirmOfferExchangeCandidatesHelperText: "Filtrér på hvilke medarbejdere der ikke får vagten tilbudt.",
ConfirmOfferOnShiftExchange: "Bekräfta övertagande förfrågan",
ConfirmOfferOnShiftExchangeAllAvailable: "Er du sikker på du vil udbyde vagten til alt ledigt personale i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeCandidateFilter: "Er du sikker på, du vil udbyde vagten i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeContent: "Är du säker på att du vill skicka detta övertagande förfrågan til allt tillgänglig personal?",
ConfirmRemoveShiftBodyHandover: "Udbuddet på vagtbørsen aflyses også som følge heraf.",
ConfirmRemoveShiftTitle: "Slet vagt",
ConfirmSaveChanges: "Er du sikker på, at du vil gemme dine ændringer?",
ConfirmShiftAssignment: "Bekräfta passbyte",
ConfirmShiftAssignmentContent: "Är du säker på att du vill tilldela denna pass till",
ConfirmShiftExchange: "Bekræft godkendelse af bytte",
Copy: "Kopia",
CopyDetails: "Kopia",
CopyErrorTextAndContactSupport: "Kopiera texten och kontakta IT supporten på din arbetsplats",
Create: "Opret",
CreateNew: "Opret ny",
CreateNewShift: "Opret ny vagt",
Date: "Dato",
Delete: "Tag bort",
DeletedDueToShiftTypeChange: "Slettes pga. ændret vagttype:",
DeviatingDutylinesWillBeAffectedExplanation: "Din ændring i arbejdstiden vil påvirke følgende tjenester:",
DeviatingDutylinesWillBeDeleted: "Afvigende tjenester slettes",
DeviatingDutylinesWillBeDeletedExplanation: "Slettes pga. ændret vagttype:",
DeviatingPayerNotificationTitle: "Afvigende arbejdssted",
DeviatingWorkplace: "Afvigende arbejdssted",
Duties: "Arbetsuppgifter",
Edit: "Rediger",
EditAndFindSubstitute: "Rediger vagten og find afløser",
EditShift: "Rediger vagt",
Employee: "Employee",
employeeDefiniteArticle: "medarbejderen",
EndAbsence: "Afslut fravær",
EndDate: "Slutdatum",
EndImperative: "Afslut",
enterPassword: "Skriv in lösenord",
enterUserName: "Skriv in ditt användarnamn",
Error: "Fel",
ErrorCode: "Fejlkode",
exchange: "Utbyta",
exchangeCandidateFilter: "Kandidatfilter",
exchangeCandidateFilter_callinEsque: "Vagter og beskyttet fravær (flere kand..)",
exchangeCandidateFilter_classic: "Vagter og fravær (færrest kandidater)",
exchangeCandidateFilter_noCallin: "Beskyttet fravær (flest kandidater)",
expectedToBeBack: "Förväntas vara tillbaka",
Eye: "Öga",
FetchData: "Hent data uden at åbne",
FictiveEmployees: "Fiktive medarbejdere",
FictivePerson: "Fiktiv person",
FilterOutEmployeesWith: "Frasortér medarbejdere med..",
FindEmployee: "Find medarbejder",
findSubstitute: "Hitta ersättningar",
FollowGroup: "Følg gruppe",
FormerAssignee: "Tidigare tilldelad",
From: "Från",
FromHours: "Fra timer",
FromMinutes: "Fra minutter",
GoToSwap: "Gå till Pass byte",
Group: "Gruppe",
Groups: "Grupper",
GroupsFollowed: "Grupper du følger",
Hello: "Hej",
HhMmSeparator: ":",
HowMany: "Hvor mange ulæste hentes?",
HowManyRead: "Hvor mange læste hentes?",
HowManyUnread: "Hvor mange ulæste hentes?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "Der skete en uventet fejl. Fejlen er rapporteret til vores driftsafdeling.",
InternalMessage: "Intern besked",
InternalMessages: "Interne beskeder",
Keep: "Behold",
KeepChanges: "Behold ændringer",
KeepExchange: "Behold byt",
KeepOffer: "Behålla erbjudande",
Language: "Språk",
languageName: "Svensk",
LeftArrow: "Vänster pil",
Loading: "Läser in",
LoadingShifts: "Henter vagter",
loadMoreDays: "Ladda flera dagar",
Location: "Plats",
Lock: "Låsa",
login: "Logga in",
LoginLoadingData: "Lige et øjeblik,\ndin app henter data",
loginTitleWelcome: "Välkommen till",
Logout: "Logga ut",
mail: "Post",
ManyPersonGroupsContent: "Da du har adgang til mere end 10 personalegrupper, henter appen først data fra en personalegruppe når denne tilgåes.",
ManyPersonGroupsTitle: "Mange personalegrupper",
markAsHandled: "Markera som hanterat",
MaxLengthError: "Du kan ikke skrive flere tegn",
MeAlt: "Mig",
Menu: "Meny",
MessagesEmptyStateTitle: "Ingen beskeder",
ModifiedDuties: "Tilpassede tjenester",
ModifiedDuty: "Tilpasset tjeneste",
MomentLoadingData: "Øjeblik, vi henter data",
Month_Array: ["Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December"],
netError: "Tyvärr, det finns ett nätverksproblem, status:",
NetworkErrorOccured: "Der skete en netværksfejl",
New: "Ny",
Next: "Nästa",
No: "Nej",
NoAccessToTeamPlan: "Du har desværre ikke adgang til TeamPlan.",
noAppInformation: "Ingen App Information hittades",
NoCandidates: "Ingen oplagte kandidater",
NoCandidatesLong: "Der er desværre ingen kandidater at vise i den sorterede tilkaldeliste.",
NoChanges: "Ingen ændringer",
NoChangesHaveBeenMade: "Der er ikke ændret nogen vagter for denne personalegruppe.",
noDepartmentsSelected: "Ingen personalegruppe valgt",
noDepartmentsToShow: "Inga personsgrupper att visa",
noEmployeeToShow: "Ingen anställd att visa",
NoOffersInSearch: "Der er ingen personalegrupper der matcher din søgning.",
NoPersonelGroupFollowedTextMobile: "Swipe til venstre på en gruppe og vælg",
NoPersonelGroupFollowedTextWeb: "Hold musen hen over en gruppe og tryk på de tre prikker, for at følge.",
NoPersonelGroupFollowedTitle: "Du følger ingen grupper",
Norm: "Norm",
NoShiftMatch: "Der er ingen vagter\nder matcher din søgning.",
noShiftsToShow: "Inga pass att visa",
noSortedCallListToShow: "Inga kandidater i samtalslistan",
NotAssignable: "Kan ikke tildeles",
Note: "Note",
NothingPlanned: "Intet planlagt",
notification: "Meddelande",
NotificationsOff: "Slå notifikationer fra",
NotificationsOn: "Slå notifikationer til",
NoUnresolvedIssues: "Ingen udeståender",
Offer: "Erbjudande",
offerOnShiftExchange: "Övertagande förfrågan",
OfferShift: "Erbjuda pass",
OfferWillBeCancelled: "Udbud afbrydes",
OfferWillBeCancelledPleaseNote: "Vær opmærksom på, at denne vagt er udbudt på vagtbørsen. Hvis vagten slettes afbrydes udbuddet.",
ongoingAbsence: "Löpande frånvaro",
onThisDay: "på denne dag",
Original: "Oprindelig",
OriginalBelongingTo: "Oprindelig tilhørende",
OtherEmployees: "Øvrige medarbejdere",
OtherGroups: "Øvrige grupper",
Overview: "Översikt",
password: "Lösenord",
passwordIsRequired: "Lösenord krävs",
Pending: "Status",
PersonelGroupInfoBody1: "Bruger du en gruppe ofte, kan det være en god ide at følge den. Så henter app’en automatisk data for dig og du vil modtage notifikationer fra gruppen.",
PersonelGroupInfoBody2NonTouch: "Hold musen henover en gruppe og tryk på de tre prikker for at følge, stoppe med at følge eller slå notifikationer til og fra.",
PersonelGroupInfoBody2Touch: "Swipe mod venstre på en gruppe, for at følge eller slå notifikationer til/fra.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbolforklaring:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Hent data for en gruppe uden at åbne den",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Ulæste chat og interne beskeder",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Udeståender",
PersonelGroupInfoTitle: "Hent og følg personalegrupper",
PersonGroups: "Personalegrupper",
PersonGroupsNotLoaded: "Personalegrupper hvis data ikke er hentet er markeret med ikonet: ",
phone: "Telefon",
Planned: "Plan",
Planner: "Planlægger",
PleaseNoteTheFollowingDuties: "Vær opmærksom på følgende tjenester:",
Problem: "Tyvärr, det finns ett problem.",
Read: "Læst",
ReadMessagesAndNotes: "Læste chat- og interne beskeder",
ReadMessagesEmptyStateTitle: "Ingen læste beskeder",
ReadPluralPastTense: "Læste",
RecentlyUsedShiftDefs: "Senest anvendt",
RegardingShort: "På",
registerAbsence: "Registrera frånvaro",
Reject: "Afvis",
RejectShiftAssignment: "Bekræft afbrydelse af udbud",
RejectShiftAssignmentContent: "Er du sikker på, at du vil afbryde udbuddet i denne vagt?",
RejectShiftExchange: "Bekræft afvisning af bytte",
RejectShiftExchangeContent: "Er du sikker på, at du vil afvise dette vagtbytte?",
reload: "Ladda om",
RemoveShift: "Slet vagt",
ResponseFromServer: "Svar från server",
responsibility: "Ansvar",
RightArrow: "Höger pil",
RuleBreak: "Regelbrud",
RuleViolationInfo: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoApplicants: "Listen af ansøgere er sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoSortedCallList: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
Save: "Gem",
SaveChanges: "Gem ændringer",
search: "Sök",
SelectAbsenceBack: "Vælg dato",
SelectAbsenceType: "Vælg fraværstype",
SelectLanguage: "Vælg sprog",
SelectShiftType: "Vælg vagttype",
SelectThePersonGroup: "Vælg personalegruppen",
SelectTheShift: "Vælg vagten",
settings: "Inställningar",
shift: "vagt",
shift_plural: "Pass",
ShiftHoursHaveBeenModified: "Arbejdstiden er blevet tilpasset",
ShiftStatusAbsent: "Frånvarande",
ShiftStatusActionRequired: "Åtgärd krävs",
ShiftStatusNoActivities: "Planerat arbete utan aktiviteter",
ShiftStatusOffered: "Orbjuds",
ShiftStatusPlanned: "Planerat arbete",
ShiftStatusReplaced: "Ersatt",
ShiftStatusSubstitute: "Ersättning",
ShiftTypeNotAvailable: "Vagttypen er ikke tilgængelig for denne personalegruppe",
ShiftTypeNotAvailableExplanation: "Hvis du ændrer vagttypen, kan du ikke skifte tilbage, efter du har gemt.",
somethingWentWrong: "Något gick fel",
StarShift: "*-vagt",
StartOrEndTimeHasBeenChanged: "Start- og/eller sluttidspunktet for denne vagt er blevet ændret.",
Status: "Status",
Tasks: "Uppgifter",
Team: "Personsgrupp",
teamPlan: "LagPlan",
TheErrorMessageWasCopied: "Felmeddelandet är kopierat till urklipp",
TheSpecifiedTimeOverlapsWith: "Den angivne tid overlapper med",
TheSpecifiedTimeOverlapsWithTheFollowing: "Den angivne tid overlapper med følgende vagter:",
TheSpecifiedTimesOverlapWith: "De angivne tider overlapper med",
TheSpecifiedTimesOverlapWithTheFollowing: "De angivne tider overlapper med følgende vagter:",
ThisShiftHasBeenModified: "Denne vagt er blevet tilpasset",
To: "Til",
today: "I dag",
ToHours: "Til timer",
ToMinutes: "Til minutter",
TooManyGroupsAlertBody: "For at undgå at app'en bliver langsom, er der begrænsning på hvor mange medarbejdere den kan hente data på samtidig.\n\nDerfor skal du først frigøre plads ved at fjerne en af de eksisterende grupper, hvis du ønsker at tilføje en ny.\n\nVær opmærksom på at det kan være nødvendigt at fjerne flere små grupper for at gøre plads til en stor.",
TooManyGroupsAlertTitle: "Der er ikke plads til at følge flere grupper",
Type: "Typ",
UnfollowDialogTitle: "Følg ikke længere gruppe",
UnfollowGroup: "Følg ikke længere",
Unread: "Ulæst",
UnreadMessagesAndNotes: "Ulæste chat- og interne beskeder",
UnreadMessagesEmptyStateTitle: "Ingen ulæste beskeder",
UnreadPluralPastTense: "Ulæste",
Url: "URL",
User: "Användare",
userName: "Användarnamn",
userNameIsRequired: "Användarnamn krävs",
userNameNoBlackSpacesAtStartOrEnd: "Ditt användarnamn kan inte börja eller sluta med mellanslag",
VersionNotSupported: "Versionen inte stöds",
Week: "Vecka",
Weekday_Array: ["Måndag","Tisdag","Onsdag","Torsdag","Fredag","Lördag","Söndag"],
WeekdayInitials_Array: ["M","T","O","T","F","L","S"],
Weeks: "Uger",
WhereToFindDepartments: "Du finder personalegrupperne under \"Skift gruppe\" i bunden af skærm.",
WillBeDeletedWithTheShift: "Slettes sammen med vagten:",
WriteMessage: "Skriv meddelande",
Yes: "Ja",
YesLeavePage: "Ja, forlad side",
YesLogout: "Ja, log ud",
YourAppIsHavingProblemsWithTheServer: "Din app har problem med servern",
};

export const available_languages : Language[] = [
    Language_En,
    Language_Da,
    Language_Se
];

export const langMap = {
    'En': Language_En,
    'Da': Language_Da,
    'Se': Language_Se
};

export const language_enum_to_language = {
    [LangLanguageEnum.En]: Language_En,
    [LangLanguageEnum.Da]: Language_Da,
    [LangLanguageEnum.Se]: Language_Se
};