import * as dateFns from 'date-fns';

import {
  AbsenceType,
  Exchange,
  TeamShift,
  TeamShiftExSingle,
} from '../api/TeamPlan_api';
import { ThunkDispatch } from '../types';
import {
  ExchangeCandidateFilterEnum,
  ExchangeTypeEnum,
  TeamShiftStatusEnum,
} from '../api/enumLib_api';
import { Period, formatPeriod } from '../util/dates';
import { Tab, getSiteRoutes, getTeamRouteParams } from '../routes';
import {
  addLoadingPeriod,
  attemptLoadTeamRoster,
  loadTeamRosterFailure,
  loadTeamRosterRequest,
  loadTeamRosterSuccess,
  removeLoadingPeriod,
  updateShift,
  updateShifts,
} from '../ListView/ListViewActions';
import {
  attemptSendNotificationLog,
  closeModal,
  queueToast,
  setModalLoading,
} from '../appActions';
import { history, push, replace } from '../history';

import {
  EditedShift,
  ShiftDraft,
  getShiftDraftLabel,
  isNewlyCreatedShift,
} from '../ListView/CreateShift/EditedShift';
import { Store } from '../rootReducer';
import { ToastType } from '@pdcfrontendui/components/Toast/Toast';
import { WebRecordType } from '../api/Common_api';
import { currentLanguage } from '../currentLanguage';
import { dateFormats } from '@pdcfrontendui/utils';
import getApi from '../getApi';

export type ShiftViewAction =
  | {
      type: 'GETABSENCETYPES_REQUEST';
    }
  | {
      type: 'GETABSENCETYPES_SUCCESS';
      getAbsenceTypesResponse: AbsenceType[];
    }
  | {
      type: 'GETABSENCETYPES_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'REGISTERABSENCE_REQUEST';
    }
  | {
      type: 'REGISTERABSENCE_SUCCESS';
      registerAbsenceResponse: {
        teamShiftList: TeamShift[];
        recordType: WebRecordType;
      };
    }
  | {
      type: 'REGISTERABSENCE_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'MARKASHANDLED_REQUEST';
    }
  | {
      type: 'MARKASHANDLED_SUCCESS';
      markAsHandledResponse: TeamShiftExSingle;
      personId: number;
    }
  | {
      type: 'MARKASHANDLED_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'OFFER_ON_SHIFT_ECHANGE_REQUEST';
    }
  | {
      type: 'OFFER_ON_SHIFT_ECHANGE_SUCCESS';
      offerOnShiftExchangeResponse: TeamShiftExSingle;
    }
  | {
      type: 'OFFER_ON_SHIFT_ECHANGE_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_REQUEST';
    }
  | {
      type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_SUCCESS';
      cancelOfferOnShiftExchangeResponse: TeamShiftExSingle;
      personId: number;
    }
  | {
      type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'ASSIGNSHIFT_REQUEST';
    }
  | {
      type: 'ASSIGNSHIFT_SUCCESS';
      assignShiftResponse: TeamShift[];
    }
  | {
      type: 'ASSIGNSHIFT_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'GETEXCHANGE_REQUEST';
    }
  | {
      type: 'GETEXCHANGE_SUCCESS';
      exchange: Exchange;
    }
  | {
      type: 'GETEXCHANGE_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'SET_SWAP_VIEW_OPEN';
      open: boolean;
    }
  | {
      type: 'SET_OFFER_VIEW_OPEN';
      open: boolean;
    }
  | {
      type: 'EXCHANGE_ACCEPT_REQUEST';
    }
  | {
      type: 'EXCHANGE_ACCEPT_SUCCESS';
    }
  | {
      type: 'EXCHANGE_ACCEPT_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'EXCHANGE_REJECT_REQUEST';
    }
  | {
      type: 'EXCHANGE_REJECT_SUCCESS';
    }
  | {
      type: 'EXCHANGE_REJECT_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'EXCHANGE_SELECT_EXCHANGE_ID';
      exchangeId: number | undefined;
    }
  | {
      type: 'SET_SHIFT_DRAFT';
      data: ShiftDraft | null;
    }
  | {
      type: 'UPDATE_SELECTED_CANDIDATE_FILTER';
      candidateFilter: ExchangeCandidateFilterEnum;
    }
  | {
      type: 'REMOVE_SHIFT_REQUEST';
    }
  | {
      type: 'REMOVE_SHIFT_SUCCESS';
    }
  | {
      type: 'REMOVE_SHIFT_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'SET_CONFIRM_DELETE_MODAL';
      shown: boolean;
    }
  | {
      type: 'EDIT_SHIFT_REQUEST';
    }
  | {
      type: 'EDIT_SHIFT_SUCCESS';
    }
  | {
      type: 'EDIT_SHIFT_FAILURE';
    }
  | {
      type: 'SET_CONFIRM_EDIT_MODAL';
      shown: boolean;
    };

export const getAbsenceTypesRequest = (): ShiftViewAction => ({
  type: 'GETABSENCETYPES_REQUEST',
});

export const getAbsenceTypesSuccess = (
  getAbsenceTypesResponse: AbsenceType[]
): ShiftViewAction => ({
  type: 'GETABSENCETYPES_SUCCESS',
  getAbsenceTypesResponse,
});

export const getAbsenceTypesFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'GETABSENCETYPES_FAILURE',
  errorMessage,
});

export const markAsHandledRequest = (): ShiftViewAction => ({
  type: 'MARKASHANDLED_REQUEST',
});

export const markAsHandledSuccess = (
  personId: number,
  markAsHandledResponse: TeamShiftExSingle
): ShiftViewAction => ({
  type: 'MARKASHANDLED_SUCCESS',
  personId,
  markAsHandledResponse,
});

export const markAsHandledFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'MARKASHANDLED_FAILURE',
  errorMessage,
});
export const offerOnShiftExchangeRequest = (): ShiftViewAction => ({
  type: 'OFFER_ON_SHIFT_ECHANGE_REQUEST',
});

export const offerOnShiftExchangeSuccess = (
  offerOnShiftExchangeResponse: TeamShiftExSingle
): ShiftViewAction => ({
  type: 'OFFER_ON_SHIFT_ECHANGE_SUCCESS',
  offerOnShiftExchangeResponse,
});

export const offerOnShiftExchangeFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'OFFER_ON_SHIFT_ECHANGE_FAILURE',
  errorMessage,
});
export const cancelOfferOnShiftExchangeRequest = (): ShiftViewAction => ({
  type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_REQUEST',
});

export const cancelOfferOnShiftExchangeSuccess = (
  personId: number,
  cancelOfferOnShiftExchangeResponse: TeamShiftExSingle
): ShiftViewAction => ({
  type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_SUCCESS',
  personId,
  cancelOfferOnShiftExchangeResponse,
});

export const cancelOfferOnShiftExchangeFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'CANCEL_OFFER_ON_SHIFT_ECHANGE_FAILURE',
  errorMessage,
});
export const registerAbsenceRequest = (): ShiftViewAction => ({
  type: 'REGISTERABSENCE_REQUEST',
});
export const registerAbsenceSuccess = (registerAbsenceResponse: {
  teamShiftList: TeamShift[];
  recordType: WebRecordType;
}): ShiftViewAction => ({
  type: 'REGISTERABSENCE_SUCCESS',
  registerAbsenceResponse,
});

export const registerAbsenceFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'REGISTERABSENCE_FAILURE',
  errorMessage,
});

export const assignShiftRequest = (): ShiftViewAction => ({
  type: 'ASSIGNSHIFT_REQUEST',
});

export const assignShiftSuccess = (
  assignShiftResponse: TeamShift[]
): ShiftViewAction => ({
  type: 'ASSIGNSHIFT_SUCCESS',
  assignShiftResponse,
});

export const assignShiftFailure = (errorMessage: string): ShiftViewAction => ({
  type: 'ASSIGNSHIFT_FAILURE',
  errorMessage,
});

export const getExchangeRequest = (): ShiftViewAction => ({
  type: 'GETEXCHANGE_REQUEST',
});

export const getExchangeSuccess = (exchange: Exchange): ShiftViewAction => ({
  type: 'GETEXCHANGE_SUCCESS',
  exchange,
});

export const getExchangeFailure = (errorMessage: string): ShiftViewAction => ({
  type: 'GETEXCHANGE_FAILURE',
  errorMessage,
});

export const setSwapViewOpen = (open: boolean) => ({
  type: 'SET_SWAP_VIEW_OPEN',
  open,
});

export const setOfferViewOpen = (open: boolean) => ({
  type: 'SET_OFFER_VIEW_OPEN',
  open,
});

export const exchangeAcceptRequest = (): ShiftViewAction => ({
  type: 'EXCHANGE_ACCEPT_REQUEST',
});

export const exchangeAcceptSuccess = (): ShiftViewAction => ({
  type: 'EXCHANGE_ACCEPT_SUCCESS',
});

export const exchangeAcceptFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'EXCHANGE_ACCEPT_FAILURE',
  errorMessage,
});

export const exchangeRejectRequest = (): ShiftViewAction => ({
  type: 'EXCHANGE_REJECT_REQUEST',
});

export const exchangeRejectSuccess = (): ShiftViewAction => ({
  type: 'EXCHANGE_REJECT_SUCCESS',
});

export const exchangeRejectFailure = (
  errorMessage: string
): ShiftViewAction => ({
  type: 'EXCHANGE_REJECT_FAILURE',
  errorMessage,
});

export const exchangeSelectExchangeId = (
  exchangeId: number | undefined
): ShiftViewAction => ({
  type: 'EXCHANGE_SELECT_EXCHANGE_ID',
  exchangeId,
});

export const removeShiftRequest = (): ShiftViewAction => ({
  type: 'REMOVE_SHIFT_REQUEST',
});

export const removeShiftSuccess = (): ShiftViewAction => ({
  type: 'REMOVE_SHIFT_SUCCESS',
});

export const removeShiftFailure = (errorMessage: string): ShiftViewAction => ({
  type: 'REMOVE_SHIFT_FAILURE',
  errorMessage,
});

export const editShiftRequest = (): ShiftViewAction => ({
  type: 'EDIT_SHIFT_REQUEST',
});

export const editShiftSuccess = (): ShiftViewAction => ({
  type: 'EDIT_SHIFT_SUCCESS',
});

export const editShiftFailure = (): ShiftViewAction => ({
  type: 'EDIT_SHIFT_FAILURE',
});

export const setConfirmDeleteModal = (shown: boolean): ShiftViewAction => ({
  type: 'SET_CONFIRM_DELETE_MODAL',
  shown,
});

export const setShiftDraft = (
  shiftDraft: ShiftDraft | null
): ShiftViewAction => ({
  type: 'SET_SHIFT_DRAFT',
  data: shiftDraft,
});

function dispatchRegisterAbsenceToast(
  dispatch: ThunkDispatch,
  employeeName: string,
  absenceShifts: TeamShift[]
) {
  let message = '';
  // TODO: Backend returns existing absences that were not affected by the registrations.
  absenceShifts = absenceShifts.filter(
    (shift) => shift.status === TeamShiftStatusEnum.actionRequired
  );
  if (absenceShifts.length >= 2) {
    message = `${currentLanguage.RegisteredAbsent_2(
      employeeName,
      dateFormats.Man_31DOT_okt_23DOT59(absenceShifts[1]!.period.from)
    )}-${dateFormats.$23DOT59(absenceShifts[1]!.period.to)}`;
    if (absenceShifts.length === 3) {
      message += ' ' + currentLanguage.And1OtherShift;
    } else if (absenceShifts.length > 3) {
      message +=
        ' ' + currentLanguage.AndXOtherShifts_1(absenceShifts.length - 2);
    }
  }
  if (message.length !== 0) {
    const toast: ToastType = { text: message };
    dispatch(queueToast(toast));
  }
}

function dispatchUpdateShift(
  dispatch: ThunkDispatch,
  shiftId: string,
  result: TeamShiftExSingle,
  teamId: string
) {
  dispatch(
    updateShift(
      shiftId,
      {
        ...result.teamShift,
        personId: result.teamShift.personId,
        status: result.teamShift.status,
        period: {
          from: new Date(result.teamShift.period.from),
          to: new Date(result.teamShift.period.to),
        },
      },
      result.recordType,
      teamId
    )
  );
}

export function attemptGetAbsenceTypes(shiftId: string, teamId: string) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    // kinda hacky way to make sure we only have one getAbsenceTypes call at a time
    if (!getState().shiftViewReducer.loading) {
      dispatch(getAbsenceTypesRequest());
      return getApi()
        .getAbsenceTypes(shiftId, teamId)
        .then((json) => {
          dispatch(getAbsenceTypesSuccess(json));
        })
        .catch((err) => {
          dispatch(getAbsenceTypesFailure(err.message));
          dispatch(attemptSendNotificationLog(err));
        });
    }
  };
}

export function attemptMarkAsHandled(
  teamId: string,
  personId: number,
  shiftId: string
) {
  return (dispatch: ThunkDispatch) => {
    dispatch(markAsHandledRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .markAsHandled(shiftId, teamId)
      .then((json) => {
        dispatch(markAsHandledSuccess(personId, json));
        dispatch(setModalLoading(false));
        dispatchUpdateShift(dispatch, shiftId, json, teamId);
        dispatch(closeModal());
      })
      .catch((err) => {
        dispatch(markAsHandledFailure(err.message));
        dispatch(setModalLoading(false));
        dispatch(closeModal());
        dispatch(attemptSendNotificationLog(err));
      });
  };
}
export function attemptOfferOnShiftExchange(
  shiftId: string,
  teamIdList: string[],
  currentTeamId: string,
  candiateFilter: ExchangeCandidateFilterEnum
) {
  return (dispatch: ThunkDispatch) => {
    dispatch(offerOnShiftExchangeRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .offerOnShiftExchange(shiftId, teamIdList, candiateFilter)
      .then((json) => {
        dispatch(offerOnShiftExchangeSuccess(json));
        dispatch(setModalLoading(false));
        dispatchUpdateShift(dispatch, shiftId, json, currentTeamId);
        dispatch(closeModal());
      })
      .catch((err) => {
        dispatch(offerOnShiftExchangeFailure(err.message));
        dispatch(setModalLoading(false));
        dispatch(closeModal());
        dispatch(attemptSendNotificationLog(err));
      });
  };
}
export function attemptCancelOfferOnShiftExchange(
  teamId: string,
  personId: number,
  shiftId: string
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(cancelOfferOnShiftExchangeRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .cancelOfferOnShiftExchange(shiftId, teamId)
      .then((json) => {
        dispatch(cancelOfferOnShiftExchangeSuccess(personId, json));
        dispatch(setModalLoading(false));
        dispatchUpdateShift(dispatch, shiftId, json, teamId);
        dispatch(closeModal());
        const state = getState();
        if (!state.appReducer.screensizeBig) {
          const currentTeamRoute = getSiteRoutes().team(
            state.listViewReducer.currentTeam
          );
          push(currentTeamRoute);
        }
      })
      .catch((err) => {
        dispatch(cancelOfferOnShiftExchangeFailure(err.message));
        dispatch(setModalLoading(false));
        dispatch(closeModal());
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export function attemptAssignShift(
  teamId: string,
  personId: number,
  shiftId: string,
  updatedPeriod: Period | null,
  currentlyOnFictive: boolean
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(assignShiftRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .assignShift(teamId, personId, shiftId, updatedPeriod)
      .then((json) => {
        // Important to reset shiftDraft before any other state changes
        dispatch(setShiftDraft(null));
        dispatch(assignShiftSuccess(json.teamShiftList));
        dispatch(setModalLoading(false));
        dispatch(
          updateShifts(shiftId, json.teamShiftList, json.recordType, teamId)
        );
        dispatch(closeModal());

        // When assigning a shift currently assigned to a fictive employee, it is actually copied!
        // So we need to remove the original shift.
        if (currentlyOnFictive) {
          void dispatch(attemptRemoveShift(shiftId, teamId));
        }

        const state = getState();
        const params = getTeamRouteParams(history.location.search);
        if (!state.appReducer.screensizeBig) {
          const currentTeamRoute = getSiteRoutes().team(
            state.listViewReducer.currentTeam
          );
          push(currentTeamRoute);
        } else {
          // Exit the find substitute state, and show the shift in the assigned state. But stay on the pending tab (if not prviously on fictive).
          const newShiftId = json.teamShiftList[1]?.id;
          if (newShiftId) {
            push(
              getSiteRoutes().team(teamId, {
                personId,
                shiftId: newShiftId,
                // tab: !currentlyOnFictive ? Tab.Pending : Tab.Planned,
                tab: params.tab,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(assignShiftFailure(err.message));
        dispatch(setModalLoading(false));
        dispatch(closeModal());
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export function attemptAssignShiftCandidate(teamId: string, personId: number) {
  return async (dispatch: ThunkDispatch, getState: () => Store) => {
    try {
      const shiftDraft = getState().shiftViewReducer.shiftDraft;
      const assignedTo =
        getState().listViewReducer.employeeMap[teamId]?.[personId];
      if (!shiftDraft || isNewlyCreatedShift(shiftDraft) || !assignedTo) {
        return;
      }
      dispatch(setModalLoading(true));
      const json = await getApi().assignShiftCandidate(
        teamId,
        personId,
        shiftDraft
      );
      dispatch(setShiftDraft(null));
      dispatch(assignShiftSuccess([json.teamShift]));
      dispatch(setModalLoading(false));
      dispatch(
        updateShifts(
          json.teamShift.id,
          [json.teamShift],
          json.recordType,
          teamId
        )
      );
      dispatch(closeModal());
      const label = getShiftDraftLabel(shiftDraft);
      dispatch(
        queueToast({
          text: currentLanguage.IsNowAssigned_2(
            `"${label}" ${formatPeriod(
              shiftDraft.from,
              shiftDraft.to,
              currentLanguage.languageCode
            )}`,
            assignedTo.name
          ),
        })
      );
      const state = getState();
      // Show list of planned shifts (where the assigned shift now resides)
      // But if its assigned to a fictive employee, select the shift (as user likely wants to do further handling right away)
      push(
        getSiteRoutes().team(
          teamId,
          assignedTo.isFictive || state.appReducer.screensizeBig
            ? {
                personId: json.teamShift.personId,
                shiftId: json.teamShift.id,
              }
            : undefined
        )
      );
    } catch (err) {
      if (err instanceof Error) {
        dispatch(assignShiftFailure(err.message));
        dispatch(setModalLoading(false));
        dispatch(closeModal());
        dispatch(attemptSendNotificationLog(err));
      }
    }
  };
}

export function attemptRegisterAbsence(
  teamId: string,
  shiftId: string,
  absenceTypeId: string,
  returnDate?: Date | null
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(registerAbsenceRequest());
    return getApi()
      .registerAbsence(
        shiftId,
        teamId,
        absenceTypeId,
        returnDate
          ? dateFns.subMinutes(returnDate, returnDate.getTimezoneOffset())
          : null
      )
      .then((json) => {
        const shift = getState().listViewReducer.shiftMap[teamId]?.[shiftId];
        if (shift) {
          const employeeName =
            getState().listViewReducer.employeeMap[teamId]?.[shift.personId]
              ?.name;
          if (employeeName) {
            dispatchRegisterAbsenceToast(
              dispatch,
              employeeName,
              json.teamShiftList
            );
          }
        }
        dispatch(registerAbsenceSuccess(json));
        dispatch(
          updateShifts(shiftId, json.teamShiftList, json.recordType, teamId)
        );
        // Keep shift selected, but switch to the pending tab (where it now resides)
        replace(
          getSiteRoutes().team(teamId, {
            ...getTeamRouteParams(history.location.search),
            tab: Tab.Pending,
          })
        );

        // This definitely isnt the best solution to reload the shifts after register absence success
        // But everything else has failed, and this should work
        const requestPeriod = getState().listViewReducer.period;

        dispatch(addLoadingPeriod(requestPeriod));
        dispatch(loadTeamRosterRequest(teamId));
        return getApi()
          .getTeamRoster(teamId, requestPeriod, false)
          .then((teamRoster) => {
            dispatch(
              loadTeamRosterSuccess(
                teamRoster.shiftMap,
                teamRoster.employeeMap,
                teamRoster.recordType,
                teamId
              )
            );
            dispatch(removeLoadingPeriod(requestPeriod));
          })
          .catch((err) => {
            if (err instanceof Error) {
              dispatch(loadTeamRosterFailure(err.message, teamId));
              dispatch(attemptSendNotificationLog(err));
            }
          });
      })
      .catch((err) => {
        if (err instanceof Error) {
          dispatch(registerAbsenceFailure(err.message));
          dispatch(attemptSendNotificationLog(err));
        }
      });
  };
}

export function attemptGetExchange(personId: number, shiftStart: Date) {
  return (dispatch: ThunkDispatch) => {
    dispatch(getExchangeRequest());
    return getApi()
      .getExchange(personId, shiftStart)
      .then((exchange) => {
        dispatch(getExchangeSuccess(exchange));
      })
      .catch((err) => {
        dispatch(getExchangeFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}
export function attemptExchangeAccept(
  unitid: string,
  requestExchangePeriod: Period,
  offerPersonId: number,
  offerExchangeId: number,
  requestPersonId: number | null
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(exchangeAcceptRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .exchangeAccept(
        unitid,
        requestExchangePeriod,
        offerPersonId,
        offerExchangeId,
        requestPersonId
      )
      .then(async () => {
        dispatch(exchangeAcceptSuccess());
        await dispatch(
          attemptLoadTeamRoster(
            getState().listViewReducer.currentTeam,
            getState().listViewReducer.period,
            false,
            false
          )
        );

        push(
          getSiteRoutes().team(getState().listViewReducer.currentTeam, {
            ...getTeamRouteParams(history.location.search),
            shiftId: undefined,
          })
        );
        dispatch(closeModal());
      })
      .catch((err) => {
        dispatch(exchangeAcceptFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      })
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };
}

export function attemptExchangeReject(
  exchangeId: number,
  exchangePeriod: Period,
  exchangeUnitId: string,
  exchangeType: ExchangeTypeEnum,
  remark: string | null
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(exchangeRejectRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .exchangeReject(
        exchangeId,
        exchangePeriod,
        exchangeUnitId,
        exchangeType,
        remark
      )
      .then(async () => {
        dispatch(exchangeRejectSuccess());
        await dispatch(
          attemptLoadTeamRoster(
            getState().listViewReducer.currentTeam,
            getState().listViewReducer.period,
            false,
            false
          )
        );

        push(
          getSiteRoutes().team(getState().listViewReducer.currentTeam, {
            ...getTeamRouteParams(history.location.search),
            shiftId: undefined,
          })
        );
      })
      .catch((err) => {
        dispatch(exchangeRejectFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      })
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };
}

export const updateSelectedCandidateFilter = (
  candidateFilter: ExchangeCandidateFilterEnum
): ShiftViewAction => ({
  type: 'UPDATE_SELECTED_CANDIDATE_FILTER',
  candidateFilter,
});

export function attemptRemoveShift(
  shiftId: string,
  teamId: string,
  employeeName?: string,
  shiftLabel?: string,
  shiftTimeLabel?: string
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(removeShiftRequest());
    dispatch(setModalLoading(true));
    return getApi()
      .removeShift(shiftId, teamId)
      .then(async () => {
        dispatch(setShiftDraft(null));
        dispatch(removeShiftSuccess());
        if (employeeName && shiftLabel && shiftTimeLabel) {
          dispatch(
            queueToast({
              text: currentLanguage.RemoveShiftToast_3(
                employeeName,
                shiftLabel,
                shiftTimeLabel
              ),
            })
          );
        }
        await dispatch(
          attemptLoadTeamRoster(
            getState().listViewReducer.currentTeam,
            getState().listViewReducer.period,
            false,
            false
          )
        );

        dispatch(closeModal());
      })
      .catch((err) => {
        dispatch(removeShiftFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      })
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };
}

export function attemptEndAbsence(teamId: string, shift: TeamShift) {
  return async (dispatch: ThunkDispatch, getState: () => Store) => {
    try {
      const response = await getApi().endAbsence(shift.id, teamId);
      dispatch(
        updateShifts(
          shift.id,
          response.teamShiftList,
          response.recordType,
          teamId
        )
      );
      const employeeName =
        getState().listViewReducer.employeeMap[teamId]?.[shift.personId]?.name;
      if (employeeName) {
        const restored = response.teamShiftList.filter(
          (shift) => shift.status === TeamShiftStatusEnum.planned
        );
        if (restored.length >= 2) {
          let text = `${currentLanguage.EndAbsenceMultiPleaseNote_2(
            employeeName,
            dateFormats.Man_31DOT_okt_23DOT59(restored[1]!.period.from)
          )}-${dateFormats.$23DOT59(restored[1]!.period.to)}`;
          if (restored.length === 3) {
            text += ` ${currentLanguage.And1OtherShift}`;
          } else if (restored.length > 3) {
            text += ` ${currentLanguage.AndXOtherShifts_1(
              restored.length - 2
            )}`;
          }
          dispatch(queueToast({ text }));
        }
      }
    } catch (err) {
      //
    }
  };
}

export function attemptEditShift(teamId: string, shift: EditedShift) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(editShiftRequest());
      const response = await getApi().editShift(
        teamId,
        shift.originalShift,
        shift
      );
      dispatch(
        updateShift(
          shift.originalShift.id,
          response.teamShift,
          response.recordType,
          teamId
        )
      );
      dispatch(editShiftSuccess());
      const label =
        shift.def && shift.def.id !== shift.originalShift.shiftDefId
          ? shift.def.label
          : shift.originalShift.label;
      dispatch(
        queueToast({
          text: currentLanguage.ChangedToXHasBeenSaved_1(
            `${label} ${formatPeriod(
              shift.from,
              shift.to,
              currentLanguage.languageCode
            )}`
          ),
        })
      );
      return response.teamShift;
    } catch (err) {
      dispatch(editShiftFailure());
    }
  };
}

