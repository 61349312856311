﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for Message
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  type DomainWsIntervalType,
  type DomainWsVariantObject,
  type DomainWsVariantAvailability,
  type DomainJsonObject,
  type DomainWspdclasttrans,
  type DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// Represents a user (employee or planner)
//  Responsible AGRU
export class UserKey {
  [key: string]: unknown;
  constructor(
    public UserId: number,
    public UserType: Enums.UserLoginTypeEnum) {}
}

export function getUserKeyFromJSON(jsonObj: StringObj): UserKey {
  return new UserKey(

      getInt(jsonObj, 'UserId'),

      getEnum(jsonObj, 'UserType', Enums.UserLoginTypeEnum)
  );
}

export function getUserKey(jsonObj: StringObj, prop: string): UserKey {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('UserKey', prop);
  assertObject(val);
  return getUserKeyFromJSON(val);
}

// A chat message for a specific person / day
//  Responsible AGRU
export class DayMessage {
  [key: string]: unknown;
  constructor(
    public MessageId: number,
    public MessageText: string,
    public PersonId: number,
    public Day: Date,
    public FromName: string,
    public FromKey: UserKey,
    public SentWhen: Date,
    public Visibility: Enums.MsgVisibilityOldEnum,
    public ReadWhen: Date | null = null) {}
}

export function getDayMessageFromJSON(jsonObj: StringObj): DayMessage {
  return new DayMessage(
    /* msgday.id */
      getInt(jsonObj, 'MessageId'),
    /* 80 */
      getString(jsonObj, 'MessageText'),

      getInt(jsonObj, 'PersonId'),

      getDate(jsonObj, 'Day'),
    /* 80 */
      getString(jsonObj, 'FromName'),

      getUserKey(jsonObj, 'FromKey'),

      getDate(jsonObj, 'SentWhen'),
    /* Visibility of the message (public, employee or planners only). */
      getEnum(jsonObj, 'Visibility', Enums.MsgVisibilityOldEnum),
    /* When was the message read */
      getNullable(jsonObj, 'ReadWhen', getDate)
  );
}

export function getDayMessage(jsonObj: StringObj, prop: string): DayMessage {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('DayMessage', prop);
  assertObject(val);
  return getDayMessageFromJSON(val);
}

// Number of unread messages for a team
//  Responsible AGRU
export class UnreadCount {
  [key: string]: unknown;
  constructor(
    public TeamId: string,
    public NumberOfUnreadMessages: number) {}
}

export function getUnreadCountFromJSON(jsonObj: StringObj): UnreadCount {
  return new UnreadCount(
    /* 11 */
      getString(jsonObj, 'TeamId'),

      getInt(jsonObj, 'NumberOfUnreadMessages')
  );
}

export function getUnreadCount(jsonObj: StringObj, prop: string): UnreadCount {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('UnreadCount', prop);
  assertObject(val);
  return getUnreadCountFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Fetch all day messages for the specified persons and period.
Responsible: AGRU */
export class GetMessages extends WebRequest<DayMessage[]> {
  public webService = 'message' as const;
  public method = 'GetMessages' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public PersonIds: number[],
    public Period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<DayMessage[]>): DayMessage[] {
    return json.result.map(getDayMessageFromJSON);
  }
}

/**     % Sends a day message from the current user (planner).
Responsible: AGRU */
export class PostMessage extends WebRequest<DayMessage> {
  public webService = 'message' as const;
  public method = 'PostMessage' as const;
  public noUserValidation = false as const;
  public noAuditAction = true as const;
  constructor(
    public PersonId: number,
    public Day: Date,
    public MessageText: string,
    public Visibility: Enums.MsgVisibilityOldEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<DayMessage>): DayMessage {
    return getDayMessageFromJSON(json.result);
  }
}

/**     % Mark a list of day messages as read by planner
Responsible: AGRU */
export class MarkMessagesAsRead extends WebRequest<number[]> {
  public webService = 'message' as const;
  public method = 'MarkMessagesAsRead' as const;
  public noUserValidation = false as const;
  public noAuditAction = true as const;
  constructor(
    public MessageIds: number[],
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<number[]>): number[] {
    return json.result.map(getIntFromJSON);
  }
}

/**     % Get number of unread messages per team
Responsible: AGRU */
export class GetUnreadCounts extends WebRequest<UnreadCount[]> {
  public webService = 'message' as const;
  public method = 'GetUnreadCounts' as const;
  public noUserValidation = false as const;
  public noAuditAction = true as const;
  constructor(
    public TeamIds: string[],
    public Period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<UnreadCount[]>): UnreadCount[] {
    return json.result.map(getUnreadCountFromJSON);
  }
}

