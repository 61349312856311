﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for TeamPlan
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  type DomainWsIntervalType,
  type DomainWsVariantObject,
  type DomainWsVariantAvailability,
  type DomainJsonObject,
  type DomainWspdclasttrans,
  type DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// The persongroupid and label
//  Responsible JBL
export class Team {
  [key: string]: unknown;
  constructor(
    public id: string,
    public label: string,
    public parentId: string,
    public unitUilabel: string,
    public canOffer: boolean,
    public favoriteStatus: Enums.FavoriteStatusEnum,
    public notificationStatus: Enums.NotificationStatusEnum,
    public groupMemberCount: number) {}
}

export function getTeamFromJSON(jsonObj: StringObj): Team {
  return new Team(
    /* 11; from persongroup.groupid */
      getString(jsonObj, 'id'),
    /* 80; from persongroup.uilabel */
      getString(jsonObj, 'label'),
    /* 10; from persongroup.unitid */
      getString(jsonObj, 'parentId'),
    /* 80; from persongroup.unitid.uilabel */
      getString(jsonObj, 'unitUilabel'),
    /* Whether shifts can be offered to this persongroup. Always true when swapIsUsed=everywhere. Always false when swapIsUsed=no */
      getBoolean(jsonObj, 'canOffer'),

      getEnum(jsonObj, 'favoriteStatus', Enums.FavoriteStatusEnum),

      getEnum(jsonObj, 'notificationStatus', Enums.NotificationStatusEnum),
    /* The number of members in the group */
      getInt(jsonObj, 'groupMemberCount')
  );
}

export function getTeam(jsonObj: StringObj, prop: string): Team {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Team', prop);
  assertObject(val);
  return getTeamFromJSON(val);
}

// Personal information shown in TeamPlan
//  Responsible LABO
export class Person {
  [key: string]: unknown;
  constructor(
    public id: number,
    public name: string,
    public phoneList: string[],
    public email: string,
    public initials: string,
    public isFictive: boolean) {}
}

export function getPersonFromJSON(jsonObj: StringObj): Person {
  return new Person(
    /* from person1.personid */
      getInt(jsonObj, 'id'),
    /* 50 */
      getString(jsonObj, 'name'),
    /* 20; The person's phone numbers (person1.phone, person1.altphone, and person1.mobilphone, but only if set) */
    getArray(jsonObj, 'phoneList', getStringFromJSON),
    /* 80 */
      getString(jsonObj, 'email'),
    /* 80 */
      getString(jsonObj, 'initials'),
    /* Whether the person is a fictive person */
      getBoolean(jsonObj, 'isFictive')
  );
}

export function getPerson(jsonObj: StringObj, prop: string): Person {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Person', prop);
  assertObject(val);
  return getPersonFromJSON(val);
}

// TeamDuty - coop se only. Dutylines on  the shift
//  Responsible JMQ
export class TeamDuty {
  [key: string]: unknown;
  constructor(
    public salarysort: string,
    public salarysortText: string,
    public start: number | null = null,
    public end: number | null = null,
    public clockcode: string | null = null) {}
}

export function getTeamDutyFromJSON(jsonObj: StringObj): TeamDuty {
  return new TeamDuty(
    /* 80; Salarysort for duty */
      getString(jsonObj, 'salarysort'),
    /* 80; Long text for duty */
      getString(jsonObj, 'salarysortText'),
    /* Timeoffset between 0 and 1439 */
      getNullable(jsonObj, 'start', getInt),
    /* Timeoffsetend between 1 and 1440 */
      getNullable(jsonObj, 'end', getInt),
    /* 80 */
      getNullable(jsonObj, 'clockcode', getString)
  );
}

export function getTeamDuty(jsonObj: StringObj, prop: string): TeamDuty {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamDuty', prop);
  assertObject(val);
  return getTeamDutyFromJSON(val);
}

// teamActivity - Can either come from an activity or task
//  Responsible JBL
export class TeamActivity {
  [key: string]: unknown;
  constructor(
    public start: number,
    public end: number,
    public label: string) {}
}

export function getTeamActivityFromJSON(jsonObj: StringObj): TeamActivity {
  return new TeamActivity(
    /* Timeoffset between 0 and 1439 */
      getInt(jsonObj, 'start'),
    /* Timeoffsetend between 1 and 1440 */
      getInt(jsonObj, 'end'),
    /* 80; Computed from either activity_reg.activityLabel or prd_task_reg.name */
      getString(jsonObj, 'label')
  );
}

export function getTeamActivity(jsonObj: StringObj, prop: string): TeamActivity {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamActivity', prop);
  assertObject(val);
  return getTeamActivityFromJSON(val);
}

// TeamShift. A thing that needs to be done, a shift_reg.; How shifts are interpreted by the business logic is hidden from the App.; We cannot store notes on shifts, but we could return a list of notes on the day of the shift, or we could store daymessages referring to the shift
//  Responsible JBL
export class TeamShift {
  [key: string]: unknown;
  constructor(
    public id: string,
    public label: string,
    public type: string,
    public shiftDefId: string,
    public period: DomainWsIntervalType,
    public personId: number,
    public formerPersonId: number,
    public status: Enums.TeamShiftStatusEnum,
    public dutyLines: TeamDuty[],
    public record: WebField[],
    public dutyLinesOriginal: TeamDuty[],
    public activities: TeamActivity[],
    public tasks: TeamActivity[],
    public responsibilities: string[],
    public isTimeEditable: boolean,
    public numSwapSuggestions: number | null = null,
    public deviatingPayerUiLabel: string | null = null) {}
}

export function getTeamShiftFromJSON(jsonObj: StringObj): TeamShift {
  return new TeamShift(
    /* 30; Opaque, an internal representation of the shift_reg's primary key. In the planned "production-version" of TeamPlan should can only refer to a task resouce constraint (if the shift is generated from a task).; Note, the id can change almost arbitrarily, for instance the id can change moving or reassigned the shift */
      getString(jsonObj, 'id'),
    /* 80; Computed from the shift, for instance "normalt arbejde" for shifts, task.label for tasks */
      getString(jsonObj, 'label'),
    /* 9; Subset of ShiftTypeId */
      getString(jsonObj, 'type'),
    /* 9; from shift_reg.shiftid. The template used for the shift. */
      getString(jsonObj, 'shiftDefId'),
    /* 30 */
      getDomainWsIntervalType(jsonObj, 'period'),

      getInt(jsonObj, 'personId'),
    /* optional: The previous owner if this shift was constructed via a copyrequest, or -1. TODO_LABO, the shift_reg remark also has this information and we do not actually set this field, so we should just remove the field */
      getInt(jsonObj, 'formerPersonId'),

      getEnum(jsonObj, 'status', Enums.TeamShiftStatusEnum),
    /* Duties on the shift */
    getArray(jsonObj, 'dutyLines', getTeamDutyFromJSON),
    /* hasRecordInterface */
    getArray(jsonObj, 'record', getWebFieldFromJSON),
    /* The original duties before availability conversion */
    getArray(jsonObj, 'dutyLinesOriginal', getTeamDutyFromJSON),
    /* Activities which are a subset of the shift period */
    getArray(jsonObj, 'activities', getTeamActivityFromJSON),
    /* Task assignments which are a subset of the shift period */
    getArray(jsonObj, 'tasks', getTeamActivityFromJSON),
    /* 80; Activities or task assignments which are a superset of the shift period */
    getArray(jsonObj, 'responsibilities', getStringFromJSON),
    /* Can the times be changed */
      getBoolean(jsonObj, 'isTimeEditable'),
    /* The number of exchange offers/suggestions/applications. Only defined if status in (swapActionRequired, offerActionRequired) */
      getNullable(jsonObj, 'numSwapSuggestions', getInt),
    /* 80; UIlabel of payer unit. Only defined if shift has dutyline with deviating payer. */
      getNullable(jsonObj, 'deviatingPayerUiLabel', getString)
  );
}

export function getTeamShift(jsonObj: StringObj, prop: string): TeamShift {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamShift', prop);
  assertObject(val);
  return getTeamShiftFromJSON(val);
}

// For webmethods returning a single teamShift
//  Responsible LABO
export class TeamShiftExSingle {
  [key: string]: unknown;
  constructor(
    public recordType: WebRecordType,
    public teamShift: TeamShift) {}
}

export function getTeamShiftExSingleFromJSON(jsonObj: StringObj): TeamShiftExSingle {
  return new TeamShiftExSingle(
    /* Type for teamShift.record */
      getWebRecordType(jsonObj, 'recordType'),

      getTeamShift(jsonObj, 'teamShift')
  );
}

export function getTeamShiftExSingle(jsonObj: StringObj, prop: string): TeamShiftExSingle {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamShiftExSingle', prop);
  assertObject(val);
  return getTeamShiftExSingleFromJSON(val);
}

// For webmethods returning a list of teamShifts
//  Responsible LABO
export class TeamShiftExList {
  [key: string]: unknown;
  constructor(
    public recordType: WebRecordType,
    public teamShiftList: TeamShift[]) {}
}

export function getTeamShiftExListFromJSON(jsonObj: StringObj): TeamShiftExList {
  return new TeamShiftExList(
    /* Type for teamShiftList.record */
      getWebRecordType(jsonObj, 'recordType'),

    getArray(jsonObj, 'teamShiftList', getTeamShiftFromJSON)
  );
}

export function getTeamShiftExList(jsonObj: StringObj, prop: string): TeamShiftExList {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamShiftExList', prop);
  assertObject(val);
  return getTeamShiftExListFromJSON(val);
}

// One row in the call in list
//  Responsible LABO
export class CallIn {
  [key: string]: unknown;
  constructor(
    public id: number,
    public assignable: boolean,
    public shortRule: string,
    public priority: number | null = null,
    public ruleid: string | null = null,
    public descRule: string | null = null,
    public descCause: string | null = null,
    public cost: number | null = null) {}
}

export function getCallInFromJSON(jsonObj: StringObj): CallIn {
  return new CallIn(
    /* person1.personid */
      getInt(jsonObj, 'id'),

      getBoolean(jsonObj, 'assignable'),
    /* 80; Humanly readable short text / rule-violation-group text for the worst ruleviolation (different ruleids can use the same shortRule),; or @Disposal if there are no rule violations, or @error if the assignment caused an error before the rulecheck,; or @overlapping if there are overlapping shifts. (the translation of workRuleConflict.ruleidgroup) */
      getString(jsonObj, 'shortRule'),
    /* Worst ruleviolation severity. Lower is better. 1 is lowest. Unset if assignable is false */
      getNullable(jsonObj, 'priority', getReal),
    /* 80; Unique ID for the type of the worst ruleviolation (corresponds to workruleitem.id). Unset if there are no rule violations */
      getNullable(jsonObj, 'ruleid', getString),
    /* 80; Description of the rule violation in a format mentioning the parameters for the persons rulegroup, for instance, "11-hours rule can be violated max. 2 time(s) per rolling 14-day(s)". Unset if there are no rule violations or other errors */
      getNullable(jsonObj, 'descRule', getString),
    /* 80; Humanly readable reference to the data which causes the worst rule violation (typically a reference to a timeperiod). Unset if there are no rule violations */
      getNullable(jsonObj, 'descCause', getString),
    /* The cost of the shift. Should only be displayed if any cost is != 0 (if the cost module is disabled, then the cost is always 0). Unset if assignable is false or the cost module is disabled */
      getNullable(jsonObj, 'cost', getReal)
  );
}

export function getCallIn(jsonObj: StringObj, prop: string): CallIn {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('CallIn', prop);
  assertObject(val);
  return getCallInFromJSON(val);
}

// Settings defined per user which can only be read after logon (see getUserSettings)
//  Responsible LABO
export class UserSettings {
  [key: string]: unknown;
  constructor(
    public defaultExchangeCandidateFilter: Enums.ExchangeCandidateFilterEnum) {}
}

export function getUserSettingsFromJSON(jsonObj: StringObj): UserSettings {
  return new UserSettings(
    /* The candidateFilter most recently used for for offerOnShiftExchange and its default value for future calls */
      getEnum(jsonObj, 'defaultExchangeCandidateFilter', Enums.ExchangeCandidateFilterEnum)
  );
}

export function getUserSettings(jsonObj: StringObj, prop: string): UserSettings {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('UserSettings', prop);
  assertObject(val);
  return getUserSettingsFromJSON(val);
}

// System information for getVersion
//  Responsible MMI
export class SystemInformation {
  [key: string]: unknown;
  constructor(
    public appVersionMin: string,
    public serviceVersion: string,
    public dbVersion: string) {}
}

export function getSystemInformationFromJSON(jsonObj: StringObj): SystemInformation {
  return new SystemInformation(
    /* 30; ??? */
      getString(jsonObj, 'appVersionMin'),
    /* 30; OldLabel_Type pdcSession.majorVersion */
      getString(jsonObj, 'serviceVersion'),
    /* 30; DatabaseName_Type logRecord.lr_dbVersion */
      getString(jsonObj, 'dbVersion')
  );
}

export function getSystemInformation(jsonObj: StringObj, prop: string): SystemInformation {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('SystemInformation', prop);
  assertObject(val);
  return getSystemInformationFromJSON(val);
}

// Health information for getHealthCheck
//  Responsible AGRU
export class HealthCheck {
  [key: string]: unknown;
  constructor(
    public serviceVersion: string,
    public dbVersion: string) {}
}

export function getHealthCheckFromJSON(jsonObj: StringObj): HealthCheck {
  return new HealthCheck(
    /* 30; OldLabel_Type pdcSession.majorVersion */
      getString(jsonObj, 'serviceVersion'),
    /* 30; DatabaseName_Type logRecord.lr_dbVersion */
      getString(jsonObj, 'dbVersion')
  );
}

export function getHealthCheck(jsonObj: StringObj, prop: string): HealthCheck {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('HealthCheck', prop);
  assertObject(val);
  return getHealthCheckFromJSON(val);
}

// The list of persons and shifts visible in #teamId in a given timescope, see getTeamRoster
//  Responsible LABO
export class TeamRoster {
  [key: string]: unknown;
  constructor(
    public teamId: string,
    public recordType: WebRecordType,
    public teamShiftList: TeamShift[],
    public personList: Person[]) {}
}

export function getTeamRosterFromJSON(jsonObj: StringObj): TeamRoster {
  return new TeamRoster(
    /* 11 */
      getString(jsonObj, 'teamId'),
    /* Type of teamShiftList.record */
      getWebRecordType(jsonObj, 'recordType'),

    getArray(jsonObj, 'teamShiftList', getTeamShiftFromJSON),

    getArray(jsonObj, 'personList', getPersonFromJSON)
  );
}

export function getTeamRoster(jsonObj: StringObj, prop: string): TeamRoster {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamRoster', prop);
  assertObject(val);
  return getTeamRosterFromJSON(val);
}

// WI137650 Info about the teamShift to be exchanged.; One row in the planner-approve-offer list
//  Responsible JBL
export class ExchangeInfo {
  [key: string]: unknown;
  constructor(
    public exchangeId: number,
    public teamShiftList: TeamShift[],
    public unitId: string,
    public period: DomainWsIntervalType,
    public type: Enums.ExchangeTypeEnum,
    public status: Enums.ExchangeStatusEnum,
    public remark: string,
    public plannerRemark: string,
    public personId: number | null = null,
    public persongroupList: string[] | null = null,
    public callIn: CallIn | null = null) {}
}

export function getExchangeInfoFromJSON(jsonObj: StringObj): ExchangeInfo {
  return new ExchangeInfo(
    /* Id of the exchange_reg. Input for acceptExchange and rejectExchange */
      getInt(jsonObj, 'exchangeId'),
    /* For the shift module there is only one, for the production module there can be multiple */
    getArray(jsonObj, 'teamShiftList', getTeamShiftFromJSON),
    /* 10; from exchange_reg.unitid. The unitid of the request (also if this is an offer) */
      getString(jsonObj, 'unitId'),
    /* 30; from exchange_reg.start--end. Corresponds to the shift period */
      getDomainWsIntervalType(jsonObj, 'period'),
    /* from exchange_reg.type. Identifies the type of swap when rendering the swap (should only need to look at the request's type) */
      getEnum(jsonObj, 'type', Enums.ExchangeTypeEnum),
    /* from exchange_reg.status. Identifies swaps which have been assigned between finding the shift and clicking on the swap */
      getEnum(jsonObj, 'status', Enums.ExchangeStatusEnum),
    /* 80; from exchange_reg.remark. The remark from the employee */
      getString(jsonObj, 'remark'),
    /* 80; from exchange_reg.plannerRemark. The remark from the planner */
      getString(jsonObj, 'plannerRemark'),
    /* from exchange_reg.personid. only undefined when type=dutyrequests. The same as callin.id (if callin is defined) */
      getNullable(jsonObj, 'personId', getInt),
    /* 80; The persongrouplabels corresponding to the #personId's memberships during #period. only undefined when type=dutyrequests. Contains at least one element, if defined */
    getNullableArray(jsonObj, 'persongroupList', getStringFromJSON),
    /* Set for offers when status = inProgress, never set for requests */
      getNullable(jsonObj, 'callIn', getCallIn)
  );
}

export function getExchangeInfo(jsonObj: StringObj, prop: string): ExchangeInfo {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('ExchangeInfo', prop);
  assertObject(val);
  return getExchangeInfoFromJSON(val);
}

// WI137650 Exchange that awaits accept/reject from planner
//  Responsible JBL
export class Exchange {
  [key: string]: unknown;
  constructor(
    public requestInfo: ExchangeInfo,
    public swapInfoList: ExchangeInfo[]) {}
}

export function getExchangeFromJSON(jsonObj: StringObj): Exchange {
  return new Exchange(

      getExchangeInfo(jsonObj, 'requestInfo'),
    /* there can be multiple swappers making offers for a request */
    getArray(jsonObj, 'swapInfoList', getExchangeInfoFromJSON)
  );
}

export function getExchange(jsonObj: StringObj, prop: string): Exchange {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Exchange', prop);
  assertObject(val);
  return getExchangeFromJSON(val);
}

// Shifttypeid and label for a tpye of absence. See GetAbsence
//  Responsible LABO
export class AbsenceType {
  [key: string]: unknown;
  constructor(
    public id: string,
    public label: string,
    public openable: Enums.AvailabilityOpenHandlingEnum) {}
}

export function getAbsenceTypeFromJSON(jsonObj: StringObj): AbsenceType {
  return new AbsenceType(
    /* 9 */
      getString(jsonObj, 'id'),
    /* 80 */
      getString(jsonObj, 'label'),

      getEnum(jsonObj, 'openable', Enums.AvailabilityOpenHandlingEnum)
  );
}

export function getAbsenceType(jsonObj: StringObj, prop: string): AbsenceType {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('AbsenceType', prop);
  assertObject(val);
  return getAbsenceTypeFromJSON(val);
}

// shift_period_def, a template for a shift_reg.; The actual contents used for the shift_reg can vary over time, but TeamPlan mostly works with the shift_period_def which is valid at this point in time.
//  Responsible JBL
export class TeamShiftDef {
  [key: string]: unknown;
  constructor(
    public id: string,
    public unitid: string,
    public label: string,
    public startTime: number,
    public endTime: number,
    public type: string,
    public dutyLines: TeamDuty[],
    public editable: boolean,
    public isDefault: boolean) {}
}

export function getTeamShiftDefFromJSON(jsonObj: StringObj): TeamShiftDef {
  return new TeamShiftDef(
    /* 9; from shift_period_def.shiftid */
      getString(jsonObj, 'id'),
    /* 10; from shift_period_def.unitid. Where the template is defined */
      getString(jsonObj, 'unitid'),
    /* 80; from shift_period_def.uilabel */
      getString(jsonObj, 'label'),
    /* Timeoffset between 0 and 1439 */
      getInt(jsonObj, 'startTime'),
    /* Timeoffsetend between 1 and 4319 */
      getInt(jsonObj, 'endTime'),
    /* 9; Subset of ShiftTypeId */
      getString(jsonObj, 'type'),
    /* Duties on the shift */
    getArray(jsonObj, 'dutyLines', getTeamDutyFromJSON),
    /* Whether TeamPlan can alter the shifts' start and end times in getCallInListDef and assignCallInListDef */
      getBoolean(jsonObj, 'editable'),
    /* Whether the shift is the default (*)-shiftDef. The default shiftDef does not have representative dutylines, startTime, or endTime. */
      getBoolean(jsonObj, 'isDefault')
  );
}

export function getTeamShiftDef(jsonObj: StringObj, prop: string): TeamShiftDef {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TeamShiftDef', prop);
  assertObject(val);
  return getTeamShiftDefFromJSON(val);
}

// Text explaining the deviating info on a shift.
//  Responsible RATJ
export class DeviatingShiftInfo {
  [key: string]: unknown;
  constructor(
    public title: string,
    public info: string) {}
}

export function getDeviatingShiftInfoFromJSON(jsonObj: StringObj): DeviatingShiftInfo {
  return new DeviatingShiftInfo(
    /* 80; short description of the type of differences on the shift with respect to its shift_def, that the planner should be informed about. Does not contain newlines. */
      getString(jsonObj, 'title'),
    /* 80; details about the differences with respect to its shift_def. May contain newlines.; TODO: We might want to make info optional, and we might want to add optional dutylines, rather than sending formatted dutylines in the info field. */
      getString(jsonObj, 'info')
  );
}

export function getDeviatingShiftInfo(jsonObj: StringObj, prop: string): DeviatingShiftInfo {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('DeviatingShiftInfo', prop);
  assertObject(val);
  return getDeviatingShiftInfoFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Returns settings defined per user which can only be read after logon (see userSettings)
Responsible: LABO */
export class GetUserSettings extends WebRequest<UserSettings> {
  public webService = 'teamPlan' as const;
  public method = 'GetUserSettings' as const;
  public noUserValidation = false as const;
  public noAuditAction = true as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<UserSettings>): UserSettings {
    return getUserSettingsFromJSON(json.result);
  }
}

/**     % WI86182 System information - user story
Responsible: MMI */
export class GetVersion extends WebRequest<SystemInformation> {
  public webService = 'teamPlan' as const;
  public method = 'GetVersion' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<SystemInformation>): SystemInformation {
    return getSystemInformationFromJSON(json.result);
  }
}

/**     % wi125397 GetHealthCheck for PDCPlan Cloud
Responsible: AGRU */
export class GetHealthCheck extends WebRequest<HealthCheck> {
  public webService = 'teamPlan' as const;
  public method = 'GetHealthCheck' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<HealthCheck>): HealthCheck {
    return getHealthCheckFromJSON(json.result);
  }
}

/**     % Returns the list of persongroups that the current staffuser (see the JWT) can plan for, ie can write to shifts for and read from.
    % See WI86549 Choose department/Team - User story
Responsible: LABO */
export class GetTeams extends WebRequest<Team[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetTeams' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Team[]>): Team[] {
    return json.result.map(getTeamFromJSON);
  }
}

/**     % WI86183 List of shifts - user story
Responsible: LABO */
export class GetTeamRoster extends WebRequest<TeamRoster> {
  public webService = 'teamPlan' as const;
  public method = 'GetTeamRoster' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public period: DomainWsIntervalType,
    public storedViewName: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamRoster>): TeamRoster {
    return getTeamRosterFromJSON(json.result);
  }
}

/**     % Returns person information #personIdList assuming that they are members of the persongroup #teamId
Responsible: LABO */
export class GetEmployees extends WebRequest<Person[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetEmployees' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public personIdList: number[],
    public teamId: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Person[]>): Person[] {
    return json.result.map(getPersonFromJSON);
  }
}

/**     % Sets favoriteStatus of #teamId to #status
Responsible: JBL */
export class SetFavoriteStatus extends WebRequest<Team> {
  public webService = 'teamPlan' as const;
  public method = 'SetFavoriteStatus' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public status: Enums.FavoriteStatusEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Team>): Team {
    return getTeamFromJSON(json.result);
  }
}

/**     % Sets notificationStatus of #teamId to #status
Responsible: JBL */
export class SetNotificationStatus extends WebRequest<Team> {
  public webService = 'teamPlan' as const;
  public method = 'SetNotificationStatus' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public status: Enums.NotificationStatusEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Team>): Team {
    return getTeamFromJSON(json.result);
  }
}

/**     % WI137650 get the exchange for the person at this time
Responsible: JBL */
export class GetExchange extends WebRequest<Exchange> {
  public webService = 'teamPlan' as const;
  public method = 'GetExchange' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public personId: number,
    public shiftStart: Date,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Exchange>): Exchange {
    return getExchangeFromJSON(json.result);
  }
}

/**     % WI137650 accept exchange offer
Responsible: JBL */
export class AcceptExchange extends WebRequest<Enums.WebmethodVoidPlaceholderEnum> {
  public webService = 'teamPlan' as const;
  public method = 'AcceptExchange' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public unitId: string,
    public requestExchangePeriod: DomainWsIntervalType,
    public offerPersonId: number,
    public offerExchangeId: number,
    public requestPersonId: number | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Enums.WebmethodVoidPlaceholderEnum>): Enums.WebmethodVoidPlaceholderEnum {
    return getEnumFromJSON(json.result, Enums.WebmethodVoidPlaceholderEnum)  }
}

/**     % WI137650 cancel exchange (either the request or offer). Note that cancelling the request will also cancel all offers.
    % Cancelling an offer will normally not directly affect the request (unless they are paired, in which case the request is cancelled or it is the non-canceleld offer, in which case the request responsible is set to swapper)
Responsible: JBL */
export class CancelExchange extends WebRequest<Enums.WebmethodVoidPlaceholderEnum> {
  public webService = 'teamPlan' as const;
  public method = 'CancelExchange' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public exchangeId: number,
    public exchangePeriod: DomainWsIntervalType,
    public exchangeUnitId: string,
    public exchangeType: Enums.ExchangeTypeEnum,
    public remark: string | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Enums.WebmethodVoidPlaceholderEnum>): Enums.WebmethodVoidPlaceholderEnum {
    return getEnumFromJSON(json.result, Enums.WebmethodVoidPlaceholderEnum)  }
}

/**     % Offer #shiftId to the persongroups #teamIdList and return the new view of #shiftId.
    % See WI86195 Offer Shift on Shift Exchange - user story
Responsible: LABO */
export class OfferOnShiftExchange extends WebRequest<TeamShiftExSingle> {
  public webService = 'teamPlan' as const;
  public method = 'OfferOnShiftExchange' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamIdList: string[],
    public storedViewName: string,
    public candidateFilter: Enums.ExchangeCandidateFilterEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExSingle>): TeamShiftExSingle {
    return getTeamShiftExSingleFromJSON(json.result);
  }
}

/**     % DEPRECATED. USE cancelExchange INSTEAD
    % Cancel the swap on #shiftId / #teamId and return the new new of #shiftId. See WI86195 Offer Shift on Shift Exchange - user story
Responsible: LABO */
export class CancelOfferOnShiftExchange extends WebRequest<TeamShiftExSingle> {
  public webService = 'teamPlan' as const;
  public method = 'CancelOfferOnShiftExchange' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public storedViewName: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExSingle>): TeamShiftExSingle {
    return getTeamShiftExSingleFromJSON(json.result);
  }
}

/**     % Returns the list of possible absenceTypes (shifttypes and their labels) which can be registerred on #shiftId in #teamId
    % See WI86191 Register absence - user story
Responsible: LABO */
export class GetAbsenceTypes extends WebRequest<AbsenceType[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetAbsenceTypes' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<AbsenceType[]>): AbsenceType[] {
    return json.result.map(getAbsenceTypeFromJSON);
  }
}

/**     % Create an absence registration (availability_reg) covering the day of #shiftId with #absenceTypeId, possibly with a return date
    % See WI86191 Register absence - user story.
Responsible: LABO */
export class RegisterAbsence extends WebRequest<TeamShiftExList> {
  public webService = 'teamPlan' as const;
  public method = 'RegisterAbsence' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public absenceTypeId: string,
    public storedViewName: string,
    public returnDate: Date | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExList>): TeamShiftExList {
    return getTeamShiftExListFromJSON(json.result);
  }
}

/**     % Remove #shiftId in #teamId
Responsible: RATJ */
export class EndAbsence extends WebRequest<TeamShiftExList> {
  public webService = 'teamPlan' as const;
  public method = 'EndAbsence' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public storedViewName: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExList>): TeamShiftExList {
    return getTeamShiftExListFromJSON(json.result);
  }
}

/**     % WI93035 Mark shift with absence as handled.
    % The exact behavior depends on the planning model:
    % - shiftOnly does nothing,
    % - shift + activity removes the activities,
    % - production turns off the alarm regarding the uncovered resourceassignment.
Responsible: LABO */
export class MarkAsHandled extends WebRequest<TeamShiftExSingle> {
  public webService = 'teamPlan' as const;
  public method = 'MarkAsHandled' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public storedViewName: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExSingle>): TeamShiftExSingle {
    return getTeamShiftExSingleFromJSON(json.result);
  }
}

/**     % Returns the list of persons which can take #shiftId, as well as an indication of how well they fit wrt cost and rule violations
    % The list is sorted from best fit to worst fit, so the caller should not sort the results. See WI 88141
Responsible: RATJ */
export class GetCallInList extends WebRequest<CallIn[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetCallInList' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public updatedPeriod: DomainWsIntervalType | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<CallIn[]>): CallIn[] {
    return json.result.map(getCallInFromJSON);
  }
}

/**     % Assign #personId a copy of #shiftId, mark #shiftId as handled, and return both the assigned shift and the copies
    % This call does not support assigning shifts to people in other teams (for historical reasons). See WI 88145
Responsible: LABO */
export class AssignCallInList extends WebRequest<TeamShiftExList> {
  public webService = 'teamPlan' as const;
  public method = 'AssignCallInList' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
    public personIdList: number[],
    public storedViewName: string,
    public updatedPeriod: DomainWsIntervalType | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExList>): TeamShiftExList {
    return getTeamShiftExListFromJSON(json.result);
  }
}

/**     % Returns the list of possible teamShiftDef (shift_period_defs and their labels) which can be registerred at some point in #period.
    % See WI147378
Responsible: LABO */
export class GetShiftDefs extends WebRequest<TeamShiftDef[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetShiftDefs' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftDef[]>): TeamShiftDef[] {
    return json.result.map(getTeamShiftDefFromJSON);
  }
}

/**     % Returns the dutylines corresponding to #shiftDefId defined at #unitid. Note #unitid has to match #teamShiftDef.unitid.
    % Used in the frontend to get a preview of which dutylines are being inserted
Responsible: LABO */
export class GetModifiedDutyLines extends WebRequest<TeamDuty[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetModifiedDutyLines' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public unitid: string,
    public startDate: Date,
    public shiftDefId: string,
    public period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamDuty[]>): TeamDuty[] {
    return json.result.map(getTeamDutyFromJSON);
  }
}

/**     % Returns the list of persons which can take #shiftDefId, as well as an indication of how well they fit wrt cost and rule violations.
    % Like getCallInList, but working with a new shift, rather than an existing shift. See WI147378
Responsible: LABO */
export class GetShiftCandidateList extends WebRequest<CallIn[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetShiftCandidateList' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public startDate: Date,
    public shiftDefId: string,
    public period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<CallIn[]>): CallIn[] {
    return json.result.map(getCallInFromJSON);
  }
}

/**     % Assign #personId a copy of #shiftDefId on #startDate.
    % Like assignCallInList, but working with a new shift, rather than an existing shift. See WI147378
Responsible: LABO */
export class AssignShiftCandidate extends WebRequest<TeamShiftExSingle> {
  public webService = 'teamPlan' as const;
  public method = 'AssignShiftCandidate' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public startDate: Date,
    public personId: number,
    public storedViewName: string,
    public shiftDefId: string,
    public period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExSingle>): TeamShiftExSingle {
    return getTeamShiftExSingleFromJSON(json.result);
  }
}

/**     % Remove #shiftId in #teamId. Should call getDeviatingShiftInfo and getRemovedRegistrationInfo first.
Responsible: JBL */
export class RemoveShift extends WebRequest<Enums.WebmethodVoidPlaceholderEnum> {
  public webService = 'teamPlan' as const;
  public method = 'RemoveShift' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public shiftId: string,
    public teamId: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<Enums.WebmethodVoidPlaceholderEnum>): Enums.WebmethodVoidPlaceholderEnum {
    return getEnumFromJSON(json.result, Enums.WebmethodVoidPlaceholderEnum)  }
}

/**     % Edit #shiftId in #teamId, updating its shift_def id and period. Should call getDeviatingShiftInfo and getRemovedRegistrationInfo first.
    % Editing the shift_reg will automatically update activity_regs which start or end at the same time as the shift_reg and delete any activity_regs which are no longer covered by a shift.
Responsible: RATJ */
export class EditShift extends WebRequest<TeamShiftExSingle> {
  public webService = 'teamPlan' as const;
  public method = 'EditShift' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public shiftId: string,
    public shiftDefId: string,
    public storedViewName: string,
    public period: DomainWsIntervalType,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamShiftExSingle>): TeamShiftExSingle {
    return getTeamShiftExSingleFromJSON(json.result);
  }
}

/**     % Returns infomation about deviations-from-shift-defition.
    % Should be called when editing the shift, such that the user becomes aware of deviations that may be dropped by the edit.
Responsible: RATJ */
export class GetDeviatingShiftInfo extends WebRequest<DeviatingShiftInfo[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetDeviatingShiftInfo' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public shiftId: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<DeviatingShiftInfo[]>): DeviatingShiftInfo[] {
    return json.result.map(getDeviatingShiftInfoFromJSON);
  }
}

/**     % Returns info about registrations that will be removed as a consequence of changing the period of #shiftId to #updatedPeriod.
    % Should be called before removeShift and before editShift.
    % Note that this method does not inform about overlapping shifts, since the frontend should check overlapping shifts (editShift does check for overlapping shifts).
    % Note that this method does not inform about shifts with corresponding exchange_regs, since the frontend should check whether status=offered (if an offered shift is edited, then all the exchange_regs are implicitly cancelled).
Responsible: LABO */
export class GetRemovedRegistrationInfo extends WebRequest<TeamActivity[]> {
  public webService = 'teamPlan' as const;
  public method = 'GetRemovedRegistrationInfo' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public teamId: string,
    public shiftId: string,
    public isDelete: boolean,
    public updatedPeriod: DomainWsIntervalType | null,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<TeamActivity[]>): TeamActivity[] {
    return json.result.map(getTeamActivityFromJSON);
  }
}

