/**
 * This is the main entry point for the application and should be the only
 * source file where code is executed.
 */
import 'styling';

import { createRoot } from 'react-dom/client';

import { AppEventCallbacks, AppEvents, PdcConfig } from './PdcConfig';

import { RouterProvider } from 'react-router-dom';
import NanoEvents from 'nanoevents';
import { Provider } from 'react-redux';
import { ROOT_ID } from './constants';
import React from 'react';
import { Store } from './rootReducer';
import { configureStore } from './storeConfiguration';
import { debug as debugMaker } from 'debug';
import getApi from './getApi';
import { resumeSession } from './commonAction';
import { setupPushNotifications } from './api/PushNotification';
import { router } from './router';
import { localStorageGet } from './util/localStorage';
import { LocalStorageKey } from './util/LocalStorageKey';

const debug = debugMaker('TeamPlan/index');

// TODO: get the proper initial value here and avoid casting to Store
const initState: Store = {} as Store;

const pdc: PdcConfig = (window.pdc = window.pdc || ({} as PdcConfig));


// DO NOT REMOVE THIS!!! THIS IS JUST TO CREATE A INDENTIFIER FOR PDC-SHELL TO NOTICE ITS A MODULE BUILD
document.body.classList.add('IS_MODULE_BUILD');

declare global {
  interface Window {
    pdc: PdcConfig;
  }
}

const appEvents = new NanoEvents() as unknown as AppEvents;

// To configure and start teamplan the `config` and `start` events must be
// dispatched.
//
// * The `config` event is used to set configuration from custom.js.  It should
//   be the first event to be started.  Have a look at custom.js to see an
//   example.
//
// * The `start` event is used to set up teamplan and finally mount and render
//   the application to the DOM.
//
//   When debugging on phones it is useful to be able to load the application,
//   attach a debugger (chrome devtools or weinre) and then be able to manually
//   start the application and in this way postponing any requests made to the
//   backend API until there is a running debug session.
//
//   T do this you can comment out `appEvents.emit('start')` in the onLoad function and
//   call `appEvents.emit('start')` manually once in a debug session.
//
// To render the teamplan application to the dom the 'start' event must be
// dispatched
//
// app events:
// -----------
// config: Used by custom.js to configure the app
// start: mount teamplan and start it

// Include some help in dev mode
if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production') {
  pdc.help = () => {
    console.log(`Usage:
      // start the application
      pdc.dispatch('start')
    `);
  };
}

let dispatchQueue: null | [keyof AppEventCallbacks][] = null;
pdc.dispatch = (signal) => {
  if (!dispatchQueue) {
    dispatchQueue = [];
  }
  dispatchQueue.push([signal]);
  if (appEvents) {
    (dispatchQueue || []).forEach((arg) => {
      let [signal0] = arg;
      appEvents.emit(signal0);
    });
    dispatchQueue = [];
  }
};

appEvents.on('start', function teamPlanStartFunction() {
  debug('start');

  const store = configureStore(initState);

  const api = getApi();

  // Try to set up push notifications a number of times before giving up
  // TODO: Exponential backoff
  // TODO: Proper error message
  function pushSetup(maxTries: number) {
    maxTries = typeof maxTries === 'number' ? maxTries : 10;
    if (maxTries === 0) {
      return;
    }
    const authToken = localStorageGet(LocalStorageKey.token);
    if (!authToken) {
      setTimeout(() => pushSetup(maxTries - 1), 400);
      return;
    }
    setupPushNotifications(api, store.dispatch);
  }

  appEvents.on('setup-push', () => {
    debug('setup-push');
    pushSetup(20);
  });

  let reactContainer = document.getElementById(ROOT_ID);
  if (!reactContainer) {
    // This happens when TeamPlan runs in PDC Shell
    reactContainer = document.createElement('div');
    reactContainer.setAttribute('id', ROOT_ID);
    document.body.appendChild(reactContainer);
  }

  createRoot(reactContainer).render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );

  /* this action should always be fired on app init
  its used to load all saved data, like store persistance.*/
  store.dispatch(resumeSession());
});

//start the application
appEvents.emit('start');
