import { AsyncThunk } from '../types';

import { CallIn } from '../api/TeamPlan_api';
import { attemptSendNotificationLog } from '../appActions';
import getApi from '../getApi';
import { CreatedShift } from '../ListView/CreateShift/EditedShift';
import { Period } from '../util/dates';

export type FindSubstituteViewAction =
  | {
      type: 'LOADSUBSTITUTES_REQUEST';
    }
  | {
      type: 'LOADSUBSTITUTES_SUCCESS';
      loadSubstitutesResponse: CallIn[];
    }
  | {
      type: 'LOADSUBSTITUTES_FAILURE';
    }
  | {
      type: 'SET_LOADEDFORSHIFT';
      id: string;
    }
  | {
      type: 'RESET_SUBSTITUTE_STORE';
    };

export const loadSubstitutesRequest = (): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_REQUEST',
});

export const loadSubstitutesSuccess = (
  loadSubstitutesResponse: CallIn[]
): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_SUCCESS',
  loadSubstitutesResponse,
});

export const loadSubstitutesFailure = (): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_FAILURE',
});

export const setLoadedForShift = (id: string): FindSubstituteViewAction => ({
  type: 'SET_LOADEDFORSHIFT',
  id,
});

export const resetSubstituteStore = (): FindSubstituteViewAction => ({
  type: 'RESET_SUBSTITUTE_STORE',
});

export function attemptLoadSubstitutes(
  shiftId: string,
  teamId: string,
  updatedPeriod: Period | null
): AsyncThunk {
  return async (dispatch, getState) => {
    const shiftMap = getState().listViewReducer.shiftMap[teamId];
    // Make extra sure that the shift exists as this is triggered in a useEffect, which could trigger by various route changes
    if (!shiftMap || !(shiftId in shiftMap)) {
      throw new Error();
    }
    dispatch(loadSubstitutesRequest());
    try {
      const substitutes = await getApi().getCallIn(
        shiftId,
        teamId,
        updatedPeriod
      );
      dispatch(loadSubstitutesSuccess(substitutes));
    } catch (err) {
      dispatch(loadSubstitutesFailure());
      if (err instanceof Error) {
        dispatch(attemptSendNotificationLog(err));
      }
    }
  };
}

export function attemptGetShiftCandidateList(
  teamId: string,
  createdShift: CreatedShift
): AsyncThunk {
  return async (dispatch) => {
    try {
      dispatch(loadSubstitutesRequest());
      const substitutes = await getApi().getShiftCandidateList(
        teamId,
        createdShift
      );
      dispatch(loadSubstitutesSuccess(substitutes));
    } catch (err) {
      dispatch(loadSubstitutesFailure());
      if (err instanceof Error) {
        dispatch(attemptSendNotificationLog(err));
      }
    }
  };
}

