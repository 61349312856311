import { Thunk, ThunkDispatch } from '../types';

import { TeamMap } from '../api/model';
import { attemptSendNotificationLog } from '../appActions';
import getApi from '../getApi';

export type ChooseTeamAction =
  | {
      type: 'LOADTEAMS_REQUEST';
    }
  | {
      type: 'LOADTEAMS_SUCCESS';
      loadTeamsResponse: TeamMap;
    }
  | {
      type: 'LOADTEAMS_FAILURE';
      errorMessage: string;
    };

export const loadTeamsRequest = (): ChooseTeamAction => ({
  type: 'LOADTEAMS_REQUEST',
});

export const loadTeamsSuccess = (
  loadTeamsResponse: TeamMap
): ChooseTeamAction => ({
  type: 'LOADTEAMS_SUCCESS',
  loadTeamsResponse,
});

export const loadTeamsFailure = (errorMessage: string): ChooseTeamAction => ({
  type: 'LOADTEAMS_FAILURE',
  errorMessage,
});

export const attemptLoadTeams = () => {
  // example using Api
  return (dispatch: ThunkDispatch) => {
    dispatch(loadTeamsRequest());
    return getApi()
      .getTeams()
      .then((teamList: TeamMap) => {
        dispatch(loadTeamsSuccess(teamList));
      })
      .catch((err) => {
        dispatch(loadTeamsFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
};
