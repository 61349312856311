import './App.scss';
import '@pdcfrontendui/components/style.css';
import '@pdcfrontendui/shared/reset.css';
import '@pdcfrontendui/staffplan/style.css';

import * as React from 'react';

import { Button, Modal, Toast } from '@pdcfrontendui/components';
import { ROOT_ID } from './constants';
import { EmployeeMap, GetVersionModel, TeamMap } from './api/model';
import { ErrorModal, intializeAuditLogging } from '@pdcfrontendui/staffplan';
import ModalWrapper, { ModalWrapperProps } from './Modal/ModalWrapper';
import { Period, toDefaultLoadPeriod } from './util/dates';
import {
  useLocation,
  type Location,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { getRouteState, useTeamId } from './routes';

import BottomMenu from './BottomMenu';
import ConfirmDialog from './components/ConfirmDialog';
import ErrorBoundaryContainer from './sharedComponents/ErrorBoundaryContainer';
import InfoDialog from './components/InfoDialog';
import IntervalPolling from './IntervalPolling';
import MenuContainer from './Menu/MenuContainer';
import { ToastType } from '@pdcfrontendui/components/Toast/Toast';
import { currentLanguage, setDefaultLanguageId } from './currentLanguage';
import ids from './testing/ids';
import { localStorageSet } from './util/localStorage';
import { LocalStorageKey } from './util/LocalStorageKey';
import { history } from './history';
import { RouteBlocker } from './components/RouteBlocker';
import { useIdle } from 'react-use';
import { useHideRoot } from './hooks/useHideRoot';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';

export type StateFromProps = {
  version: GetVersionModel | null;
  idleLogoutSeconds: number;
  employeeMap: Record<string, EmployeeMap>;
  currentDate: Date;
  shouldShowMenu: boolean;
  modalLoading: boolean;
  openModals: ModalWrapperProps[];
  toast: ToastType | null;
  errorModal: {
    error: unknown;
    shown: boolean;
  };
  showingRuleViolationInfo: boolean;
  showingDialog: boolean;
  teams: TeamMap;
};

export type DispatchFromProps = {
  toggleScreenSmall: () => void;
  toggleScreenBig: () => void;
  changeDate: (date: Date) => void;
  toggleMenu: () => void;
  closeModal: () => void;
  changePeriod: (period: Period) => void;
  loadShifts: (id: string, requestPeriod: Period, isPoll: boolean) => void;
  attemptGetGlobalSettings: () => void;
  checkNewMessages: (
    ids: number[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) => void;
  checkUnreadMessages: (
    teamIds: string[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) => void;
  getHolidayArray: () => void;
  hideErrorModal: () => void;
  queueToast: (message: string) => void;
  setLocationForTracking: (location: Location) => void;
  setCurrentTeam: (teamId: string) => void;
  setShowingRuleViolationInfo: (show: boolean) => void;
  getShiftDefinitions: (teamId: string, period: Period) => void;
  logout: () => void;
};

export default function App({
  currentDate,
  changeDate,
  changePeriod,
  attemptGetGlobalSettings,
  checkNewMessages,
  checkUnreadMessages,
  closeModal,
  employeeMap,
  errorModal,
  getHolidayArray,
  getShiftDefinitions,
  hideErrorModal,
  idleLogoutSeconds,
  loadShifts,
  logout,
  modalLoading,
  openModals,
  queueToast,
  setCurrentTeam,
  setLocationForTracking,
  setShowingRuleViolationInfo,
  showingDialog,
  shouldShowMenu,
  showingRuleViolationInfo,
  teams,
  toast,
  toggleMenu,
  toggleScreenBig,
  toggleScreenSmall,
  version,
}: DispatchFromProps & StateFromProps) {
  const isLoggedIn = useIsLoggedIn();
  const idle = useIdle(idleLogoutSeconds * 1000);
  if (isLoggedIn && idle) {
    logout();
  }

  const location = useLocation();
  // Make location globally available (how big a hack is it?)
  history.location = location;
  const navigate = useNavigate();
  history.navigate = navigate;
  let teamId = useTeamId();
  if (!teams[teamId]) {
    teamId = '';
  }

  // Align current team with store and local storage
  React.useLayoutEffect(() => {
    if (teamId) {
      setCurrentTeam(teamId);
      localStorageSet(LocalStorageKey.currentTeam, teamId);
    }
  }, [teamId, setCurrentTeam]);

  React.useEffect(setDefaultLanguageId, []);

  React.useEffect(() => {
    if (teamId) {
      getShiftDefinitions(teamId, toDefaultLoadPeriod(currentDate));
    }
  }, [teamId, currentDate, getShiftDefinitions]);

  React.useEffect(() => {
    changePeriod(toDefaultLoadPeriod(currentDate));
    changeDate(currentDate);
  }, [currentDate, changeDate, changePeriod]);

  React.useEffect(() => {
    attemptGetGlobalSettings();
    // This is used for choosing between single view or split view, dependant on screen size
    const mediaQuery = window.matchMedia('(min-width:  767px)'); // To use the same constraint as useIsDualView in PDCFrontendUI
    if (mediaQuery.matches) {
      toggleScreenBig();
    } else {
      toggleScreenSmall();
    }
    mediaQuery.addListener((mq) => {
      if (mq.matches) {
        toggleScreenBig();
      } else {
        toggleScreenSmall();
      }
    });
    getHolidayArray();
    return intializeAuditLogging(
      () => getRouteState(history.location)?.route ?? '', // .route can only be null for a split second, if user manually inputs a non-existing route
      [ROOT_ID]
    );
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLocationForTracking(location);
  }, [location]);

  const modalContent: ModalWrapperProps =
    openModals.length > 0
      ? openModals[openModals.length - 1]!
      : ({
          shouldShow: false,
          modalType: null,
        } as ModalWrapperProps);

  useHideRoot(openModals.length > 0, showingRuleViolationInfo, showingDialog);

  return (
    <ErrorBoundaryContainer>
      <RouteBlocker />
      {/* Routes are rendered inside Outlet */}
      <Outlet />
      {isLoggedIn && (
        <>
          <IntervalPolling
            currentDate={currentDate}
            callGetMessages={checkNewMessages}
            checkUnreadMessageCount={checkUnreadMessages}
            loadShifts={loadShifts}
            teamId={teamId}
            employees={employeeMap}
          />
          <ErrorBoundaryContainer>
            <MenuContainer
              onClose={toggleMenu}
              currentTeam={teamId}
              currentDate={currentDate}
              shouldShow={shouldShowMenu}
            />
          </ErrorBoundaryContainer>
          <BottomMenu />
          <ErrorBoundaryContainer>
            <Modal shown={showingRuleViolationInfo}>
              <Modal.Header>{currentLanguage.RuleBreak}</Modal.Header>
              <Modal.Body className="rule-violation-info ">
                {currentLanguage.RuleViolationInfoSortedCallList}
              </Modal.Body>
              <Modal.Buttons>
                <Button
                  onClick={() => {
                    setShowingRuleViolationInfo(false);
                  }}
                >
                  {currentLanguage.Close}
                </Button>
              </Modal.Buttons>
            </Modal>
          </ErrorBoundaryContainer>
        </>
      )}
      <ConfirmDialog />
      <InfoDialog />
      <ModalWrapper
        modalType={modalContent.modalType}
        modalBtn={modalContent.modalBtn}
        modalBackText={modalContent.modalBackText}
        shouldShow={modalContent.shouldShow}
        content={modalContent.content}
        disableBackOnLoad={modalContent.disableBackOnLoad}
        onClose={() => {
          closeModal();
        }}
        version={version ?? undefined}
        // loading={modalContent.loading} // TODO Loading should probably be specified for each modal
        loading={modalLoading}
      />
      <ErrorModal
        {...errorModal}
        getLang={() => currentLanguage}
        onBack={hideErrorModal}
        onCopyError={() => queueToast(currentLanguage.TheErrorMessageWasCopied)}
        backButtonId={ids.Modal.errorBack}
        copyButtonId={ids.Modal.errorCopy}
      />
      <ErrorBoundaryContainer>
        <Toast
          toast={toast}
          buttonId={ids.App.dismissToast}
          // Robots can be very slow, so a simple solution is to display the toast for longer
          // navigator.webdriver should always be true when running playwright tests
          displayTime={window.navigator.webdriver ? 60_000 : undefined}
        />
      </ErrorBoundaryContainer>
    </ErrorBoundaryContainer>
  );
}
