import FindSubstituteView, {
  DispatchFromProps,
  StateFromProps,
} from './FindSubstituteView';
import {
  attemptLoadSubstitutes,
  attemptGetShiftCandidateList,
  setLoadedForShift,
} from './FindSubstituteViewActions';

import { Store } from '../rootReducer';
import { ThunkDispatch } from '../types';
import { attemptGetEmployees } from '../ListView/ListViewActions';
import { connect } from 'react-redux';

export type FindSubstituteViewProps = {
  shiftId: string;
};

const mapStateToProps = (store: Store): StateFromProps => ({
  teamId: store.listViewReducer.currentTeam,
  loading: store.findSubstituteViewReducer.loading,
  substitutes: store.findSubstituteViewReducer.substitutes,
  employees:
    store.listViewReducer.employeeMap[store.listViewReducer.currentTeam] ?? {},
  loadedForShift: store.findSubstituteViewReducer.loadedForShift,
  shiftDraft: store.shiftViewReducer.shiftDraft,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  loadSubstitutes: (shiftId, teamId, updatedPeriod) => {
    void dispatch(attemptLoadSubstitutes(shiftId, teamId, updatedPeriod));
  },
  getShiftCandidateList: (teamId, createdShift) => {
    dispatch(attemptGetShiftCandidateList(teamId, createdShift));
  },
  setLoadedForShift: (id: string) => {
    dispatch(setLoadedForShift(id));
  },
  getEmployees: (personIds: number[], teamId: string) => {
    dispatch(attemptGetEmployees(personIds, teamId));
  },
});

export default connect<
  StateFromProps,
  DispatchFromProps,
  FindSubstituteViewProps,
  Store
>(
  mapStateToProps,
  mapDispatchToProps
)(FindSubstituteView);

