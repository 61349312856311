﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for Login
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  type DomainWsIntervalType,
  type DomainWsVariantObject,
  type DomainWsVariantAvailability,
  type DomainJsonObject,
  type DomainWspdclasttrans,
  type DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// Represents a user (employee or planner)
//  Responsible AGRU
export class UserKey {
  [key: string]: unknown;
  constructor(
    public UserId: number,
    public UserType: Enums.UserLoginTypeEnum) {}
}

export function getUserKeyFromJSON(jsonObj: StringObj): UserKey {
  return new UserKey(

      getInt(jsonObj, 'UserId'),

      getEnum(jsonObj, 'UserType', Enums.UserLoginTypeEnum)
  );
}

export function getUserKey(jsonObj: StringObj, prop: string): UserKey {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('UserKey', prop);
  assertObject(val);
  return getUserKeyFromJSON(val);
}

// Representation of login response
//  Responsible AGRU
export class LogonResponse {
  [key: string]: unknown;
  constructor(
    public token: string,
    public name: string,
    public userKey: UserKey,
    public staffUserType: Enums.StaffUserTypeEnum | null = null) {}
}

export function getLogonResponseFromJSON(jsonObj: StringObj): LogonResponse {
  return new LogonResponse(
    /* 512 */
      getString(jsonObj, 'token'),
    /* 80 */
      getString(jsonObj, 'name'),

      getUserKey(jsonObj, 'userKey'),

      getNullableEnum<Enums.StaffUserTypeEnum>(jsonObj, 'staffUserType', Enums.StaffUserTypeEnum)
  );
}

export function getLogonResponse(jsonObj: StringObj, prop: string): LogonResponse {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('LogonResponse', prop);
  assertObject(val);
  return getLogonResponseFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Login for user with given username and password plus a
    % deviceid used to send push messages back to that user
    % Returns a token to be used in future Authorization
    % headers if login succeeds
Responsible: JMQ */
export class Login extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'Login' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public username: string,
    public password: string,
    public deviceid: string,
    public application: Enums.ApplicationEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

/**     % Logout the current user (specified in the JWT) and end the pdcsession such that the JWT cannot be renewed
Responsible: JMQ */
export class Logout extends WebRequest<boolean> {
  public webService = 'login' as const;
  public method = 'Logout' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

/**     % StaffUser logon with frontend hashed password
    % The field LogonResponse.staffUserType always returns the usertype
Responsible: MMI */
export class Login_02 extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'Login_02' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public username: string,
    public passwordHash: string,
    public application: Enums.ApplicationEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

/**     % Make a frontend hashed password
Responsible: JMQ */
export class MakeFrontendHashedPassword extends WebRequest<string> {
  public webService = 'login' as const;
  public method = 'MakeFrontendHashedPassword' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public username: string,
    public password: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<string>): string {
    return getStringFromJSON(json.result);
  }
}

/**     % Logon with OpenId
    % Returns token from openid
Responsible: JBL */
export class LoginOpenId_employee extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'LoginOpenId_employee' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public accessCode: string,
    public redirectURI: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

/**     % Logon with OpenId
    % Returns token from openid
Responsible: JBL */
export class LoginOpenId_planner extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'LoginOpenId_planner' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public accessCode: string,
    public redirectURI: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

/**     % Logon with windows active directory
    % Returns token a token to be used in future Authorization
Responsible: AW */
export class LoginBasic extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'LoginBasic' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public username: string,
    public password: string,
    public application: Enums.ApplicationEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

/**     % Register a public key for biometric authentication on a device
Responsible: AGRU */
export class RegisterBiometricAuth extends WebRequest<boolean> {
  public webService = 'login' as const;
  public method = 'RegisterBiometricAuth' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public deviceId: string,
    public publicKey: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

/**     % Generate a one-time token for biometric authentication
Responsible: AGRU */
export class GetTokenForBiometricAuth extends WebRequest<string> {
  public webService = 'login' as const;
  public method = 'GetTokenForBiometricAuth' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public deviceId: string,
    public application: Enums.ApplicationEnum,
    public lastJwt: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<string>): string {
    return getStringFromJSON(json.result);
  }
}

/**     % Logon using biometric authentication
    % Returns a token to be used in future Authorization
Responsible: AGRU */
export class LogonBiometricAuth extends WebRequest<LogonResponse> {
  public webService = 'login' as const;
  public method = 'LogonBiometricAuth' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public deviceId: string,
    public signature: string,
    public application: Enums.ApplicationEnum,
    public lastJwt: string,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<LogonResponse>): LogonResponse {
    return getLogonResponseFromJSON(json.result);
  }
}

