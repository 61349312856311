import { LoginAction, LoginEvents } from './LoginAction';

import type { CommonAction } from '../commonAction';
import type { Reducer } from 'redux';
import { UserLoginTypeEnum } from '../api/enumLib_api';
import { NO_USER_USER_ID } from '../constants';

export type LoginReducer = {
  errorMessage: string;
  userType: UserLoginTypeEnum;
  userId: number;
  attemptingLogin: boolean;
  fullName: string;
};

const initialState: LoginReducer = {
  errorMessage: '',
  attemptingLogin: false,
  // Always a planner, currently
  userType: UserLoginTypeEnum.planner,
  userId: NO_USER_USER_ID,
  fullName: '',
};

const loginReducer: Reducer<LoginReducer> = (
  state: LoginReducer = initialState,
  action: LoginAction | CommonAction
): LoginReducer => {
  switch (action.type) {
    case LoginEvents.LOGIN_REQUEST:
      return {
        ...state,
        attemptingLogin: true,
        errorMessage: '',
      };
    case LoginEvents.LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.userId,
        userType: action.userType,
        fullName: action.fullName,
        attemptingLogin: false,
      };
    case LoginEvents.LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        attemptingLogin: false,
        userId: -1,
      };
    case LoginEvents.LOGOUT:
      return {
        ...state,
        userId: NO_USER_USER_ID,
        userType: UserLoginTypeEnum.planner,
      };
  }

  return state;
};

export default loginReducer;
