import './ShiftView.scss';

import * as dateFns from 'date-fns';

import {
  AbsenceType,
  CallIn,
  Exchange,
  Team,
  TeamShift,
  TeamShiftDef,
} from '../api/TeamPlan_api';
import { EmployeeMap, ShiftMap, TeamMap } from '../api/model';
import {
  ExchangeCandidateFilterEnum,
  ExchangeTypeEnum,
  TeamShiftStatusEnum,
} from '../api/enumLib_api';
import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import ShiftCard, { ShiftInfo } from './ShiftCard';
import { TeamViewMode, getSiteRoutes, useTeamRouteParams } from '../routes';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import BusinessCard from '../components/BusinessCard';
import Card from '../components/Card';
import Chat from '../Chat';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import type { DayMessageMap } from '../util/getChatKey';
import { EDITED_SHIFT_ID } from '../constants';
import {
  calculateModifiedDutylines,
  isCreatedShift,
  isEditedShift,
  ShiftDraft,
  ShiftDraftType,
} from '../ListView/CreateShift/EditedShift';
import OfferView from './OfferView';
import { Period } from '../util/dates';
import { ShiftViewProps } from './ShiftViewContainer';
import SwapView from './SwapView';
import { WebRecordType } from '../api/Common_api';
import { currentLanguage } from '../currentLanguage';
import { dateFormats } from '@pdcfrontendui/utils';
import ids from '../testing/ids';
import { useNavigate } from 'react-router-dom';
import useNotesAndMessages from '../hooks/useNotesAndMessages';
import { useAsync } from '@pdcfrontendui/hooks';
import getApi from '../getApi';

export type StateFromProps = {
  loading: boolean;
  loadingEmployee: boolean;
  loadingSwap: boolean;
  team: Team | null;
  shift: TeamShift | null;
  shiftRecordType: WebRecordType;
  employees: EmployeeMap;
  absenceTypes: AbsenceType[];
  isAssigningShift: boolean;
  currentDate: Date;
  dayMessageMap: DayMessageMap;
  userId: number;
  shifts: ShiftMap;
  swapDetails: Exchange | null;
  isSwapViewOpen: boolean;
  isOfferViewOpen: boolean;
  pendingAcceptRequest: boolean;
  pendingRejectRequest: boolean;
  substitutes: CallIn[];
  selectedExchangeId: number | null;
  acceptRequestEnabled: boolean;
  teams: TeamMap;
  shiftDraft: ShiftDraft | null;
  possibleCandidateFilters: ExchangeCandidateFilterEnum[];
  defaultExchangeCandidateFilter: ExchangeCandidateFilterEnum;
  selectedExchangeCandidateFilter: ExchangeCandidateFilterEnum | null;
  showConfirmDeleteShift: boolean;
  deleteShiftLoading: boolean;
  shiftDefMap: Record<string, TeamShiftDef>;
};

export type DispatchFromProps = {
  callMarkAsHandled: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callOfferOnShiftExchange: (
    shiftId: string,
    currentTeamId: string,
    teamIdList: string[],
    candidateFilter: ExchangeCandidateFilterEnum
  ) => void;
  callRegisterAbsence: (
    teamId: string,
    shiftId: string,
    absenceTypeId: string,
    returnDate: Date | null
  ) => void;
  callAssignShift: (
    teamId: string,
    personId: number,
    employeeName: string,
    shiftId: string,
    updatedPeriod: Period | null,
    currentlyOnFictive: boolean
  ) => void;
  callAssignCreatedShift: (
    teamId: string,
    personId: number,
    employeeName: string
  ) => void;
  loadAbsenceTypes: (shiftId: string, teamId: string) => void;
  callGetEmployees: (personIds: number[], teamId: string) => void;
  callPostMessage: (
    personId: number,
    messageText: string,
    isInternalMessage: boolean,
    date: Date
  ) => void;
  getExchange: (personId: number, shiftStart: Date) => void;
  callMarkMessagesAsRead: (messageIds: number[]) => void;
  callExchangeAccept: (
    unitId: string,
    requestExchangePeriod: Period,
    isSwap: boolean,
    offerPersonId: number,
    offerPersonName: string,
    offerExchangeId: number,
    requestPersonId: number | null,
    requestPersonName: string
  ) => void;
  setSwapViewOpen: (open: boolean) => void;
  setOfferViewOpen: (open: boolean) => void;
  callExchangeReject: (
    exchangeId: number,
    exchangePeriod: Period,
    exchangeUnitId: string,
    exchangeType: ExchangeTypeEnum,
    remark: string | null,
    isSwap: boolean
  ) => void;
  callCancelOfferOnShiftExchange: (
    teamId: string,
    personId: number,
    shiftId: string
  ) => void;
  exchangeSelectExchangeId: (exchangeId?: number) => void;
  updateSelectedCandidateFilter: (
    candidateFilter: ExchangeCandidateFilterEnum
  ) => void;
  attemptRemoveShift: (
    shiftId: string,
    teamId: string,
    employeeName: string,
    shiftLabel: string,
    shiftTimeLabel: string
  ) => Promise<void>;
  setConfirmDeleteModal: (shown: boolean) => void;
  attemptEndAbsence: (
    teamId: string,
    shift: TeamShift,
    employeeName: string
  ) => void;
  setShiftDraft: (draft: ShiftDraft | null) => void;
};

const ShiftView = ({
  employees,
  personId,
  team,
  shift,
  isAssigningShift,
  absenceTypes,
  loading,
  loadingEmployee,
  loadingSwap,
  shiftRecordType,
  currentDate,
  isSwapViewOpen,
  isOfferViewOpen,
  callRegisterAbsence,
  loadAbsenceTypes,
  getExchange,
  callGetEmployees,
  callMarkAsHandled,
  callAssignShift: _callAssignShift,
  callPostMessage,
  callMarkMessagesAsRead,
  setSwapViewOpen,
  setOfferViewOpen,
  callExchangeAccept,
  callExchangeReject,
  callCancelOfferOnShiftExchange,
  callOfferOnShiftExchange,
  attemptRemoveShift,
  exchangeSelectExchangeId,
  updateSelectedCandidateFilter,
  selectedExchangeId,
  dayMessageMap,
  userId,
  shifts,
  swapDetails,
  pendingAcceptRequest,
  pendingRejectRequest,
  substitutes,
  acceptRequestEnabled,
  teams,
  possibleCandidateFilters,
  defaultExchangeCandidateFilter,
  selectedExchangeCandidateFilter,
  deleteShiftLoading,
  showConfirmDeleteShift,
  setConfirmDeleteModal,
  attemptEndAbsence: _attemptEndAbsence,
  setShiftDraft,
  shiftDefMap,
  shiftDraft,
  callAssignCreatedShift,
}: DispatchFromProps & StateFromProps & ShiftViewProps) => {
  // When accepting a swap, the shift is removed before the url is updated.
  // that puts us in a state where for a bit shift is not set (as it's trying to get)
  // a non existing shift. We can redesign the accept/refetch flow to prevent this.
  if (!shift || !team) return null;

  const navigate = useNavigate();
  const params = useTeamRouteParams();

  const callFindSubstitute = (
    teamId: string,
    shiftId: string,
    personId: number
  ) => {
    navigate(
      getSiteRoutes().team(teamId, {
        ...params,
        shiftId,
        personId,
        mode: TeamViewMode.FindSubstitute,
      })
    );
  };

  // If we are assigning a shift, we want to show if the recipient has shifts on the given day, and so we have to do a bit of a search
  // TODO CHHI: This seems overly expensive, and should be done differently
  const shiftsForRecipient = useMemo(
    () =>
      isAssigningShift
        ? Object.values(shifts).filter(
            (currentShift) =>
              currentShift.personId === personId &&
              dateFns.isSameDay(currentShift.period.from, shift.period.from)
          )
        : null,
    [personId, shift.period.from, isAssigningShift, shifts]
  );

  // Pre-fetch deleted activities
  const { value: deletedActivities, hasError: coudNotFetchDeletedActivities } =
    useAsync(
      shift.activities.length
        ? () =>
            getApi().getRemovedRegistrationInfo(
              team.id,
              isEditedShift(shiftDraft)
                ? shiftDraft.originalShift.id
                : shift.id,
              true,
              null
            )
        : null,
      [shift.activities.length, shift.id, team.id]
    );

  const __isEditedShift = shift.id === EDITED_SHIFT_ID;

  useEffect(() => {
    if (!shiftDraft && shift.id && team.id) {
      loadAbsenceTypes(shift.id, team.id);
    }
  }, [shift.id, team.id, loadAbsenceTypes, shiftDraft, params.mode]);

  useEffect(() => {
    if (
      shift.status === TeamShiftStatusEnum.offerActionRequired ||
      shift.status === TeamShiftStatusEnum.swapActionRequired
    ) {
      getExchange(personId, shift.period.from);
    }
  }, [shift.status, personId, shift.period.from, getExchange]);

  const setEditedShift = useCallback(
    (shiftDraft: ShiftDraft | null) => {
      setShiftDraft(shiftDraft);
      navigate(
        getSiteRoutes().team(team.id, {
          ...params,
          mode: TeamViewMode.CreateOrEdit,
        })
      );
    },
    [setShiftDraft, team.id, params, navigate]
  );

  const {
    messages,
    numMessages,
    hasUnreadMessages,
    markMessagesAsRead,
    toggleShowMessages,
    sendMessage,
    internalMessages,
    numInternalMessages,
    hasUnreadInternalMessages,
    markInternalMessagesAsRead,
    toggleShowInternalMessages,
    sendInternalMessage,
  } = useNotesAndMessages(
    userId,
    dayMessageMap,
    shift.period.from,
    personId,
    true,
    callMarkMessagesAsRead,
    callPostMessage
  );

  const substitute =
    params.mode === TeamViewMode.FindSubstitute
      ? substitutes.find((f) => f.id === personId)
      : undefined;

  const isSwap = swapDetails?.requestInfo.type === ExchangeTypeEnum.swapRequest;
  const employee = employees[personId];

  const canCancelOffer =
    !isAssigningShift &&
    (shift.status === TeamShiftStatusEnum.offered ||
      shift.status === TeamShiftStatusEnum.offerActionRequired);

  const callAssignShift = useCallback(
    (
      teamId: string,
      personId: number,
      employeeName: string,
      shiftId: string
    ) => {
      if (isEditedShift(shiftDraft)) {
        _callAssignShift(
          teamId,
          personId,
          employeeName,
          shiftDraft.originalShift.id,
          { from: shiftDraft.from, to: shiftDraft.to },
          false
        );
      } else if (isCreatedShift(shiftDraft)) {
        callAssignCreatedShift(teamId, personId, employeeName);
      } else if (!shiftDraft) {
        // TODO: fix on wi149459
        const currentEmployeeForShift = employees[shift.personId];
        _callAssignShift(
          teamId,
          personId,
          employeeName,
          shiftId,
          null,
          // !!currentEmployeeForShift?.isFictive
          false
        );
      }
    },
    [
      _callAssignShift,
      shift.personId,
      employees,
      callAssignCreatedShift,
      shiftDraft,
    ]
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      setScrollbarWidth(
        Math.round(
          scrollContainerRef.current.offsetWidth -
            scrollContainerRef.current.clientWidth
        )
      );
    }
  }, []);
  // 12 is the default padding, which needs to be subtracted
  const paddingRight = `${Math.min(scrollbarWidth - 12, 12)}px`;

  const shiftDef = shiftDefMap[shift.shiftDefId] ?? null;

  const attemptEndAbsence =
    (shift.status === TeamShiftStatusEnum.actionRequired ||
      shift.status === TeamShiftStatusEnum.absent ||
      shift.status === TeamShiftStatusEnum.replaced) &&
    shift.dutyLinesOriginal.length
      ? () => {
          _attemptEndAbsence(team.id, shift, employee?.name ?? '');
        }
      : undefined;

  return (
    <>
      <div className="shiftview-container" id={ids.ShiftView.container}>
        {employee && (
          <BusinessCard employee={employee} loadingEmployee={loadingEmployee} />
        )}
        <div
          ref={scrollContainerRef}
          className="content"
          style={{
            paddingRight,
          }}
        >
          {employee && !employee.isFictive && (
            <>
              <Card
                badge={numMessages}
                label={currentLanguage.ChatWithEmployee}
                onClick={toggleShowMessages}
                newBadge={hasUnreadMessages}
                id={ids.ShiftView.chat}
              >
                <Icons.Chat size={IconSize.XSmall} />
              </Card>
              <Card
                badge={numInternalMessages}
                label={currentLanguage.AddNote}
                onClick={toggleShowInternalMessages}
                newBadge={hasUnreadInternalMessages}
                id={ids.ShiftView.internalMessage}
              >
                <Icons.ChatLock size={IconSize.XSmall} />
              </Card>
            </>
          )}
          {shiftsForRecipient?.map((recipientShift) => (
            <ShiftInfo
              key={recipientShift.id}
              absenceType={absenceTypes.find(
                (absenceType) => absenceType.id === recipientShift.type
              )}
              dutyLines={recipientShift.dutyLines}
              shift={recipientShift}
              shiftDraft={shiftDraft}
            />
          ))}
          <ShiftCard
            {...{
              absenceTypes,
              callAssignShift,
              callFindSubstitute,
              callMarkAsHandled,
              callRegisterAbsence,
              callOfferOnShiftExchange,
              callCancelOfferOnShiftExchange,
              currentDate,
              personId,
              employees,
              loading,
              shift,
              isAssigningShift: isAssigningShift,
              shiftRecordType,
              team,
              loadingSwap,
              setSwapViewOpen,
              setOfferViewOpen,
              substitute,
              isSwap,
              canCancelOffer,
              onRemoveShift: () => {
                setConfirmDeleteModal(true);
              },
              setEditedShift,
              shiftDef,
              shiftDraft,
              attemptEndAbsence,
            }}
          />
        </div>
      </div>
      <Chat
        title={`${currentLanguage.ChatWith_1(
          employees[personId]?.name.split(' ')[0] ?? currentLanguage.Employee
        )}, ${dateFormats.Man_31DOT_okt(currentDate).toLowerCase()}`}
        goBack={toggleShowMessages}
        messages={messages}
        sendMessage={sendMessage}
        markMessagesAsRead={markMessagesAsRead}
      />
      <Chat
        title={`${currentLanguage.InternalMessages}, ${dateFormats
          .Man_31DOT_okt(currentDate)
          .toLowerCase()}`}
        goBack={toggleShowInternalMessages}
        messages={internalMessages}
        sendMessage={sendInternalMessage}
        markMessagesAsRead={markInternalMessagesAsRead}
      />
      <SwapView
        onBackClick={() => {
          setSwapViewOpen(false);
        }}
        show={isSwapViewOpen}
        swapDetails={swapDetails}
        employees={employees}
        team={team}
        isSwap={isSwap}
        pendingAcceptRequest={pendingAcceptRequest}
        pendingRejectRequest={pendingRejectRequest}
        selectedExchangeId={selectedExchangeId}
        acceptRequestEnabled={acceptRequestEnabled}
        onSelectExchangeId={(exchangeId) =>
          exchangeSelectExchangeId(exchangeId)
        }
        getEmployees={callGetEmployees}
        onAcceptExchange={() => {
          if (
            swapDetails?.requestInfo.personId &&
            (swapDetails.requestInfo.type === ExchangeTypeEnum.swapRequest ||
              selectedExchangeId)
          ) {
            const currentSwap = selectedExchangeId
              ? swapDetails.swapInfoList.find(
                  (f) => f.exchangeId === selectedExchangeId
                )
              : swapDetails.swapInfoList[0];

            if (currentSwap?.personId) {
              const offerName = employees[currentSwap.personId]?.name;
              const requestName =
                employees[swapDetails.requestInfo.personId]?.name;

              if (offerName && requestName) {
                callExchangeAccept(
                  swapDetails.requestInfo.unitId,
                  shift.period,
                  isSwap,
                  currentSwap.personId,
                  offerName,
                  currentSwap.exchangeId,
                  swapDetails.requestInfo.personId,
                  requestName
                );
              }
            }
          }
        }}
        onRejectExchange={() => {
          if (swapDetails) {
            callExchangeReject(
              swapDetails.requestInfo.exchangeId,
              shift.period,
              swapDetails.requestInfo.unitId,
              swapDetails.requestInfo.type,
              null,
              isSwap
            );
          }
        }}
      />
      <OfferView
        show={isOfferViewOpen}
        shift={shift}
        teams={teams}
        teamId={team.id}
        onBackClick={() => setOfferViewOpen(false)}
        possibleCandidateFilters={possibleCandidateFilters}
        defaultExchangeCandidateFilter={defaultExchangeCandidateFilter}
        acceptOffer={(teamIdList, candidateFilter) => {
          callOfferOnShiftExchange(
            shift.id,
            team.id,
            teamIdList,
            candidateFilter
          );
        }}
        rejectOffer={() => {
          setOfferViewOpen(false);
        }}
        selectedCandidateFilter={selectedExchangeCandidateFilter}
        updateSelectedCandidateFilter={updateSelectedCandidateFilter}
      />
      <ConfirmDeleteModal
        shown={showConfirmDeleteShift}
        shift={shift}
        loading={deleteShiftLoading}
        modifiedDutylines={
          shiftDef &&
          calculateModifiedDutylines(
            shift.dutyLinesOriginal.length
              ? shift.dutyLinesOriginal
              : shift.dutyLines,
            shiftDef
          )
        }
        onCancel={() => {
          setConfirmDeleteModal(false);
        }}
        onOk={(shiftTimeLabel) => {
          void attemptRemoveShift(
            shiftDraft?.type === ShiftDraftType.Edited
              ? shiftDraft.originalShift.id
              : shift.id,
            team.id,
            employee?.name ?? '',
            shift.label,
            shiftTimeLabel
          ).then(() => {
            navigate(getSiteRoutes().team(team.id));
          });
        }}
        coudNotFetchDeletedActivities={coudNotFetchDeletedActivities}
        deletedActivities={deletedActivities}
        employee={employee}
      />
    </>
  );
};

export default ShiftView;

