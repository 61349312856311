import {
  Navigate,
  RouteObject,
  createHashRouter,
  createMemoryRouter,
} from 'react-router-dom';
import { getSiteRoutes } from './routes';
import { connect } from './rootReducer';
import Login from './Login/LoginContainer';
import NoPersonelGroupSelected from './components/NoPersonalGroupSelected';
import MessageList from './ListView/MessageListContainer';
import { ErrorBoundary } from './components';
import { TeamContainer } from './Team';
import App from './AppContainer';
import { isCordova } from './isCordova';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';

/**
 * Guard that checks if the user is logged in, and if not, redirects to the login page.
 */
const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useIsLoggedIn();
  return isLoggedIn ? (
    children
  ) : (
    <Navigate to={getSiteRoutes().login} replace />
  );
};

/**
 * The inverse of AuthGuard. If the user is logged in, redirect to the team or teams page.
 */
const MaybeLogin = connect(
  (store) => ({
    currentTeam: store.listViewReducer.currentTeam,
  }),
  () => ({})
)(({ currentTeam }) => {
  const isLoggedIn = useIsLoggedIn();
  return isLoggedIn ? (
    <Navigate
      to={
        currentTeam ? getSiteRoutes().team(currentTeam) : getSiteRoutes().teams
      }
      replace
    />
  ) : (
    <Login />
  );
});

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />, // App is always rendered, regardless of the route
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: getSiteRoutes().login,
        element: <MaybeLogin />,
        errorElement: <ErrorBoundary />,
        index: true,
      },
      {
        path: getSiteRoutes().teams,
        element: (
          <AuthGuard>
            <NoPersonelGroupSelected />
          </AuthGuard>
        ),
        errorElement: <ErrorBoundary />,
        index: true,
      },
      {
        path: getSiteRoutes().team(),
        element: (
          <AuthGuard>
            <TeamContainer />
          </AuthGuard>
        ),
        errorElement: <ErrorBoundary />,
        index: true,
      },
      {
        path: getSiteRoutes().chat(),
        element: (
          <AuthGuard>
            <MessageList />
          </AuthGuard>
        ),
        errorElement: <ErrorBoundary />,
        index: true,
      },
      // Matches exactly "/"
      {
        element: <Navigate to={getSiteRoutes().team()} />,
        errorElement: <ErrorBoundary />,
        index: true,
      },
    ],
  },
  // 404
  {
    path: '*',
    element: <Navigate to={getSiteRoutes().team()} />,
    errorElement: <ErrorBoundary />,
    index: true,
  },
];

export const router = isCordova()
  ? createMemoryRouter(routes)
  : createHashRouter(routes);
