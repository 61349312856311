export enum ActionType {
  INIT = 'INIT',
  CHANGE_DATE = 'CHANGE_DATE',
  TOGGLE_CALENDAR = 'TOGGLE_CALENDAR',
  TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR',
  TOGGLE_MENU = 'TOGGLE_MENU',
  SET_MODALLOADING = 'SET_MODALLOADING',
  TOGGLE_SCREEN_SMALL = 'TOGGLE_SCREEN_SMALL',
  TOGGLE_SCREEN_BIG = 'TOGGLE_SCREEN_BIG',
  GETVERSION_REQUEST = 'GETVERSION_REQUEST',
  GETVERSION_SUCCESS = 'GETVERSION_SUCCESS',
  GETVERSION_FAILURE = 'GETVERSION_FAILURE',
  SET_VERSION = 'SET_VERSION',
  GETGLOBALSETTINGS_REQUEST = 'GETGLOBALSETTINGS_REQUEST',
  GETGLOBALSETTINGS_SUCCESS = 'GETGLOBALSETTINGS_SUCCESS',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_MODAL = 'OPEN_MODAL',
  GETGLOBALSETTINGS_FAILURE = 'GETGLOBALSETTINGS_FAILURE',
  GETUSERSETTINGS_REQUEST = 'GETUSERSETTINGS_REQUEST',
  GETUSERSETTINGS_SUCCESS = 'GETUSERSETTINGS_SUCCESS',
  GETUSERSETTINGS_FAILURE = 'GETUSERSETTINGS_FAILURE',
  GETMESSAGES_SUCCESS = 'GETMESSAGES_SUCCESS',
  POSTMESSAGE_SUCCESS = 'POSTMESSAGE_SUCCESS',
  POSTMESSAGE_FAILURE = 'POSTMESSAGE_FAILURE',
  SET_APP_LOADING = 'SET_APP_LOADING',
  PUSHNOTIFICATION_REGISTER_SUCCESS = 'PUSHNOTIFICATION_REGISTER_SUCCESS',
  PUSHNOTIFICATION_REGISTER_ERROR = 'PUSHNOTIFICATION_REGISTER_ERROR',
  PUSHNOTIFICATION_RECEIVED = 'PUSHNOTIFICATION_RECEIVED',
  PUSHNOTIFICATION_ERROR = 'PUSHNOTIFICATION_ERROR',
  RESUME_SESSION = 'RESUME_SESSION',
  GETUNREADMESSAGES_SUCCESS = 'GETUNREADMESSAGES_SUCCESS',
  GOT_HOLIDAYS = 'GOT_HOLIDAYS',
  QUEUE_TOAST = 'QUEUE_TOAST',
  SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG',
  HIDE_CONFIRM_DIALOG = 'HIDE_CONFIRM_DIALOG',
  SHOW_INFO_DIALOG = 'SHOW_INFO_DIALOG',
  HIDE_INFO_DIALOG = 'HIDE_INFO_DIALOG',
  SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL',
  HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  SET_SHOWING_RULE_VIOLATION_INFO = 'SET_SHOWING_RULE_VIOLATION_INFO',
  SET_SHIFTDEFMAP = 'SET_SHIFTDEFMAP',
}
