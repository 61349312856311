/**
 * The maximum number of days that the user can register an employee absent for (not counting the day of the shift).
 * Ie. if the user registers an employee absent on the 1st, the user can only register the employee absent until the 6th.
 */
export const ABSENCE_EXPECTED_BACK_MAX = 5;

/**
 * The default number of days to load when the app first loads.
 * If the user loads data for more days, polling periods are not affected.
 */
export const DAYS_LOADED_DEFAULT = 7;

/**
 * How much the active period is extended when clicking "load more" in list view.
 */
export const DAYS_LOADED_INCREMENT = 2;

/**
 * The number of days with which to expand the period if there are currently no shifts.
 * @example If the current period is June 10th - June 17th, then the period is expanded to June 7th - June 24th.
 */
export const NO_SHIFTS_DAYS_LOAD = { BACK: 3, FORWARD: 7 } as const;

/**
 * The interval, in milliseconds, with which to poll for unread messages and shifts.
 */
export const POLLING_INTERVAL_MS = 30_000; // 30 seconds

/**
 * The threshold for the user's number of teams, above which we consider the user to be managing more data than can be safely loaded.
 * If the user manages more than this number of teams, then data is only loaded for the currently selected team.
 * If the user manages equal or less than this number of teams, then data for all teams is loaded periodically.
 */
export const MULTI_TEAMS_LOAD_THRESHOLD = 10;

/**
 * The interval, in milliseconds, with which to poll for unread messages and shifts for all teams.
 * May only trigger if the user has access to a maximum of {@link MULTI_TEAMS_LOAD_THRESHOLD|10} teams.
 */
export const POLLING_INTERVAL_MULTI_MS = 300_000; // 5 minutes

export const EDITED_SHIFT_ID = 'edited';

/**
 * The maximum number of suggestions to show in the shift type suggestions dropdown.
 */
export const SHIFT_TYPE_SUGGESTIONS_MAX = 5;

/**
 * The maximum number of days in the future that the user can create an item for. Today-inclusive.
 */
export const CREATE_SHIFT_FUTURE_MAX_DAYS = 7;

/**
 * The maximum duration of a created shift, in days.
 */
export const CREATE_SHIFT_DURATION_MAX_DAYS = 2;

export const ROOT_ID = 'react-container';

/**
 * The maximum combined allowed employees in the personel groups a user can follow/favorite.
 * This is a hard limit, however a single group exceeding this number may be allowed.
 */
export const PERSONEL_GROUP_FOLLOW_MEMBERS_LIMIT = 200;

/**
 * If the user id is this value, then the user is not logged in.
 */
export const NO_USER_USER_ID = -1;
