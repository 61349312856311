import {
  CREATE_SHIFT_DURATION_MAX_DAYS,
  CREATE_SHIFT_FUTURE_MAX_DAYS,
  SHIFT_TYPE_SUGGESTIONS_MAX,
} from '../../constants';
import { useCallback, useEffect, useMemo } from 'react';
import {
  addDays,
  areRangesOverlapping,
  eachDay,
  startOfDay,
  isWithinRange,
  differenceInCalendarDays,
} from 'date-fns';
import {
  dateFormats,
  padDateOrTime as pad,
  dayKey,
} from '@pdcfrontendui/utils';
import { useBool } from '@pdcfrontendui/hooks';
import {
  CreatedShift,
  EditedShift,
  isCreatedShift,
  isEditedShift,
  isNewlyCreatedShift,
  ShiftDraft,
  ShiftDraftType,
} from './EditedShift';
import type {
  SearchableSelectGroup,
  SearchableSelectOption,
  SelectOption,
} from '@pdcfrontendui/components';
import {
  Person,
  TeamDuty,
  TeamShift,
  TeamShiftDef,
} from '../../api/TeamPlan_api';
import {
  applyMinuteOffsetInterval,
  formatMinuteOffsetInterval,
} from '../../util/minuteOffset';
import { currentLanguage } from '../../currentLanguage';
import { useFormik } from 'formik';
import { genitive } from '../../util/language';
import { TeamShiftStatusEnum } from '../../api/enumLib_api';

export type FormValues = {
  fromHours: string | null;
  fromMinutes: string | null;
  toHours: string | null;
  toMinutes: string | null;
  /** Start date. Time not used. */
  startDate: Date;
  /** How many days the shift extends. If 0, shift is exactly or less than 24 hours */
  extendNumDays: number;
  shiftDefId: string;
  dutyLines?: TeamDuty[];
};

export enum EditShiftErrorType {
  FieldsInvalid = 'FieldsInvalid',
  Overlaps = 'Overlaps',
  NotEdited = 'NotEdited',
}

export type EditShiftError =
  | Record<string, never> // Empty object = No errors. That's how Formik determines `isValid`.
  | {
      type: EditShiftErrorType.FieldsInvalid;
    }
  | {
      type: EditShiftErrorType.Overlaps;
      overlapsWith: TeamShift[];
      fromIsOverlapping: boolean;
      toIsOverlapping: boolean;
    }
  | {
      type: EditShiftErrorType.NotEdited;
    };

export function formatError(
  error: EditShiftError,
  employee: Person,
  shiftStartDate: Date
): string | { title: string; items: string[] } | null {
  switch (error.type) {
    case EditShiftErrorType.Overlaps: {
      const kl =
        currentLanguage.languageCode === 'Da' ||
        currentLanguage.languageCode === 'Se'
          ? 'kl. '
          : '';
      if (error.overlapsWith.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const overlap = error.overlapsWith[0]!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const firstName = employee.name.split(' ')[0]!;
        const firstNameGenitive = genitive(
          firstName,
          currentLanguage.languageCode
        );
        const dateDescription =
          differenceInCalendarDays(shiftStartDate, overlap.period.from) === 0
            ? currentLanguage.onThisDay
            : `${
                currentLanguage.capitalizeWeekdays
                  ? dateFormats.Mandag(overlap.period.from)
                  : dateFormats.Mandag(overlap.period.from).toLowerCase()
              } ${dateFormats.$31DOT_okt(overlap.period.from)}`;
        return `${
          error.fromIsOverlapping && error.toIsOverlapping
            ? currentLanguage.TheSpecifiedTimesOverlapWith
            : currentLanguage.TheSpecifiedTimeOverlapsWith
        } ${firstNameGenitive} ${
          currentLanguage.shift
        } ${dateDescription} ${kl}${dateFormats.$23DOT59(
          overlap.period.from
        )} - ${dateFormats.End_23DOT59(overlap.period.to)}.`;
      } else {
        return {
          title:
            error.fromIsOverlapping && error.toIsOverlapping
              ? currentLanguage.TheSpecifiedTimesOverlapWithTheFollowing
              : currentLanguage.TheSpecifiedTimeOverlapsWithTheFollowing,
          items: error.overlapsWith.map((shift) => {
            return `${
              currentLanguage.capitalizeWeekdays
                ? dateFormats.Mandag(shift.period.from)
                : dateFormats.Mandag(shift.period.from).toLowerCase()
            } ${dateFormats.$31DOT_okt(
              shift.period.from
            )} ${kl}${dateFormats.$23DOT59(
              shift.period.from
            )} - ${dateFormats.End_23DOT59(shift.period.to)}`;
          }),
        };
      }
    }
    default:
      return null;
  }
}

function fieldsAreValid(values: FormValues): boolean {
  return (
    !!values.shiftDefId &&
    !!values.fromHours &&
    !!values.fromMinutes &&
    !!values.toHours &&
    !!values.toMinutes
  );
}

function getNumExtendedDays(from: Date, to: Date): number {
  let days = (to.getTime() / 60_000 - from.getTime() / 60_000) / 1440;
  // If it's an integer, then we subtract one since it doesn't actually extend to the next day
  if (Math.round(days) === days) {
    days -= 1;
  }
  return Math.floor(days);
}

export function getDateOptions(
  start: Date,
  numDays: number,
  format: (date: Date) => string
): SelectOption[] {
  const floored = startOfDay(start);
  const dates = eachDay(floored, addDays(floored, numDays - 1));
  return dates.map((date) => ({
    key: dayKey(date),
    value: dayKey(date),
    label: format(date),
  }));
}

export const SUGGESTION_PREFIX = 'SUGGEST_';
export const UNKNOWN_SHIFT_DEF_ID = 'UNKNOWN_SHIFT_ID';

export function applyFormValuesToShiftDraft(
  shiftDraft: ShiftDraft,
  shiftDefMap: Record<string, TeamShiftDef>,
  values: FormValues
): CreatedShift | EditedShift | null {
  if (!fieldsAreValid(values)) {
    return null;
  }
  const def = isNewlyCreatedShift(shiftDraft)
    ? shiftDefMap[values.shiftDefId]
    : shiftDefMap[values.shiftDefId] ?? shiftDraft.def;
  if (!def) {
    return null;
  }
  const canSelectEndDate = def.editable && def.dutyLines.length > 1;
  const fromHoursNum = Number(values.fromHours);
  const fromMinutesNum = Number(values.fromMinutes);
  const toHoursNum = Number(values.toHours);
  const toMinutesNum = Number(values.toMinutes);
  const addedDays =
    (canSelectEndDate ? values.extendNumDays : 0) +
    (toIsBeforeFrom(values) ? 1 : 0);
  const from = new Date(
    values.startDate.getFullYear(),
    values.startDate.getMonth(),
    values.startDate.getDate(),
    fromHoursNum,
    fromMinutesNum
  );
  const to = addDays(values.startDate, addedDays);
  to.setHours(toHoursNum, toMinutesNum);

  if (isCreatedShift(shiftDraft) || isNewlyCreatedShift(shiftDraft)) {
    return {
      type: ShiftDraftType.Created,
      def,
      dutyLines: isNewlyCreatedShift(shiftDraft)
        ? values.dutyLines ?? []
        : values.dutyLines ?? shiftDraft.dutyLines,
      from,
      to,
    };
  }

  return {
    ...shiftDraft,
    def,
    dutyLines: values.dutyLines?.length
      ? values.dutyLines
      : shiftDraft.dutyLines.length
      ? shiftDraft.dutyLines
      : def.dutyLines,
    from: new Date(
      values.startDate.getFullYear(),
      values.startDate.getMonth(),
      values.startDate.getDate(),
      fromHoursNum,
      fromMinutesNum
    ),
    to,
  };
}

function toIsBeforeFrom(values: FormValues): boolean {
  return (
    !!values.fromHours &&
    !!values.fromMinutes &&
    !!values.toHours &&
    !!values.toMinutes &&
    values.toHours !== '24' &&
    `${pad(values.fromHours)}:${pad(values.fromMinutes)}` >=
      `${pad(values.toHours)}:${pad(values.toMinutes)}`
  );
}

function validNumberInput(
  value: string | null,
  min: number,
  max: number
): boolean {
  if (!value) {
    return true;
  }
  const num = Number(value);
  return num >= min && num <= max;
}
const defaultEditedShift: ShiftDraft = { type: ShiftDraftType.NewlyCreated };

export function useEditShift(
  today: Date,
  shiftDefMap: Record<string, TeamShiftDef>,
  shiftDraft: ShiftDraft | null,
  onSubmit: (shiftDraft: CreatedShift | EditedShift) => Promise<void>,
  teamId: string,
  shiftTypeSuggestionsMap: Record<string, string[]> | null,
  setShiftTypeSuggestions: (map: Record<string, string[]>) => void,
  otherPlannedEmployeeShifts: TeamShift[],
  employee: Person | null
) {
  shiftDraft ??= defaultEditedShift; // No logical difference between null and default in this hook, so we narrow the type

  const initialValues: FormValues = useMemo(() => {
    if (isNewlyCreatedShift(shiftDraft)) {
      return {
        fromHours: null,
        fromMinutes: null,
        toHours: null,
        toMinutes: null,
        shiftDefId: '',
        startDate: today,
        dutyLines: [],
        extendNumDays: 0,
      };
    }
    // defaultEditedShift has dummy values, so ignore those
    const toHours = pad(shiftDraft.to.getHours());
    const toMinutes = pad(shiftDraft.to.getMinutes());
    return {
      fromHours: pad(shiftDraft.from.getHours()),
      fromMinutes: pad(shiftDraft.from.getMinutes()),
      toHours: toHours === '00' && toMinutes === '00' ? '24' : toHours,
      toMinutes,
      shiftDefId: isEditedShift(shiftDraft)
        ? shiftDraft.def?.id ?? shiftDraft.originalShift.shiftDefId
        : shiftDraft.def.id,
      startDate: new Date(shiftDraft.from),
      dutyLines: shiftDraft.dutyLines.slice(),
      extendNumDays: getNumExtendedDays(shiftDraft.from, shiftDraft.to),
    };
  }, [shiftDraft, today]);

  const validate = useCallback(
    (values: FormValues): EditShiftError => {
      // Not having the shift def is not really an error, but user cannot edit without changing to a known one.
      if (!fieldsAreValid(values) || !shiftDefMap[values.shiftDefId]) {
        return { type: EditShiftErrorType.FieldsInvalid };
      }
      const updatedDraft = applyFormValuesToShiftDraft(
        shiftDraft,
        shiftDefMap,
        values
      );
      if (updatedDraft) {
        const overlapsWith: TeamShift[] = [];
        let fromIsOverlapping = false;
        let toIsOverlapping = false;
        for (const other of otherPlannedEmployeeShifts) {
          if (
            areRangesOverlapping(
              updatedDraft.from,
              updatedDraft.to,
              other.period.from,
              other.period.to
            )
          ) {
            overlapsWith.push(other);
            const fromIsLess = isWithinRange(
              updatedDraft.from,
              other.period.from,
              other.period.to
            );
            const toIsGreater = isWithinRange(
              updatedDraft.to,
              other.period.from,
              other.period.to
            );
            if ((fromIsLess && !toIsGreater) || (!fromIsLess && toIsGreater)) {
              if (fromIsLess) {
                // User can remove overlap by changing from
                fromIsOverlapping = true;
              } else {
                // User can remove overlap by changing to
                toIsOverlapping = true;
              }
            } else {
              // Any other case means that the updated draft extends beyond other
              // Unclear which end is overlapping, so both are marked
              fromIsOverlapping = true;
              toIsOverlapping = true;
            }
          }
        }
        if (overlapsWith.length > 0) {
          return {
            type: EditShiftErrorType.Overlaps,
            overlapsWith,
            fromIsOverlapping,
            toIsOverlapping,
          };
        }
      }
      if (
        isEditedShift(shiftDraft) &&
        updatedDraft?.def?.id === shiftDraft.originalShift.shiftDefId &&
        updatedDraft.from.getTime() ===
          shiftDraft.originalShift.period.from.getTime() &&
        updatedDraft.to.getTime() ===
          shiftDraft.originalShift.period.to.getTime()
      ) {
        return { type: EditShiftErrorType.NotEdited };
      }
      return {};
    },
    [shiftDraft, otherPlannedEmployeeShifts, shiftDefMap]
  );

  const {
    values,
    setValues,
    isValid,
    submitForm,
    resetForm,
    isSubmitting,
    errors,
    dirty,
    validateForm,
  } = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnMount: shiftDraft.type !== ShiftDraftType.Edited,
    initialValues,
    validate,
    onSubmit: async (values) => {
      const updatedDraft = applyFormValuesToShiftDraft(
        shiftDraft,
        shiftDefMap,
        values
      );
      if (updatedDraft) {
        await onSubmit(updatedDraft);
      }
    },
  });
  const error = errors as EditShiftError;

  const shiftDef = shiftDefMap[values.shiftDefId];
  const timeEditable = !!shiftDef?.editable;
  // Actual dutylines may have been modified such that length is shorter than the shiftDef, so the def should determine this
  const canSelectEndDate = timeEditable && shiftDef.dutyLines.length > 1;
  // If status is actionRequired, editing happens with finding a substitute in mind, and then type is not editable.
  const typeEditable =
    !isEditedShift(shiftDraft) ||
    shiftDraft.originalShift.status !== TeamShiftStatusEnum.actionRequired;

  const setFromHours = async (fromHours: string | null) => {
    if (timeEditable && validNumberInput(fromHours, 0, 23)) {
      await setValues((values) => {
        return { ...values, fromHours };
      });
    }
  };

  const setFromMinutes = async (fromMinutes: string | null) => {
    if (timeEditable && validNumberInput(fromMinutes, 0, 59)) {
      await setValues((values) => {
        return { ...values, fromMinutes };
      });
    }
  };

  const setToHours = async (toHours: string | null) => {
    const iuhiuh = shiftDef;
    if (timeEditable && validNumberInput(toHours, 0, 24)) {
      await setValues((values) => {
        return {
          ...values,
          toHours,
          toMinutes: toHours === '24' ? '00' : values.toMinutes,
        };
      });
    }
  };

  const setToMinutes = async (toMinutes: string | null) => {
    if (
      timeEditable &&
      values.toHours !== '24' &&
      validNumberInput(toMinutes, 0, 59)
    ) {
      await setValues((values) => {
        return { ...values, toMinutes };
      });
    }
  };

  const setStartDate = async (startDate: Date) => {
    await setValues((values) => ({ ...values, startDate }));
  };
  const setExtendBy = useCallback(
    async (endDate: string) => {
      if (
        timeEditable &&
        validNumberInput(endDate, 0, CREATE_SHIFT_DURATION_MAX_DAYS - 1)
      ) {
        await setValues((values) => ({
          ...values,
          extendNumDays: Number(endDate),
        }));
      }
    },
    [setValues, timeEditable]
  );

  const setShiftTypeKey = async (value: string) => {
    const shiftTypeKey = value.replace(SUGGESTION_PREFIX, '');
    const newShiftDef = shiftDefMap[shiftTypeKey] ?? null;
    if (newShiftDef) {
      await setValues((values) => {
        const newPeriod = applyMinuteOffsetInterval(
          values.startDate,
          newShiftDef.startTime,
          newShiftDef.endTime
        );
        const toHours = pad(newPeriod.to.getHours());
        const toMinutes = pad(newPeriod.to.getMinutes());
        const extendNumDays = getNumExtendedDays(newPeriod.from, newPeriod.to);
        return {
          ...values,
          startDate: startOfDay(newPeriod.from),
          endDate: startOfDay(newPeriod.to),
          fromHours: pad(newPeriod.from.getHours()),
          fromMinutes: pad(newPeriod.from.getMinutes()),
          toHours: toHours === '00' && toMinutes === '00' ? '24' : toHours,
          toMinutes,
          shiftDefId: shiftTypeKey,
          dutyLines: newShiftDef.dutyLines,
          extendNumDays,
        };
      });
      const shiftTypeSuggestions = shiftTypeSuggestionsMap?.[teamId];
      const newSuggested = shiftTypeSuggestions
        ? [
            shiftTypeKey,
            ...shiftTypeSuggestions
              .filter(
                (suggestion) =>
                  suggestion in shiftDefMap && suggestion !== shiftTypeKey
              )
              .slice(0, SHIFT_TYPE_SUGGESTIONS_MAX - 1),
          ]
        : [shiftTypeKey];
      setShiftTypeSuggestions({
        ...shiftTypeSuggestionsMap,
        [teamId]: newSuggested,
      });
    }
  };

  const isOverlapping = toIsBeforeFrom(values);

  const [_shouldShowEndDateSelect, showEndDateSelect] = useBool(false);
  const shouldShowEndDateSelect = _shouldShowEndDateSelect || canSelectEndDate;

  const shiftOptions: SearchableSelectGroup[] = useMemo(() => {
    const options: SearchableSelectGroup[] = [];
    const shiftTypeSuggestions = shiftTypeSuggestionsMap?.[teamId];
    if (shiftTypeSuggestions) {
      const subOptions = shiftTypeSuggestions
        .filter((suggestion) => suggestion in shiftDefMap)
        .map((suggestion): SearchableSelectOption => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const shiftDef = shiftDefMap[suggestion]!;
          return {
            label: shiftDef.label,
            value: SUGGESTION_PREFIX + suggestion,
            subLabel: formatMinuteOffsetInterval(
              shiftDef.startTime,
              shiftDef.endTime
            ),
          };
        });
      if (subOptions.length > 0) {
        options.push({
          label: currentLanguage.RecentlyUsedShiftDefs,
          options: subOptions,
        });
      }
    }
    // If def is unknown, user is able to reselect the original shift
    if (
      isEditedShift(shiftDraft) &&
      !shiftDefMap[shiftDraft.originalShift.shiftDefId]
    ) {
      options.push({
        label: currentLanguage.Original,
        options: [
          {
            label: shiftDraft.originalShift.label,
            value: shiftDraft.originalShift.shiftDefId,
          },
        ],
      });
    }
    options.push({
      options: Object.values(shiftDefMap).map((shiftDef) => ({
        label: shiftDef.label,
        value: shiftDef.id,
        subLabel: formatMinuteOffsetInterval(
          shiftDef.startTime,
          shiftDef.endTime
        ),
      })),
      label: currentLanguage.shift_plural,
    });
    return options;
  }, [shiftDraft, shiftDefMap, teamId, shiftTypeSuggestionsMap]);

  const startDateOptions = useMemo(
    () =>
      getDateOptions(
        today,
        CREATE_SHIFT_FUTURE_MAX_DAYS,
        dateFormats.Man_31DOT_okt
      ),
    [today]
  );

  const extendByOptions = useMemo(
    () =>
      Array.from({ length: CREATE_SHIFT_DURATION_MAX_DAYS }, (_, i) => ({
        key: i.toString(),
        value: i.toString(),
        label: dateFormats.$31DOT_okt(
          addDays(values.startDate, (isOverlapping ? 1 : 0) + i)
        ),
      })),
    [values.startDate, isOverlapping]
  );

  const endDate = addDays(
    values.startDate,
    values.extendNumDays + (isOverlapping ? 1 : 0)
  );

  const errorMessage = useMemo(
    () => employee && formatError(error, employee, values.startDate),
    [error, employee, values.startDate]
  );

  const fromHasError =
    error.type === EditShiftErrorType.Overlaps && error.fromIsOverlapping;
  const toHasError =
    error.type === EditShiftErrorType.Overlaps && error.toIsOverlapping;

  useEffect(() => {
    resetForm();
    void validateForm();
  }, [initialValues, resetForm, validateForm]);

  return {
    ...values,
    setFromHours,
    setFromMinutes,
    setToHours,
    setToMinutes,
    setExtendBy,
    isOverlapping,
    isValid,
    setShiftTypeKey,
    submitForm,
    setStartDate,
    extendByOptions,
    startDateOptions,
    timeEditable,
    shiftOptions,
    shouldShowEndDateSelect,
    showEndDateSelect,
    canSelectEndDate,
    endDate,
    isSubmitting,
    error,
    fromHasError,
    toHasError,
    errorMessage,
    dirty,
    typeEditable,
  };
}
