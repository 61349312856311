import { AbsenceType, Exchange } from '../api/TeamPlan_api';
import {
  ExchangeCandidateFilterEnum,
  ExchangeTypeEnum,
} from '../api/enumLib_api';

import { ActionType } from '../ActionType';
import { CommonAction } from '../commonAction';
import { ListViewAction } from '../ListView/ListViewActions';
import { Reducer } from 'redux';
import { ShiftViewAction } from './ShiftViewActions';
import { WebRecordType } from '../api/Common_api';
import { ShiftDraft } from '../ListView/CreateShift/EditedShift';

export type ShiftViewReducer = {
  loading: boolean;
  absenceTypes: AbsenceType[];
  shiftRecordType: WebRecordType;
  loadingSwap: boolean;
  swapDetails: Exchange | null;
  isSwapViewOpen: boolean;
  isOfferViewOpen: boolean;
  pendingAcceptRequest: boolean;
  pendingRejectRequest: boolean;
  acceptRequestEnabled: boolean;
  selectedExchangeId: number | null;
  shiftDraft: ShiftDraft | null;
  selectedCandidateFilter: ExchangeCandidateFilterEnum | null;
  showConfirmDeleteShift: boolean;
  deleteShiftLoading: boolean;
  showConfirmEditShift: boolean;
  editShiftLoading: boolean;
};

const initialState: ShiftViewReducer = {
  loading: false,
  absenceTypes: [],
  shiftRecordType: { fieldList: [], sortList: [], filter: '', webConfig: '' },
  loadingSwap: false,
  swapDetails: null,
  isSwapViewOpen: false,
  isOfferViewOpen: false,
  acceptRequestEnabled: false,
  pendingAcceptRequest: false,
  pendingRejectRequest: false,
  selectedExchangeId: null,
  shiftDraft: null,
  selectedCandidateFilter: null,
  showConfirmDeleteShift: false,
  deleteShiftLoading: false,
  showConfirmEditShift: false,
  editShiftLoading: false,
};

const shiftViewReducer: Reducer<ShiftViewReducer> = (
  state: ShiftViewReducer = initialState,
  action: ShiftViewAction | ListViewAction | CommonAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return initialState;
    case 'GETABSENCETYPES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GETABSENCETYPES_SUCCESS':
      return {
        ...state,
        loading: false,
        absenceTypes: action.getAbsenceTypesResponse,
      };
    case 'GETABSENCETYPES_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'REGISTERABSENCE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'REGISTERABSENCE_SUCCESS':
      return {
        ...state,
        loading: false,
        shiftRecordType: action.registerAbsenceResponse.recordType,
      };
    case 'REGISTERABSENCE_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'MARKASHANDLED_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'MARKASHANDLED_SUCCESS':
      return {
        ...state,
        loading: false,
        shiftRecordType: action.markAsHandledResponse.recordType,
      };
    case 'MARKASHANDLED_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'OFFER_ON_SHIFT_ECHANGE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'OFFER_ON_SHIFT_ECHANGE_SUCCESS':
      return {
        ...state,
        loading: false,
        shiftRecordType: action.offerOnShiftExchangeResponse.recordType,
        isOfferViewOpen: false,
      };
    case 'OFFER_ON_SHIFT_ECHANGE_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'CANCEL_OFFER_ON_SHIFT_ECHANGE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'CANCEL_OFFER_ON_SHIFT_ECHANGE_SUCCESS':
      return {
        ...state,
        loading: false,
        shiftRecordType: action.cancelOfferOnShiftExchangeResponse.recordType,
      };
    case 'CANCEL_OFFER_ON_SHIFT_ECHANGE_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'ASSIGNSHIFT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'ASSIGNSHIFT_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'ASSIGNSHIFT_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case 'LOADTEAMROSTER_SUCCESS':
      return {
        ...state,
        shiftRecordType: action.recordType,
      };

    case 'GETEXCHANGE_REQUEST':
      return {
        ...state,
        loadingSwap: true,
      };

    case 'GETEXCHANGE_SUCCESS':
      return {
        ...state,
        swapDetails: action.exchange,
        acceptRequestEnabled:
          action.exchange.requestInfo.type === ExchangeTypeEnum.swapRequest ||
          state.selectedExchangeId !== null,
        loadingSwap: false,
      };

    case 'SET_SWAP_VIEW_OPEN':
      return {
        ...state,
        isSwapViewOpen: action.open,
      };

    case 'SET_OFFER_VIEW_OPEN':
      return {
        ...state,
        isOfferViewOpen: action.open,
      };

    case 'EXCHANGE_ACCEPT_REQUEST':
      return {
        ...state,
        pendingAcceptRequest: true,
      };

    case 'EXCHANGE_REJECT_REQUEST':
      return {
        ...state,
        pendingRejectRequest: true,
      };

    case 'EXCHANGE_ACCEPT_SUCCESS':
    case 'EXCHANGE_REJECT_SUCCESS':
      return {
        ...state,
        pendingAcceptRequest: false,
        pendingRejectRequest: false,
        isSwapViewOpen: false,
        isOfferViewOpen: false,
        swapDetails: null,
      };

    case 'EXCHANGE_ACCEPT_FAILURE':
    case 'EXCHANGE_REJECT_FAILURE':
      return {
        ...state,
        pendingAcceptRequest: false,
        pendingRejectRequest: false,
      };

    case 'EXCHANGE_SELECT_EXCHANGE_ID':
      return {
        ...state,
        selectedExchangeId: action.exchangeId ?? null,
        acceptRequestEnabled: action.exchangeId !== undefined,
      };

    case 'SET_SHIFT_DRAFT':
      return {
        ...state,
        shiftDraft: action.data,
      };

    case 'UPDATE_SELECTED_CANDIDATE_FILTER':
      return {
        ...state,
        selectedCandidateFilter: action.candidateFilter,
      };
    case 'SET_CONFIRM_DELETE_MODAL':
      return {
        ...state,
        showConfirmDeleteShift: action.shown,
      };
    case 'REMOVE_SHIFT_REQUEST':
      return {
        ...state,
        deleteShiftLoading: true,
      };
    case 'REMOVE_SHIFT_FAILURE':
    case 'REMOVE_SHIFT_SUCCESS':
      return {
        ...state,
        deleteShiftLoading: false,
        showConfirmDeleteShift: false,
      };
    case 'SET_CONFIRM_EDIT_MODAL':
      return {
        ...state,
        showConfirmEditShift: action.shown,
      };
    case 'EDIT_SHIFT_REQUEST':
      return {
        ...state,
        editShiftLoading: true,
      };
    case 'EDIT_SHIFT_FAILURE':
    case 'EDIT_SHIFT_SUCCESS':
      return {
        ...state,
        editShiftLoading: false,
      };
  }

  return state;
};

export default shiftViewReducer;
