﻿/* Fully Generated from enumLib_api.ts */
////////////////// Enums /////////////////////////
/* This file contains, in this order:
 *
 *  1. Enums used in typescript
 *  2. get functions for each enum
 */
export enum AcceleratorItemTypeEnum {
  /**  unknown element  */
  undefined = 0,
  /**  menu accelerator  */
  menu = 1,
  /**  command accelerator */
  command = 2,
}
export enum AccessItemCategoryEnum {
  /** The implementation of this access item i purely manual - what is does is 100 o/o code only */
  simple = 1,
  /** The data access is granted pr orgunit. Mostly for table access */
  orgunit = 2,
  /** Access follows the persongroups in the user access assignment, but the access is limited to granted periods only */
  registratioN = 3,
  /** The data access is granted per persongroup - this is for persondata only */
  persongroup = 4,
}
export enum AccessItemEnumEnum {
  /**  */
  none_AccessItem = -1,
  /** Permits write access to secondary */
  shift_reg_AccessItem = 1,
  /** Permits write access to secondary */
  activity_reg_AccessItem = 2,
  /** Permits write access to secondary */
  availability_reg_AccessItem = 3,
  /**  */
  person_roster_reg_AccessItem = 4,
  /**  */
  person1_AccessItem = 5,
  /**  */
  person2_AccessItem = 6,
  /**  */
  person3_AccessItem = 7,
  /**  */
  shift_manpower_AccessItem = 8,
  /**  */
  activitygroup_AccessItem = 9,
  /**  */
  shift_qualification_AccessItem = 11,
  /**  */
  agreement_AccessItem = 12,
  /**  */
  occupation_AccessItem = 13,
  /**  */
  persongroup_AccessItem = 14,
  /**  */
  person_roster_def_AccessItem = 15,
  /**  */
  salarysort_AccessItem = 16,
  /**  */
  shift_def_AccessItem = 18,
  /**  */
  activity_def_AccessItem = 19,
  /**  */
  roster_def_AccessItem = 20,
  /**  */
  shifttype_AccessItem = 21,
  /**  */
  shiftcategory_AccessItem = 22,
  /**  */
  staffuser_AccessItem = 23,
  /**  */
  accessgroup_AccessItem = 24,
  /**  */
  useraccess_AccessItem = 25,
  /** Indstil System */
  system_setup_AccessItem = 26,
  /** Indstil Database */
  database_setting_AccessItem = 27,
  /**  */
  database_update_AccessItem = 28,
  /**  */
  database_import_AccessItem = 29,
  /**  */
  workrule_setup_AccessItem = 30,
  /** Permits write access to secondary */
  shift_reg_suppl_AccessItem = 31,
  /**  */
  orgunit_AccessItem = 32,
  /**  */
  salarysortdutyproperty_AccessItem = 33,
  /**  */
  salary_extract_AccessItem = 34,
  /**  */
  serviceno_change_AccessItem = 35,
  /**  */
  user_log_AccessItem = 36,
  /**  */
  costs_AccessItem = 37,
  /**  */
  calcsupl_AccessItem = 38,
  /**  */
  web_AccessItem = 39,
  /**  */
  substitute_AccessItem = 40,
  /**  */
  planstat_AccessItem = 41,
  /**  */
  demand_import_AccessItem = 42,
  /**  */
  salary_approval_AccessItem = 43,
  /**  */
  batchserver_AccessItem = 44,
  /**  */
  holiday_AccessItem = 45,
  /**  */
  agreement_personal_AccessItem = 46,
  /**  */
  orgunit_property_holidayopen_AccessItem = 47,
  /**  */
  orgunit_salarysystem_AccessItem = 48,
  /**  */
  person_find_advanced_AccessItem = 49,
  /**  */
  edit_imported_fields_AccessItem = 50,
  /**  */
  persongroup_qualification_AccessItem = 51,
  /**  */
  activitygroup_qualification_requirement_AccessItem = 52,
  /**  */
  person_qualification_AccessItem = 53,
  /**  */
  qualification_definition_AccessItem = 54,
  /**  */
  activity_qualification_requirement_AccessItem = 55,
  /**  */
  activitygrouptype_AccessItem = 56,
  /**  */
  activitygroupmember_AccessItem = 57,
  /**  */
  persongroup_payer_AccessItem = 58,
  /**  */
  demand_calculation_AccessItem = 59,
  /**  */
  salary_endorse_AccessItem = 60,
  /** Access to "Dan opgørelser" / autostatements (auto referring to the fact that the statement is derived from a total). DbDlg previously checked this accessitem for summary_statements on the norm shiftcounter. */
  autostatements_AccessItem = 61,
  /**  */
  keyFigureImport_AccessItem = 62,
  /**  */
  keyFigureExport_AccessItem = 63,
  /**  */
  keyFigureUI_AccessItem = 64,
  /**  */
  accountingOutside_AccessItem = 65,
  /**  */
  keyFigureReg1_AccessItem = 66,
  /**  */
  costPersonal_AccessItem = 67,
  /**  */
  keyFigureReg2_AccessItem = 68,
  /**  */
  keyFigureReg3_AccessItem = 69,
  /**  */
  keyFigureReg4_AccessItem = 70,
  /**  */
  keyFigureReg5_AccessItem = 71,
  /**  */
  recordrestriction_AccessItem = 72,
  /**  */
  orgunit_property_shop_AccessItem = 73,
  /**  */
  reg_person_status_operationstart_AccessItem = 74,
  /**  */
  reg_person_status_checkinstart_AccessItem = 75,
  /**  */
  reg_person_status_planningstart_AccessItem = 76,
  /**  */
  reg_person_status_requeststart_AccessItem = 77,
  /**  */
  persongroup_webstatus_AccessItem = 83,
  /**  */
  deputy_AccessItem = 84,
  /**  */
  deputy_serve_AccessItem = 85,
  /** Activates the production module */
  production_AccessItem = 86,
  /**  */
  administrator_AccessItem = 87,
  /**  */
  administratoradministrator_AccessItem = 88,
  /**  */
  publishWorkspaceToRoles_AccessItem = 90,
  /**  */
  editHelp_AccessItem = 91,
  /** Edit definition data for order and task */
  orderDef_AccessItem = 92,
  /** Edit order */
  orderReg_AccessItem = 93,
  /**  */
  taskReg_AccessItem = 94,
  /**  */
  equipment_AccessItem = 95,
  /** Assign to This Task */
  assignmentTask_AccessItem = 96,
  /** manual - Assign Person to Any Task. Permits write access to secondary */
  assignmentPerson_AccessItem = 97,
  /** manual - Assign Equipment to Any Task */
  assignmentEquipment_AccessItem = 98,
  /**  */
  taskDef_AccessItem = 99,
  /**  */
  equipment_Availability_AccessItem = 100,
  /**  */
  keyFigureReg_AccessItem = 101,
  /**  */
  keyFigureDef_AccessItem = 102,
  /**  */
  kPI_AccessItem = 103,
  /**  */
  variantDef_AccessItem = 104,
  /**  */
  baseTableDef_AccessItem = 105,
  /**  */
  baseTableReg_AccessItem = 106,
  /**  */
  deletePerson_AccessItem = 108,
  /**  */
  account_AccessItem = 109,
  /**  */
  project_AccessItem = 110,
  /**  */
  person_cpr_AccessItem = 111,
  /**  */
  publishViewToRoles_AccessItem = 112,
  /** manual - assign external reference on shift_reg (fakturakontrol) */
  shiftRegSetExternalReference_AccessItem = 113,
  /**  */
  simulation_AccessItem = 114,
  /** does the user have acces to save personal workspaces */
  publishWorkspacePersonal_AccessItem = 115,
  /** does the user have acces to save personal listview */
  publishViewPersonal_AccessItem = 116,
  /**  */
  cloneOrgunitData_AccessItem = 117,
  /**  */
  deputy_person_AccessItem = 118,
  /** Create, update or delete reservations including reservation members */
  reservation_AccessItem = 119,
  /** Swap of reservation members */
  reservationSwap_AccessItem = 120,
  /**  */
  changeVariant_AccessItem = 121,
  /**  */
  roleDef_AccessItem = 122,
  /** Permits write access to secondary */
  paidAllowance_AccessItem = 123,
  /**  */
  chat_AccessItem = 125,
  /** Run AutoAssign algorithm for assigning tasks to resources */
  autoAssign_AccessItem = 126,
  /**  */
  personLoanOut_AccessItem = 127,
  /**  */
  kpiBaseline_AccessItem = 128,
  /**  */
  kpiBaselineFreeze_AccessItem = 129,
  /**  */
  publishWorkspaceToSystem_AccessItem = 130,
  /**  */
  publishViewToSystem_AccessItem = 131,
  /** ; Removed this accessitem was introduced for SD in WI 76811 / HD 39559, but SD did not want it / balancesUiEdit_AccessItem            133 "@BalancesUiEditAccess" ( "balancesUiEdit" "writeAccessOnly" "simple" "[]" ) / old comment: Rediger personopgørelser direkte fra lister og kartotekskort. Skrivning er ekstra begrænset, da dataene oftests stammer fra eksterne systemer */
  orgunitVerifyPersonFieldList_AccessItem = 132,
  /**  */
  calcsuplSetup_AccessItem = 134,
  /**  */
  operationsAdministrator_AccessItem = 135,
  /**  */
  demand_AccessItem = 136,
  /**  */
  orgunitExtendedProperties_AccessItem = 137,
  /**  */
  resourceConstraintDef_AccessItem = 138,
  /** To see changelog for a staffuser or person you must have access to the staffusers unitid or one of then person2.planningunitid. To see changelog data, you must have read access to the data. */
  viewChangeLog_AccessItem = 139,
  /** to see auditLog for a staffuser or person you must have access to the staffusers unitid or one of then person2.planningunitid. to see auditLog for a staffusers data or persons data you must have access to the staffusers unitid or one of then person2.planningunitid. */
  viewAuditLog_AccessItem = 140,
  /** Access to dataPrivacy marked fields - with access demand */
  dataPrivacy_AccessItem = 141,
  /**  */
  removeUserLocks_AccessItem = 142,
  /**  */
  location_AccessItem = 143,
  /**  */
  dataSubjectInformation_AccessItem = 144,
  /**  */
  auxPerson_AccessItem = 145,
  /**  */
  anonymizePerson_AccessItem = 146,
  /**  */
  erasePerson_AccessItem = 147,
  /**  */
  systemConfig_AccessItem = 148,
  /**  */
  anonymizeDatabase_AccessItem = 149,
  /**  */
  vacationProfile_AccessItem = 150,
  /** Edit person1.isFictive */
  editIsFictive_AccessItem = 151,
  /**  */
  dimensionItem_tax_AccessItem = 152,
  /**  */
  staffuserExtended_AccessItem = 153,
  /** access for the functionPlan app */
  functionPlanPlanning_AccessItem = 154,
  /** Explicit access to payer orgunits */
  shift_reg_payer_AccessItem = 155,
  /** Access to datawarehouse export */
  datawarehouse_AccessItem = 156,
  /** Preference definitions. Enabling it in the installation also shows the preference_reg list */
  preferenceDef_AccessItem = 157,
  /** Access to the TeamPlan web application */
  teamPlan_AccessItem = 158,
  /** Preference registrations. Planners editing of emp preference_reg */
  preferenceReg_AccessItem = 159,
  /** Access to edit timeAttendance Reasons */
  timeAttendanceReason_AccessItem = 160,
  /** Edit Global Cost Parameters */
  socialCostAdministrator_AccessItem = 161,
  /** User access in the dashboard app */
  accessInDashboard_AccessItem = 162,
  /** Preference Pattern definitions. */
  preferencePatternDef_AccessItem = 163,
  /** Access to the criticalChange and criticalChangeRule */
  criticalRegChange_AccessItem = 164,
}
export enum AccessItemTypeEnum {
  /** The accessright can be No/Read/Write */
  readwrite = 1,
  /** The accessright can be No/Read - this is mainly used for access to functions */
  readonly = 2,
  /** The accessright can be No/Write -  This is only used for function which changes data */
  writeAccessOnly = 3,
  /** The accessright can be Read/Write */
  alwaysReadable = 4,
}
export enum AccessRightEnum {
  /**  accessright_nil  coded as "0". Even less access than "no". In staffinst "invisible" means no access for all regular users, access to all pdcusers, but hide the associated commands and menus from pdcusers (cmdaccess fails, useraccs succeeds)  */
  invisible = 48,
  /**  accessright_no  coded as "1". No access.  In staffinst "no" means no access for all regular users, but access to all pdcusers, including to commands and menus  */
  no = 49,
  /**  accessright_read  coded as "2"  */
  read = 50,
  /**  accessright_write  coded as "3"  */
  write = 51,
}
export enum AllowanceDutySubTypeEnum {
  /**  _ NUMBER SALARYSORT _ [PAYER] (prefixUnsigned) or _ _ SALARYSORT _ [PAYER] _ _ NUMBER (prefixUnsigned)  */
  integerDuty = 1,
  /**  NUMBER _ SALARYSORT _ [PAYER] (prefixNumber)  */
  durationDuty = 2,
}
export enum AllowOnExchangeEnum {
  /**  */
  never = 0,
  /**  */
  always = 1,
}
export enum AnonymizeEnum {
  /**  the database is not anonymized  */
  notAnonymized = 0,
  /**  the database is anonymized by sql script  */
  anonymizedByScript = 3,
  /**  person1.salaryid, initials and name are not anonymized - anonymized values in aux1-3  */
  anonymizedPartly3 = 6,
  /**  person1.salaryid and initials are not anonymized - anonymized values in aux1-2  */
  anonymizedPartly2 = 9,
  /**  every field is anonymized  */
  anonymizedFully = 12,
}
export enum AnonymizeStatusEnum {
  /**  Special Logic  */
  anonymizedBySpecialLogic = 0,
  /**  Anonymized by read/write  */
  anonymizedByReadWrite = 1,
  /**  Anonymized by update  */
  anonymizedByUpdateSql = 2,
  /**  Truncated  */
  anonymizedByTruncation = 3,
  /**  not anonymized  */
  notAnonymized = 4,
}
export enum ApplicationEnum {
  /**  staff windows application  */
  staff = 0,
  /**  staff windows application - in appserver mode  */
  appServer = 1,
  /**  staff windows application - in batchserver mode  */
  batchServer = 2,
  /**  Staff webservice  */
  httpServer = 7,
  /**  staff windows application - in systembridge mode  */
  systembridge = 8,
  /**  OBSOLETE HON Jan 2018: the staffweb dot net application  */
  staffWeb = 10,
  /**  the dot net based AvailabilityService  */
  availabilityService = 20,
  /**  Webservice for e.g. creating new staff users  */
  transactionService = 21,
  /**  Proxy to webservice serving mobile devices  */
  mobileWebProxy = 29,
  /**  Webservice serving mobile devices  */
  mobileWebservice = 30,
  /**  Web for use in mobile devices  */
  mobileWeb = 31,
  /**  OBSOLETE HON Jan 2018: iPhoneApp  */
  iphoneApp = 32,
  /**  Monitor watching the batchloader jobs  */
  batchMonitor = 40,
  /**  Administrating schema and applications  */
  administrator = 41,
  /**  Service for updating webuserschema  */
  staffAdmService = 42,
  /**  Configuration of SD loaders  */
  sdLoaderManager = 43,
  /**  Console program to manage applicationSetting(s)  */
  settingsManager = 44,
  /**  generic loader - jobLoader and personLoader adn clockingLoader  */
  loader = 50,
  /**  loader for SD primary loading persons  */
  sdLoader = 51,
  /**  JobLoader  */
  jobLoader = 52,
  /**  ClockingLoader - loading clockings  */
  clockingLoader = 53,
  /**  Messagingservice   */
  messagingService = 60,
  /**  Messagingservice inbound  */
  messagingServiceIn = 61,
  /**  Messagingservice outbound  */
  messagingServiceOut = 62,
  /**  Service for sending messages  */
  messageSender = 63,
  /**  script written in sql  */
  sqlScript = 70,
  /**  special upgrade DB program  */
  upgradeDB = 71,
  /**  Service for updating Exchange Server  */
  staffEWS = 80,
  /**  Testprogram for testing appserver queues  */
  queueTester = 90,
  /**  Utility for extracting Staff Web Service performance test data  */
  perfTestExtract = 91,
  /**  Windows service sending tracing log info to external webservice  */
  tracingService = 100,
  /**  Webservice and -application for function planning  */
  functionPlan = 110,
  /**  Webservice for Ella  */
  ellaService = 120,
  /**  Perform certain operations on MSMQ queues  */
  vipExecute = 130,
  /**  Manage and view log for MSMQ appservers  */
  vipMonitor = 131,
  /**  Get when the persons are available for work  (see also availabilityService 20) */
  vipAvailabilty = 140,
  /**  Booking ? */
  vipBooking = 141,
  /**  Registration of checkin  */
  vipCheckin = 142,
  /**  Get organisational structure (see also availabilityService 20)  */
  vipOrgunit = 143,
  /**  Get person information (see also availabilityService 20)  */
  vipPerson = 144,
  /**  Get person qualifications  */
  vipQualification = 145,
  /**  Salary ?  */
  vipSalary = 146,
  /**  ?  */
  vipStaff = 147,
  /**  Work planning for groups  */
  eWorkPlan = 150,
  /**  Common Authentication Service  */
  authenticationService = 151,
  /**  Shell App Dispatch Service  */
  dispatchService = 152,
  /**  Manager app webservice  */
  teamPlan = 201,
  /**  MyPlan app  */
  pdcShell = 250,
  /**  External integration webservice  */
  external = 300,
  /**  Airline CrewConnex app  */
  crewConnex = 2000,
}
export enum ApprovalTypeEnum {
  /**  Used for approvals from before the types were introduced.  */
  oldApproval = -1,
  /**  */
  salary = 1,
  /**  */
  absence = 2,
}
export enum AppServiceRequestStatusEnum {
  /**  (nontaken) */
  waiting = 1,
  /**  */
  success = 2,
  /**  */
  failed = 3,
}
export enum AppSettingCategoryEnum {
  /** MyPlan employees request, checkin, and self planning  */
  functionality = 1,
  /**  MyPlan settings which are just sent to frontend and the backend does not use for anything. */
  webClient = 2,
  /**  MyPlan plan display  */
  plans = 3,
  /**  The timings  */
  timings = 4,
  /** MyPlan orgunit, groups etc.  */
  organisation = 5,
  /** MyPlan Totals  */
  totals = 6,
  /**  MyPlan shift exchange (employee swaps and planner offers)  */
  exchange = 7,
  /** MyPlan support and systems administration   */
  support = 8,
  /**  MyPlan registration sorts  */
  sorts = 9,
  /**  MyPlan colleagues  */
  colleagues = 10,
  /** Validating the user identity using OpenID Connect  */
  authentication_openID = 11,
  /**  Validating the user identity  */
  authentication = 12,
  /** What does the user have access to? */
  authorization = 13,
  /**  MyPlan controlling task behaviour  */
  tasks = 14,
}
export enum AttendanceClockInOutEnum {
  /**  */
  clockIn = 0,
  /**  */
  clockOut = 1,
  /**  */
  clockInOut = 2,
}
export enum AttendanceStatusEnum {
  /**  Planned shift, but analysis time doesn't require checkin yet. Blue  */
  planned = 1,
  /**  Planned shift and person has checked in. Green  */
  onTime = 2,
  /**  Person has checked in but no shift is planned.  Green */
  extra = 3,
  /**  Planned shift and person has checked earlier than planned. Light Green  */
  tooEarly = 4,
  /**  Planned shift and person has checked later than planned. Dark Green  */
  tooLate = 5,
  /**  Planned shift,  analysis time doesn't require checkin yet, but very soon. Yellow  */
  critical = 6,
  /**  Planned shift, but person hasn't checked in. Red   */
  notAttending = 7,
  /**  Planned shift, but person isn't available due to illness, leave, ... No work checkin registered. Grey  */
  unavailable = 8,
}
export enum AuditFunctionEnum {
  /**  */
  noFunction = 0,
  /**  */
  listView = 1,
  /**  */
  listViewCopy = 2,
  /**  */
  listViewTable = 3,
  /**  */
  listViewPrint = 4,
  /**  */
  listViewMail = 5,
  /**  */
  listViewEmbedded = 6,
  /**  */
  listViewEmbeddedCopy = 7,
  /**  */
  listViewEmbeddedPrint = 8,
  /**  */
  listViewEmbeddedMail = 9,
  /**  */
  objDialog = 10,
  /**  */
  objDialogViewCopy = 11,
  /**  */
  objDialogPrint = 12,
  /**  */
  objDialogMail = 13,
  /**  */
  objDialogViewCopyMulti = 14,
  /**  */
  objDialogPrintMulti = 15,
  /**  */
  workspace = 20,
  /**  */
  workspaceCopy = 21,
  /**  */
  workspacePrint = 22,
  /**  */
  workspaceMail = 23,
  /**  */
  advancedFindPerson = 40,
  /**  */
  classicDialog = 50,
  /**  */
  cardexDialog = 55,
  /**  */
  classicList = 60,
  /**  */
  browseListView = 70,
  /**  */
  checkInMonitor = 80,
  /**  */
  workplanSortedCallinList = 90,
  /**  */
  planStat = 100,
  /**  */
  actWizardFindCandidates = 110,
  /**  */
  userLocks = 120,
  /**  */
  exchangePerson = 130,
  /**  */
  rosterrowRollout = 140,
  /**  */
  showLog = 150,
  /**  */
  workplanShiftSwap = 160,
  /**  */
  totalRecalculate = 170,
  /**  */
  shiftQualification = 180,
  /**  */
  reports = 190,
  /**  */
  datawarehouseClassic = 200,
  /**  */
  datawarehouseClassicStaffuser = 201,
  /**  */
  snapshotExport = 202,
  /**  */
  dutyAdjustments = 210,
  /**  */
  messageWithOptionalGDPR = 220,
  /**  */
  logWithOptionalGDPR = 230,
  /**  */
  ruleViolations = 240,
  /**  */
  actShiftSync = 250,
  /**  */
  webApproveWorkflow = 251,
  /**  */
  webServiceMethod = 300,
  /**  */
  dataSubjectInformation = 400,
  /**  */
  changeDBPassword = 500,
  /**  */
  missing_function = 1000,
  /**  */
  stopStaff = 9999,
}
export enum AuditLogNormalizedEnum {
  /**  */
  notNormalized = 0,
  /**  */
  normalized = 1,
  /**  */
  processing = 2,
}
export enum AvailabilityOpenHandlingEnum {
  /**  default. Availability is always closed  */
  disabled = 0,
  /**  Availability may be open, this is default for this shifttype when new availability made by the userinterface  */
  defaultOpen = 1,
  /**  Availability may be open, closed is default for this shifttype when new availability made by the userinterface  */
  defaultClosed = 2,
}
export enum AvailabilityShiftconvertEnum {
  /**  The exact part of shift overlapping absence is converted change_intersecting_shifts  */
  intersectingShifts = 1,
  /**  The whole shift is converted if shift-start truely overlaps absence  change_overlap_shiftsstart */
  overlapShiftsstart = 2,
  /**  The whole shift is converted if shift-start truely overlaps absence AND O.w.: The exact part of shift overlapping absence is converted. change_overlap_intersecting  */
  overlapIntersecting = 3,
  /**  If absence starts on or after shift-start the exact part of shift overlapping absence is converted O.w. The whole shift is converted if shift-start truely overlaps absence AND. change_overlap_intersect_intersecting  */
  overlapIntersectIntersecting = 4,
  /**  If shift is contaqined in absence period  */
  shiftconvertContained = 5,
  /**  The whole shift is converted if majority (>=50 PCT) overlaps whole-day absence, O.w. only part overlapping absence  */
  majorityOverlapWholeDay = 6,
}
export enum BardrawPropertyEnum {
  /**  */
  bar_not_in_filter = -2,
  /**  */
  invalid = -1,
  /**  */
  normal = 0,
  /**  */
  remark = 1,
}
export enum BatchJobExecutionResultEnum {
  /**  */
  ready = 0,
  /**  */
  endedOK = 1,
  /**  */
  running = 2,
  /**  */
  stoppedUserIntervention = 3,
  /**  */
  endedWarning = 4,
  /**  */
  stoppedFail = 5,
  /**  */
  stoppedError = 6,
  /**  */
  endedError = 7,
  /**  */
  rescheduled = 8,
}
export enum BatchJobStatusEnum {
  /**  */
  ready = 1,
  /**  */
  finished = 2,
  /**  */
  cancelled = 3,
  /**  */
  running = 4,
}
export enum BatchJobTypeEnum {
  /**  */
  unknownJobType = 0,
  /**  */
  personupdate = 1,
  /**  */
  salaryJob = 2,
  /**  */
  orgunitImport = 3,
  /**  */
  userImport = 4,
  /**  */
  setUserAccess = 5,
  /**  */
  updateUser = 6,
  /**  */
  clocking = 7,
  /**  */
  rosterDemand = 8,
  /**  */
  planDemand = 9,
  /**  */
  keyFigure = 10,
  /**  Generic transaction file in xml format  */
  transaction = 11,
  /**  Generic transaction file in csv format  */
  transactionCsv = 12,
  /**  Will usually be converted to one of the other types during load  */
  job = 13,
  /**  */
  qualification = 14,
  /**  For many customers, balance statements are imported as personupdate  */
  balanceImport = 15,
  /**  */
  personDelete = 16,
  /**  Just split lines  */
  splitStream = 17,
  /**  Check person field, and split lines  */
  personLine = 18,
  /**  */
  upsertUser = 19,
  /**  SD tjenestenummerkonvertering  */
  salaryIdConversion = 20,
}
export enum BatchTaskExecutionResultEnum {
  /**  */
  resultNil = 0,
  /**  */
  endedOK = 1,
  /**  */
  running = 2,
  /**  */
  stoppedUserIntervention = 3,
  /**  */
  endedWarning = 4,
  /**  */
  stoppedFail = 5,
  /**  */
  stoppedError = 6,
  /**  */
  endedError = 7,
  /**  */
  rescheduled = 8,
}
export enum BatchTaskStatusEnum {
  /**  */
  ready = 1,
  /**  */
  finished = 2,
  /**  */
  cancelled = 3,
  /**  move to batchjob table */
  moved = 4,
}
export enum BlobTypeEnum {
  /**  nil  */
  nil = 0,
  /**  log  */
  log = 1,
  /**  salary  */
  salary = 2,
  /**  printerConfig  */
  printerConfig = 3,
  /**  batchExecution  */
  batchExecution = 4,
}
export enum CalculationOrderEnum {
  /**  shiftcounter_def.shiftRegField is not put into shift_reg.shiftvalues  */
  disabled = 0,
  /**  shiftcounter_def.shiftRegField is put into shift_reg.shiftvalues before regular calcsupl calculations, so it can be modified by shiftcounter_rules  */
  beforeCalcsupl = 1,
  /**  shiftcounter_def.shiftRegField is put into shift_reg.shiftvalues after regular calcsupl calculations, so it can modify the calculations from calcsupl  */
  afterCalcsupl = 2,
}
export enum CapacityAggOpEnum {
  /**  Only Items that begin within slot are counted  */
  countFirst = 1,
  /**  Only Items that end within slot are counted  */
  countLast = 2,
  /**  Items intersecting slot are counted  */
  count = 3,
  /**  Minimum count for 5 minut slots  */
  countMin = 4,
  /**  Maximum count for 5 minut slots  */
  countMax = 5,
  /**  Average count for 5 minut slots  */
  countAverage = 6,
  /**  Man hour sum of slot, ie area duration * height in hours  */
  area = 7,
}
export enum CapacityCategoryEnum {
  /**  */
  act = 1,
  /**  */
  actGrp = 2,
  /**  */
  shift = 3,
  /**  */
  shiftType = 4,
  /**  */
  shiftCategory = 5,
  /**  */
  shiftCounter = 6,
  /**  */
  qualification = 7,
  /**  */
  disposal = 8,
  /**  */
  absence = 9,
  /**  */
  account = 10,
}
export enum CapacityQualificationBaseEnum {
  /**  Shifts are used for computing qualification capacity  */
  base_shift = 1,
  /**  Dispositions (positive avails) are used for computing qualification capacity  */
  base_disposition = 2,
  /**  Task RCs are used for computing qualification capacity  */
  base_taskResourceConstraints = 3,
}
export enum ChatMessageTypeEnum {
  /**  */
  undefined = 0,
  /**  Simple table  */
  simple = 1,
  /**  [Obsolete]  */
  group = 2,
  /**  Boardnote  */
  personal = 3,
  /**  Emp_day  */
  day = 4,
  /**  Swap messages  */
  swap = 7,
  /**  Deputy messages  */
  deputy = 8,
  /**  Union of Group and Personal  */
  colleague = 9,
  /**  FunctionPlan message  */
  functionPlan = 10,
}
export enum ChatTypesEnum {
  /**  */
  undefined = 0,
  /**  Simple table  */
  simple = 1,
  /**  Emp_day  */
  day = 4,
  /**  Instant Messages  */
  instant = 5,
  /**  Swap messages  */
  swap = 7,
  /**  Deputy messages  */
  deputy = 8,
  /**  Union of Group and Personal  */
  colleague = 9,
}
export enum ConfigModuleEnum {
  /**  nothing set  */
  noModule = -1,
  /**  costModule  */
  costModule = 100,
  /**  costModule  */
  datawareHouseClassicModule = 110,
}
export enum ContactFormTypeEnum {
  /**  */
  nil = -1,
  /**  */
  sms = 1,
  /**  */
  mail = 2,
  /**  */
  telephone = 3,
  /**  */
  face_face = 4,
  /**  */
  web = 5,
  /**  */
  other = 6,
}
export enum ConvertedByAvailabilityEnum {
  /**  AvailConverted has not been evaluated yet  */
  nilAvailConverted_SH = 0,
  /**  No overlapping availability_reg  */
  notAvailConverted_SH = 1,
  /**  Overlapping availability_reg  */
  availConverted_SH = 2,
  /**  AvailConverted has not been evaluated yet – sh de-selected  */
  nilAvailConverted_noSH = 3,
  /**   No overlapping availability_reg – sh de-selected  */
  notAvailConverted_noSH = 4,
  /**  Overlapping availability_reg – sh de-selected  */
  availConverted_noSH = 5,
}
export enum CostComputeTypeEnum {
  /**  The counter is not a part of the cost computation  */
  noCost = 0,
  /**  The counter is a part of the cost computation  */
  withCost = 1,
  /**  The counter is a part of the cost computation or a detailcostcounter. Overhead is not applied  */
  withoutOverhead = 2,
  /**  The counter is a detailcostcounter with overhead  */
  costDetailsWithOverhead = 3,
}
export enum CostHourComputationEnum {
  /**  occupationDecimal * 4.3333 * 37. 37 er antal timer pr. uge - BROM har givet mig formlen 2024.06.11  */
  normHourPrMonth = 1,
  /**  The sum of costNormCounter for the period  */
  normHourSumInPeriod = 2,
  /**  The occupationDecimal is multiplied to the Rate, there may be different rates for different steps. See agreement.effPeriodCostList.data.cost  */
  occupationDecimalFactor = 3,
  /**  The sum of costNormCounter for the period is multiplied to the Rate, there may be different rates for different steps See agreement.effPeriodCostList.data.cost  */
  normHourSumFactor = 4,
}
export enum CostPriceUnitEnum {
  /**  123,33   cost_priceunit_amount  */
  amount = 0,
  /**  89 o/o P cost_priceunit_pctPersonal  */
  pctPersonal = 1,
  /**  89 o/o   cost_priceunit_pctNormal  */
  pctNormal = 2,
  /**  10 o/o E cost_priceunit_pctPersonalExtra  */
  pctPersonalExtra = 3,
  /**  price for one hour work for persons with fixed pay  */
  pctFixedSalary = 4,
}
export enum DatabaseObjectTypeEnum {
  /**  function  */
  databaseFunction = 1,
  /**  procedure  */
  databaseProcedure = 2,
  /**  database type  */
  databaseType = 3,
}
export enum DataGuardEventEnum {
  /**  new user in context  */
  userChange = 0,
  /**  relevance changed  */
  relevanceChange = 1,
  /**  Global planstatus setting changed  */
  globalPlanstatusChange = 2,
  /**  change current role  */
  roleChange = 3,
  /**  a datamodel based metatable is created. Used for computed properties  */
  dataModelMetaTableCreate = 4,
  /**  the app changes current language  */
  languageChange = 5,
  /**  a new date (in one of the time zones). Called when idle  */
  dateChange = 6,
}
export enum DataKindEnum {
  /**  Aggregrate from across all the persons employments. Intended for StaffWeb only  */
  crossMix = 22,
  /**  Aggregrate the Kinds list into one row. Intended for StaffWeb only  */
  mix = 21,
  /**  Table availability_reg where shifttype.present = 0  */
  absence = 0,
  /**  Table activity_reg  */
  activity = 1,
  /**  Table availability_reg  */
  availability = 2,
  /**  Table demand  */
  demand = 3,
  /**  Table deputy_reg  */
  deputy = 4,
  /**  Table availability_reg where shifttype.present = 1  */
  presence = 5,
  /**  Tabel shift_reg which is not swappable  */
  shift = 6,
  /**  Exchange table objects  */
  swap = 7,
  /**  Table DeputyRequest that is published for exchange  */
  request = 8,
  /**  Artificial "Planning" planstatus hidden period place holders  */
  planStatus = 9,
  /**  Table prd_task_def (and emp ditto)  */
  task = 10,
  /**  Table shift_reg where planid = master && shifttype.present = 1 && start in swapPeriod && exchange_reg inprogress with the same start  */
  swappable = 11,
  /**  Artificial; represents activities used for functions (cf. activity_shift_assignment)  */
  function = 12,
  /**  Allowance from table availability_reg  */
  allowance = 13,
  /**  Transportation from table shift_reg  */
  transportation = 14,
  /**  Negative preference from table preference_reg  */
  negativePreference = 15,
  /**  Positive preference from table preference_reg  */
  positivePreference = 16,
}
export enum DataPrivacyEnum {
  /**  No data privacy defined - value used for implementation purposes  */
  undefined = 0,
  /**  No restrictions. Even people employed at other institutions can see the data.  */
  anyone = 10,
  /**  Planner and all coworkers (same institution) can see the data.  */
  coworker = 20,
  /**  Planner, and group co-members can see the data.  */
  groupColleague = 30,
  /**  Only planner's with access to the person  */
  planner = 40,
  /**  Only planner's with special accessitem privacy  */
  plannerAccess = 50,
  /**  Only planner can see the data, and only by explicit (and logged) request). Data is hidden in lists  */
  restricted = 60,
  /**  Only system can see the data, fx, use email to send new password, but no person shall see it. */
  system = 70,
}
export enum DatawarehouseParameterEnum {
  /**  datawarehouseMode  */
  dwMode = 10,
  /**  interval::intv  */
  dwPeriod = 20,
  /**  the first used (full) - in incrementalmode this is moved when the incremental phase succeds  */
  dwCurrentCommitId = 30,
  /**  in incrementalmode this was the last succesfull state  */
  dwLastCommitId = 40,
  /**  isodatetime - time for last success  */
  dwLastSuccess = 50,
  /**  isodatetime  */
  dwCreated = 60,
  /**  isodatetime  */
  dwUpdated = 70,
  /**  isodatetime  */
  dwDone = 80,
  /**  Text  */
  dwStatus = 90,
  /**  Text  */
  dwSubStatus = 100,
  /**  orgunit list  */
  dwRelevance = 110,
  /**  Program Version  */
  dwProgramVersion = 120,
  /**  Database Version  */
  dwDatabaseVersion = 130,
  /**  Source Database  */
  dwSourceDatabase = 140,
  /**  Creator info  */
  dwCreator = 150,
  /**  Reaedonly  */
  dwReadonly = 160,
}
export enum DatawarehouseProgressEnum {
  /**  datawarehouseMode  */
  dwStart = 1,
  /**  table name and position in relative count  */
  dwCurrentTable = 2,
  /**  phase for the table 1/1, 4/23, 7/108  */
  dwCurrentTablePhase = 3,
  /**  isodatetime  */
  dwRowCount = 4,
  /**  Text  */
  dwExpectedEnd = 5,
}
export enum DayOfWeekNameEnum {
  /**  */
  monday = 1,
  /**  */
  tuesday = 2,
  /**  */
  wednesday = 3,
  /**  */
  thursday = 4,
  /**  */
  friday = 5,
  /**  */
  saturday = 6,
  /**  */
  sunday = 7,
  /**  */
  holiday = 10,
}
export enum DefaultOnOffEnum {
  /**  use the system default value  */
  default = 2,
  /**  */
  on = 1,
  /**  */
  off = 0,
}
export enum DeputyMessageTypeEnum {
  /**  No changes, nothing sent  */
  deputyUnChanged = -2,
  /**  MessagingService internal value meaning type not yet computed, never saved.   */
  deputyInternalUndefined = 0,
  /**  The shift assigned to you has a change.  */
  deputyChangeAssigned = 1,
  /**  The shift has been assigned to you.  */
  deputyAssign = 2,
  /**  The shift assigned to you has been cancelled.  */
  deputyCancel = 3,
  /**  You have been planned to take shift. Please respond if you accept.  */
  deputyPlan = 4,
  /**  Never saved. Instead, each submessage is saved with its individual message type.  */
  deputySummary = 5,
  /**  Reject response to take planned shift: another has taken it, it was cancelled, other causes (expressed in msgsys.text).  */
  deputyReject = 6,
  /**  Accept response to take planned shift (accepted, or you already has been assigned).  */
  deputyAccept = 7,
  /**  A host of errors, mainly some error happened (such as appserver), and response not understood.  */
  deputyError = 8,
  /**  Assigned to another; unused in code  */
  deputyAssignedOther = 9,
  /**  Cancel of message that has not been sent, maybe because it was accepted from web; seemingly stored version of InternalIgnoreCancelPlanned.  */
  deputyIgnoreCancelPlanned = 10,
  /**  Accepted via web (not sms); USED in code (NOT replaced by IgnoreCancelPlanned)  */
  deputyWebAccept = 12,
  /**  The shift inquired (not yet assigned) for you has a change.  */
  deputyChangePlanned = 13,
}
export enum DeputyRegistrationStatusEnum {
  /**  Aflyst  */
  cancelled = 3,
  /**  Forespurgt  */
  inquired = 1,
  /**  Accepteret  */
  accepted = 2,
}
export enum DeputyRegistrationStatusCauseEnum {
  /**  Not cancelled  */
  undefined = -1,
  /**  Cancelled  */
  cancelled = 1,
  /**  The deputyrequest was assigned another person  */
  cancelledInquiry = 2,
  /**  The person rejected the deputyrequest  */
  cancelledDeputy = 3,
  /**  The department cancelled the deputyrequest  */
  cancelledRequester = 4,
  /**  The planner cancelled the deputyrequest after the person was assigned, possibly because the deputy did not show up  */
  failedDeputy = 5,
}
export enum DeputyRequestStatusEnum {
  /**  The deputy request has been created by the requester, waiting for the provider, no deputies have been inquired  */
  unassigned = 1,
  /**  All inquired deputies cancelled their deputy_reg's or the are no inquired deputies, but some deputies were previously inquired   */
  reunassigned = 4,
  /**  Some deputies inquired  */
  inprogress = 3,
  /**  Has been assigned. The deputy has a copy of the shift. The deputy, provider, and requester can still cancel the request  */
  assigned = 2,
  /**  The provider does not want to handle the request, it is turnedDown  */
  turnedDown = 5,
  /**  The requester has requested to cancel the request  */
  deleteRequest = 6,
}
export enum DeputyRequestTypeEnum {
  /**  */
  substitute = 0,
  /**  */
  deputy = 1,
}
export enum DeputyStatusEnum {
  /**  Undefined      Undefined  */
  undefined = -1,
  /**  Inquired       Unassigned, Inprogress, Reunassigned  */
  inprogress = 1,
  /**  Accepted       Assigned  */
  assigned = 2,
  /**  Cancelled      TurnedDown, DeleteRequest  */
  cancelled = 3,
}
export enum DimensionEnumEnum {
  /**  the old payer dimension  */
  payer = 1,
  /**  the old account dimension  */
  account = 2,
  /**  the old project dimension  */
  project = 3,
  /**  the first new dimension  */
  tax = 4,
}
export enum DimensionUsageEnum {
  /**  */
  never = 0,
  /**  */
  always = 1,
  /**  */
  possible = 2,
}
export enum DisplayCounterInShiftEnum {
  /**  shiftcounter_def is hidden in the UI  */
  hideCounter = 0,
  /**  shiftcounter_def is show in the UI  */
  showCounter = 1,
  /**  shiftcounter_def is shown in the StaffPlan UI, but hidden in MyPlan UI (reg4total)  */
  hideCounterForEmpuser = 2,
}
export enum DispositionStatusEnum {
  /**  */
  ok = 1,
  /**  */
  warning = 2,
  /**  */
  error = 3,
}
export enum DutyAdjustmentTypeEnum {
  /**  Erase adjustment  */
  erase = 1,
  /**  Add adjustment  */
  add = 2,
  /**  Availability Erase adjustment  */
  avail_erase = 3,
  /**  Availability Add adjustment  */
  avail_add = 4,
  /**  Shiftcounter Values adjustment due to recalculation  */
  shiftValuesAdjust = 5,
  /**  Availability Shiftcounter Values adjustment due to recalculation  */
  avail_shiftValuesAdjust = 6,
  /**  Allowance Erase adjustment  */
  allowance_erase = 7,
  /**  Allowance Add adjustment  */
  allowance_add = 8,
  /**  Allowance Shiftcounter Values adjustment due to recalculation  */
  allowance_shiftValuesAdjust = 9,
}
export enum DutyTypeEnum {
  /**  dutyhp: START END SALARYSORT _ [PAYER] (prefixNone, prefixFullDayShift, prefixAvailableShift, prefixAvail). Normally uses prefixNone. Uses prefixAvail if calculationType = avail (availability_reg). Uses prefixAvailableShift if it's a call in that is shorter than 24 hours. Uses prefixFullDayShift if it's a call in that is 24 hours or longerand Start <> End. It's a call in if its preceded by an overlapping dutyline with lartgrp = datamodelTypes::avail.  */
  timedDuty = 1,
  /**  dutysp: NUMBER _ SALARYSORT _ [PAYER] (prefixNumber / durationDuty), or _ NUMBER SALARYSORT _ [PAYER] (prefixUnsigned / integerDuty), or _ _ SALARYSORT _ [PAYER] _ _ NUMBER (prefixUnsigned / integerDuty)  */
  allowanceDuty = 2,
  /**  dutyc: CC _ SALARYSORT (prefixNone)  */
  clockcodeDuty = 3,
  /**  dutyep: _ _ SALARYSORT _ [PAYER] (prefixWithoutTime)  */
  emptyDuty = 4,
  /**  dutysch: _ _ SALARYSORT [DAY] (prefixWithoutTime). DAY is stored in salarycode. SALARYSORT has lartgrp = datamodelTypes::shiftchange (24)  */
  changeDuty = 5,
}
export enum EmpPlanEnum {
  /**  */
  undefined = -1,
  /**  */
  plan = 1,
  /**  Registrations that can be recreated by the user  */
  original = 2,
  /**  Registrations that cannot be merged automatically by the appserver  */
  unmerged = 3,
  /**  Registrations that were rejected by the planner  */
  rejected = 4,
  /**  Registrations (requests) that are in planperiod - prevent a change message in Frontend  */
  request = 5,
}
export enum EmpPreferenceStatusEnum {
  /**  Employee has not explicitly defined any preferences for this day. Preference Pattern should be shown/used  */
  noRegistrations = 0,
  /**  Employee has actively expressed preferences for this day...which may be nothing (=no registrations=empty day)  */
  registered = 1,
}
export enum EmpWorkdayStatusEnum {
  /**  Employee has nothing to say about this day. Masterplan should always be used. This status is equivalent to NO empWorkdayStatusReg record  */
  noRegistrations = 0,
  /**  Employee has actively expressed something about this day...which may be nothing (=no registrations=empty day)  */
  registered = 1,
  /**  Employee has either been granted or rejected day, but wants to re-negotiate day. If planner accepts this invitation then workday status returns to preApproved and employee can request/register again  */
  reNegotiate = 2,
}
export enum EraseJobPhaseEnum {
  /**  start/default  */
  ejpNoPhase = 0,
  /**  erase dates are computed  */
  ejpLockEraseDates = 1,
  /**  check that all pdcdeleted is 0 and 1  */
  ejpCheckPDCDeleted = 5,
  /**  check intersection cards in person2 and person3  */
  ejpCheckPerson23 = 8,
  /**  rosters - done first  */
  ejpPDCDeleted = 10,
  /**  delete records with no person1 card  */
  ejpNonPerson1 = 15,
  /**  delete old timing entries  */
  ejpTiming = 20,
  /**  rosters - done first  */
  ejpRosterDef = 30,
  /**  filter by dateTime field - no eraseModuleKeepOne, no cascading data  */
  ejpDateTime = 50,
  /**  delete records with no person1 card after deleting person1  */
  ejpNonPerson1Again = 55,
  /**  filter by dateTime field - eraseModuleKeepOne  */
  ejpDateTimeKeepOne = 60,
  /**  filter by dateTime field - cascading data  */
  ejpCascading = 70,
  /**  delete old unused orgunits  */
  ejpComplex = 80,
  /**  The referenced uses does not exists  */
  ejpNoUserReference = 90,
  /**  filter by dateTime field - delete data with no references left  */
  ejpNoReferenceExDate = 100,
  /**  filter by boolean field - delete data with no references left  */
  ejpNoReferenceBoolean = 110,
  /**  filter by enum field - delete data with no references left  */
  ejpNoReferenceEnum = 115,
  /**  delete data with no references left  */
  ejpNoReference = 120,
  /**  delete old log entries  */
  ejpLog = 130,
  /**  last phase - nothing is done  */
  ejpDone = 140,
  /**  the restore job has been executed.   */
  ejpAbort = 150,
}
export enum ErrorCodeEnum {
  /**  The record cannot be locked  */
  unableToLock = 1000,
  /**  The person can not be identified by the information provided  */
  personNotResolved = 20000,
  /**  ChangedAfter time is old than 24 hours  */
  oldChangedAfterTime = 20001,
  /**  It is necessary to authenticate again  */
  reauthenticationNeeded = 30000,
}
export enum ErrorModuleEnum {
  /**  */
  core = 1,
  /**  */
  registration = 2,
  /**  */
  production = 3,
  /**  */
  consolidation = 4,
  /**  */
  salary = 5,
  /**  */
  login = 6,
  /**  */
  myPlan = 7,
  /**  */
  teamPlan = 8,
  /**  */
  functionPlan = 9,
  /**  */
  webService = 10,
}
export enum EventTypeEnum {
  /**  on start time  */
  onStart = 1,
  /**  on end time  */
  onEnd = 2,
  /**  is a transition point between start and end time events  */
  onTransition = 3,
}
export enum ExchangeActionEnum {
  /**  */
  cancel = 0,
  /**  */
  reject = 1,
  /**  */
  accept = 2,
  /**  */
  take = 3,
  /**  */
  findSwaps = 6,
}
export enum ExchangeCancelledByEnum {
  /**  Exchange is not cancelled  */
  notCancelled = 0,
  /**  Cancelled by Swapper  */
  bySwapper = 1,
  /**  Cancelled by Requester  */
  byRequester = 2,
  /**  Cancelled by Planner  */
  byPlanner = 3,
  /**  This surgestion is cancelled due to other surgestion is accepted  */
  byOther = 4,
  /**  Cancelled due to no Candidates  */
  noCandidates = 5,
}
export enum ExchangeCandidateFilterEnum {
  /**  No overlapping shifts, no overlapping availaiblity_reg's with present = 0  */
  classic = 1,
  /**  No overlapping work shifts, no overlapping availaiblity_reg's with present = 0 and unavailbilitydegree = 2 (noCallin). This is fairly similar to the sortedCallinList behavior  */
  callinEsque = 2,
  /**  No overlapping no availaiblity_reg's with present = 0 and unavailbilitydegree = 2 (noCallin).  */
  noCallin = 3,
}
export enum ExchangeKindEnum {
  /**  Deputy exchanges */
  deputy = 4,
  /**  Swap exchanges */
  swap = 7,
  /**  Request exchanges ???  */
  request = 8,
}
export enum ExchangePartEnumEnum {
  /**  shiftPart  */
  shiftPart = 1,
  /**  requester  */
  activityPart = 2,
  /**  taskPart  */
  taskPart = 3,
}
export enum ExchangeRoleEnum {
  /**  swapper   */
  swapper = 1,
  /**  requester   */
  requester = 2,
  /**  planner   */
  planner = 3,
  /**  otherswapper   */
  other = 4,
}
export enum ExchangeStatusEnum {
  /**  This exchange can be accepted or cancelled  */
  inProgress = 1,
  /**  Accepted  */
  accepted = 2,
  /**  Cancelled  */
  cancelled = 3,
  /**  The appserver idlelistener will create candidates for this exchange request. Can be cancelled by the planner  */
  lookingForCandidates = 4,
  /**  This exchange request had status = LookingForCandidates, but the appserver found no candidates. Behaves like it is cancelled  */
  noCandidates = 5,
}
export enum ExchangeStatusUIEnum {
  /**  status = inProgress and responsible = swapper  */
  awaitsAnswers = 6,
  /**  status = inProgress and responsible = planner. Swappers can still apply  */
  awaitsPlanner = 1,
  /**  status = inProgress and responsible = requester (or responsible = other, but that should never happen)  */
  awaitsRequester = 7,
  /**  Accepted  */
  accepted = 2,
  /**  Cancelled  */
  cancelled = 3,
  /**  The appserver idlelistener will create candidates for this exchange request. Can be cancelled  */
  lookingForCandidates = 4,
  /**  This exchange request had status = LookingForCandidates, but the appserver found no candidates. Behaves like it is cancelled  */
  noCandidates = 5,
}
export enum ExchangeTypeEnum {
  /**  A swapper accepts a swapRequest: Accepting to get a shift in exchange for another shift. Paired with a swapRequest.  */
  swapOffer = 1,
  /**  A requester or planner wants to exchange a shift. Initiates the swap. Paired with a swapOffer.  */
  swapRequest = 2,
  /**  A requester or planner wants to give a shift away. Paired with a takeOffer.  */
  handoverRequest = 3,
  /**  A swapper accepts a handoverRequest, dutyRequest, or copyRequest: Accepting to get a shift.  */
  takeOffer = 4,
  /**  A planner wants to give away tasks. Resembles handoverRequest, but always initiated by a planner, and always regarding tasks. has personId = -1. Paired with a takeOffer.  */
  dutyRequest = 5,
  /**  A planner wants to copy a shift, for instance because the requester is ill. Resembles handoverRequest, but the original shift is copied rather than moved, and the request is always initiated by a planner. Paired with a takeOffer.  */
  copyRequest = 6,
  /**  A planner have desided that the illness-effected-shift need no futher handling. I.e. no one need to cover this shift.  */
  handledOmitted = 7,
  /**  A planner has handled the swap through the call-in functionality. This is very much like the copyRequest, where the shift is copied. This request is always paired with a callinOffer.  */
  callinInjunctionRequest = 8,
  /**  A planner has found someone to call-in as a match for the callinRequest.  */
  callinInjunctionOffer = 9,
}
export enum ExporterScopeEnum {
  /**  */
  system = 1,
  /**  */
  storedlist = 2,
  /**  */
  allRoles = 3,
  /**  */
  currentRole = 4,
  /**  */
  allUsers = 5,
  /**  */
  currentUser = 6,
}
export enum FactortypeEnum {
  /**  Counter factortype  */
  caf = 0,
  /**  List factortype  */
  clf = 1,
  /**  Avail Counter factortype  */
  ca = 2,
}
export enum FavoriteLevelEnum {
  /**  Equivalent to no row in userFavoritePersonGroup. Group may still be accessible via access rights.  */
  notFavorite = 0,
  /**  Data fetched and group on top of lists  */
  favorite = 1,
  /**  As favorite, but TeamPlan users will get notifications also  */
  favoriteWithNotification = 2,
}
export enum FavoriteStatusEnum {
  /**  Nothing registered. followLevel=notFollowing  */
  nothing = 1,
  /**  Team shift is a favorite. followLevel in [following,followingWithNotifications]  */
  favorite = 2,
}
export enum FctplanMsgScopeEnum {
  /**  Created by planner in FP via top level/general messages to the groups of the plan ;  Visible in FP on top level and in MP as Info (cannot be replied to).  */
  functionPlanTop = 1,
  /**  Created in FP via days in the calendar. ;  Visible on days in FP and as Day messages in MP (cannot be replied to).  */
  functionPlanDate = 2,
}
export enum FieldCompressionEnum {
  /**  */
  noCompression = 1,
  /**  */
  lowCompression = 2,
  /**  windows8+  */
  highCompression = 3,
  /**  Compress by third party zLib  */
  zlibCompression = 4,
}
export enum FieldTypeEnum {
  /**  */
  databaseField = 1,
  /**  */
  virtualField = 2,
}
export enum FoDayEnumEnum {
  /**  */
  save = 1,
  /**  */
  use = 2,
}
export enum FolderTypeEnum {
  /**  userWorkingFolder  */
  userWorkingFolder = 1,
  /**  global Folder  */
  globalFolder = 2,
  /**  browse Folder  */
  browseFolder = 3,
}
export enum FunctionPlanningTypeEnum {
  /**  An ordinary StaffPlan roster  */
  standardRoster = 0,
  /**  An ordinary Functionplan roster  */
  functionPlanRoster = 1,
  /**  A FunctionPlan places template  */
  functionPlanTemplate = 2,
}
export enum FunctionTypeEnum {
  /**  */
  activity = 1,
  /**  */
  availability = 2,
  /**  */
  shift = 3,
}
export enum GdprPersonTypeEnum {
  /** person1 user */
  employee = 1,
  /** staffuser user */
  planner = 2,
  /** auxPerson */
  auxPerson = 10,
}
export enum GlobalPersonIdEnum {
  /**  */
  nil = 0,
  /**  person1.Cpr  */
  cpr = 1,
  /**  person1.Global_PersonId  */
  global_PersonId = 2,
}
export enum HelpInDatabaseEnum {
  /**  help is only stored in files, like Da_Silkl.txt  */
  noHelpInDatabase = 0,
  /**  help is stored in files and in the tables customer_help and customer_command, with unitid=1  */
  globalHelpInDatabase = 1,
  /**  help is stored in files and in the tables customer_help and customer_command, with any unitid  */
  hierarchicalHelpInDatabase = 2,
}
export enum HolidaySaveTypeEnum {
  /**  CBJ ??????  */
  nil = 0,
  /**  Den nye ferielov 2020  */
  dk2020 = 1,
  /**  Den nye ferielov 2020 variant 2 dec. proportional oprunding i august */
  dk20202dec = 2,
  /**  Den nye ferielov 2020 variant 2 dec. kun oprunding i august hvis full ansættelse  */
  dk20202decfull = 3,
}
export enum IndexOrganizedTypeEnum {
  /**  ordinary or not Oracle  */
  ordinary = 1,
  /**  index-organized table  */
  iot = 2,
  /**  IOT_OVERFLOW  */
  iotOverflow = 3,
  /**  IOT_MAPPING  */
  iotMapping = 4,
}
export enum InsEmptyDaysEnum {
  /**  Do not do anything  */
  nothing = 0,
  /**  Insert a shift containing an empty duty, during the salary process  */
  mkEmptyDuty = 1,
  /**  Insert a shift containing a duty of norm length  */
  mkNormDuty = 2,
}
export enum ItemTypeEnum {
  /**  The person is supposed to be at work  */
  work = 1,
  /**  The person is absent  */
  absence = 2,
  /**  Item is not related to working hours (so don't display in work plan)  */
  other = 3,
  /**  Item is a role (activity, allowance, or transportation)  */
  role = 4,
  /**  Item is a preference, either positive (want to work) or negative (wants absence)  */
  preference = 5,
  /**  Item is an availability (employee may be set to work here)  */
  task = 6,
  /**  The person is on call  */
  onCall = 7,
  /**  For showing phoneGapCalendar events  */
  calendarEvent = 8,
}
export enum ItemWorkdayStatusEnum {
  /**  Means no emp-workday. Similar to no workdaystatus though emp may have registered and unregigistered only if workdaystatus is present.  */
  noRegistrations = -1,
  /**  Registrations not processed - if invalid and not checkinNoConsol, the baseline will likely become the plan  */
  unprocessed = 0,
  /**  Registrations approved by planner  */
  approved = 1,
  /**  Registrations approved by auto-consolidation (appserver)  */
  preapproved = 2,
  /**  Registrations rejected by planner  */
  rejected = 3,
  /**  Processing of workday failed. Cause of problem stated in remark field of workdayStatus_reg  */
  error = 4,
  /**  Planner has finally approved availability request. Not applicable to Checkin regs  */
  vacationApproved = 5,
  /**  Planner has previously approved availability request, but has now compromised day-off with planned work. Not applicable to Checkin regs   */
  vacationConflict = 6,
  /**  Planner has revoked a previously approved availability request, because work is required after all. Not applicable to Checkin regs  */
  vacationRevoked = 7,
  /**  No emp-workday, but workdaystatus and invalid. Means emp has registered, but cancelled afterwards.  */
  unprocessedUnregistered = 101,
  /**  Emp-workday, presence of invalidation and workday unknown, used in select of Iwebworkday  */
  empRegistered = 102,
  /**  Status must not be displayed  */
  hidden = 208,
  /**  Roster item status not set (status is unknown or irrelevant)  */
  unset = 209,
}
export enum KfCompositionOpEnum {
  /**  Average of sub slots is KF Value of output slot  */
  kfCompositionOp_average = 0,
  /**  Minimum of sub slots is KF Value of output slot  */
  kfCompositionOp_min = 1,
  /**  Maximum of sub slots is KF Value of output slot  */
  kfCompositionOp_max = 2,
}
export enum KfTypeEnum {
  /**  Type is unspecified and KF uncharacterized. No system semantics can be associated with KFs of this type  */
  kfType_unspecified = 0,
  /**  KF semantics resembles that of CAPACITY numbers, e.g available emp hours or total working trucks */
  kfType_capacity = 1,
  /**  KF semantics resembles that of DEMAND numbers, e.g demanded emp hours or total trucks demanded */
  kfType_demand = 2,
  /**  KF semantics resembles that of CAPACITY-DEMAND numbers, e.g balance of emp hours or trucks  */
  kfType_difference = 3,
}
export enum LangCustomerEnum {
  /**  Numbers may NOT be used for serializing.  */
  noCustomer = 0,
  /**  Numbers may NOT be used for serializing.  */
  airTransat = 16465,
  /**  Numbers may NOT be used for serializing.  */
  appTest = 21011,
  /**  Numbers may NOT be used for serializing.  */
  argo = 18649,
  /**  Numbers may NOT be used for serializing.  */
  coopDK = 4244,
  /**  Numbers may NOT be used for serializing.  */
  coopSE = 4703,
  /**  Numbers may NOT be used for serializing.  */
  danPilot = 9671,
  /**  Numbers may NOT be used for serializing.  */
  danskeDiakonhjem = 25748,
  /**  Numbers may NOT be used for serializing.  */
  dsb = 2451,
  /**  Numbers may NOT be used for serializing.  */
  euroMaint = 24302,
  /**  Numbers may NOT be used for serializing.  */
  falckES = 17799,
  /**  Numbers may NOT be used for serializing.  */
  falckSE = 18219,
  /**  Numbers may NOT be used for serializing.  */
  flugger = 10844,
  /**  Numbers may NOT be used for serializing.  */
  gjaldstovan = 11115,
  /**  Numbers may NOT be used for serializing.  */
  kriminalforsorgen = 18152,
  /**  Numbers may NOT be used for serializing.  */
  lindex = 32742,
  /**  Numbers may NOT be used for serializing.  */
  mariehjem = 14084,
  /**  Numbers may NOT be used for serializing.  */
  molslinjen = 26187,
  /**  Numbers may NOT be used for serializing.  */
  osloKommune = 13313,
  /**  Numbers may NOT be used for serializing.  */
  pdc = 13519,
  /**  Numbers may NOT be used for serializing.  */
  pht = 13660,
  /**  Numbers may NOT be used for serializing.  */
  postenNO = 19658,
  /**  Numbers may NOT be used for serializing.  */
  regionHovedstaden = 6407,
  /**  Numbers may NOT be used for serializing.  */
  regionMidt = 7200,
  /**  Numbers may NOT be used for serializing.  */
  silk = 22101,
  /**  Numbers may NOT be used for serializing.  */
  stElizabeth = 25439,
  /**  Numbers may NOT be used for serializing.  */
  strategicAviation = 28129,
  /**  Numbers may NOT be used for serializing.  */
  test = 15506,
  /**  Numbers may NOT be used for serializing.  */
  tv2 = 17904,
  /**  Numbers may NOT be used for serializing.  */
  tV2Kosmopol = 12588,
}
export enum LangLanguageEnum {
  /**  Numbers may NOT be used for serializing.  */
  ar = 17,
  /**  Numbers may NOT be used for serializing.  */
  da = 90,
  /**  Numbers may NOT be used for serializing.  */
  de = 94,
  /**  Numbers may NOT be used for serializing.  */
  en = 133,
  /**  Numbers may NOT be used for serializing.  */
  es = 138,
  /**  Numbers may NOT be used for serializing.  */
  fo = 164,
  /**  Numbers may NOT be used for serializing.  */
  fr = 167,
  /**  Numbers may NOT be used for serializing.  */
  no = 404,
  /**  Numbers may NOT be used for serializing.  */
  pl = 461,
  /**  Numbers may NOT be used for serializing.  */
  ru = 530,
  /**  Numbers may NOT be used for serializing.  */
  se = 544,
}
export enum LangSolutionEnum {
  /**  Numbers may NOT be used for serializing.  */
  noSolution = 0,
  /**  Numbers may NOT be used for serializing.  */
  airport = 26795,
  /**  Numbers may NOT be used for serializing.  */
  health = 30300,
  /**  Numbers may NOT be used for serializing.  */
  industry = 12766,
  /**  Numbers may NOT be used for serializing.  */
  media = 30660,
  /**  Numbers may NOT be used for serializing.  */
  rescue = 31123,
  /**  Numbers may NOT be used for serializing.  */
  retail = 25859,
  /**  Numbers may NOT be used for serializing.  */
  test = 15506,
}
export enum LegitimationEnum {
  /**  */
  undecided = 0,
  /**  */
  legitimate = 1,
  /**  not legitimate if in use (see shifttype)   */
  notLegitimate = 2,
}
export enum LicenseEnum {
  /**  */
  license_staff = 1,
  /**  */
  license_cost = 2,
  /**  */
  license_web = 3,
  /**  license_capacityHist     5 "@LicenseCapacityHist"           "h" "@LicenseCapacityHistWithNumber" nolonger used  */
  license_capacity = 4,
  /**  */
  license_substitute = 6,
  /**  */
  license_checkIn = 7,
  /**  */
  license_activity_shift_link = 8,
  /**  */
  license_batchserver = 9,
  /**  */
  license_external_password = 10,
  /**  */
  license_automatic_planning = 11,
  /**  */
  license_datawarehouse = 12,
  /**  */
  license_demand_calculation = 13,
  /**  */
  license_keyfigures = 14,
  /**  */
  license_all_staffTest7Only = 16,
}
export enum LimitTransmitIntvOfAvailabilityEnum {
  /**  Transmit the full interval  */
  sendFull = 0,
  /**  Truncate absences as if they end after end of transmit interval  */
  truncate = 1,
  /**  Inherit the limitation.  */
  inherit = 2,
  /**  Transmit avails as full, exept the open, which are truncated by end af transmit interval  */
  truncateWhenOpen = 3,
  /**  Absence ending after processed end are sent as open   */
  sendAsOpenOnLaterEnd = 4,
}
export enum LobCacheTypeEnum {
  /**  ordinary or not Oracle  */
  noLobCache = 1,
  /**  cache reads and writes  */
  lobCache = 2,
  /**  cache reads  */
  lobCacheRead = 3,
}
export enum LogChangeTypeEnum {
  /**  nil  */
  nil = 0,
  /**  insert  */
  insert = 1,
  /**  delete  */
  delete = 2,
  /**   update */
  update = 3,
}
export enum LoggingModuleEnum {
  /** cost */
  logCost = 1,
  /** cost person detail */
  logCostPerson = 2,
}
export enum LogMessageTypeEnum {
  /**  */
  undefined = 0,
  /**  */
  success = 1,
  /**  */
  informational = 2,
  /**  */
  warning = 3,
  /**  */
  user_error = 4,
  /**  */
  error = 5,
  /**  */
  fatal_error = 6,
}
export enum LogMiscellaneousTypeEnum {
  /**  */
  syncronization = 1,
  /**  */
  odbcConnection = 2,
}
export enum LogonTypeEnum {
  /**  User/password from StaffPlan database, input via form  */
  staffWebUser = 0,
  /**  Username is Windows identity, check if it is in StaffPlan database with empty password  */
  windows = 1,
  /**  Login with OpenId Connect  */
  openId = 8,
  /**  AD username and password  */
  basic = 9,
  /**  Not an empuser  */
  undefined = 10,
}
export enum LogRegistration_TypeEnum {
  /**  shift  */
  shift = 0,
  /**  availability  */
  availability = 1,
}
export enum LogRegistrationChangeTypeEnum {
  /**  nil  */
  nil = 0,
  /**  insert  */
  insert = 1,
  /**  delete  */
  delete = 2,
  /**  update  */
  update = 3,
  /**  synchronize  */
  synchronize = 4,
  /**  used during synchronize insert for performance purposes - and immediately changed to syncronize after commit  by direct sql  */
  temporarySynchronize = 99,
}
export enum LogTypeEnum {
  /**  */
  logTypeNil = 0,
  /**  */
  logTypeNone = 1,
  /**  */
  logTypeDiff = 2,
  /**  */
  logTypeAll = 3,
}
export enum MandatoryUIEnum {
  /**  */
  mandatory = 1,
  /**  */
  free = 2,
  /**  only used in system tables - null interferred with builtin NULL */
  dbNull = 3,
  /**  caseinsensitive - field value is unique in the entire system  */
  systemUnique = 4,
  /**  caseinsensitive - field value is unique at the orgunit - table must have orgunit field  */
  orgunitUnique = 5,
  /**  caseinsensitive - field value is unique at the orgunit and it's decenders and predecessors - table must have orgunit field  */
  orgunitTreeUnique = 6,
  /**  caseinsensitive - must be unique within orgunit modulo validity, ie same name can be reused as long as validity intervals doesn't intersect - table must have orgunit field  */
  orgunitValidityUnique = 7,
  /**  caseinsensitive - field value is unique at the orgunit and it's decenders and predecessors -  modulo validity, ie same name can be reused as long as validity intervals doesn't intersect - table must have orgunit field  */
  orgunitTreeValidityUnique = 8,
  /**  caseinsensitive - field value is unique at the father node - table must have field with father property  */
  fatherUnique = 9,
  /**  caseinsensitive - field value is unique at the father node and it's decenders and predecessors  - table must have field with father property  */
  fatherTreeUnique = 10,
  /**  caseinsensitive - must be unique within father node modulo validity, ie same name can be reused as long as validity intervals doesn't intersect  - table must have field with father property  */
  fatherValidityUnique = 11,
  /**  caseinsensitive - field value is unique at the father node and it's decenders and predecessors -  modulo validity, ie same name can be reused as long as validity intervals doesn't intersect  - table must have field with father property  */
  fatherTreeValidityUnique = 12,
  /**  caseinsensitive - field value is unique in the entire system if it not empty  */
  systemUniqueOrNull = 13,
  /**  custom rule for field/object  */
  custom = 20,
  /**  date or minut offset field cannot be blank  */
  nonBlankDate = 30,
}
export enum MatchLevelEnum {
  /**  The parties prefer never to be matched  */
  poorest = 1,
  /**  The parties prefer seldom to be matched  */
  poor = 2,
  /**  It is ok to match the parties  */
  neutral = 3,
  /**  The parties like to be matched  */
  good = 4,
  /**  The parties like very much to be matched  */
  best = 5,
}
export enum MsgDisplayStatusEnum {
  /**  No information about display status  */
  noStatus = 0,
  /**  Message has been seen/read by the user in-app, hence no notification will be sent  */
  seen = 1,
  /**  The user has explicitly acknowledged the message, in-app or via channel.  */
  acknowledged = 2,
}
export enum MsgHandlingEnum {
  /**  Initial value, and no further handling required  */
  noHandling = 0,
  /**  May be set by the user, and displayed to the user similar to unread  */
  followup = 1,
}
export enum MsgKindEnum {
  /** Messages from one user to other users or groups. Users may be planners or employees */
  chat = 0,
  /** Messages about the workday for an employee. Reference personid */
  day = 1,
  /** Notes to a function plan, with period attachment. Reference fctPlanLayout */
  fctPlan = 2,
  /** Notes to a period (default one day) in a function plan. Reference fctPlanLayout */
  fctDay = 3,
  /** Messages about exchange offers. Reference exchangeReg */
  exchange = 4,
  /** Temp requests and inquiries. Reference deputy */
  deputy = 5,
  /** Messages about the workplan, for an employee */
  plan = 6,
  /** Message to user self about an object (absence) in the plan */
  reminder = 7,
}
export enum MsgNotificationStatusEnum {
  /**  Configured channels without a msgNotification exist  */
  unsent = 0,
  /**  All configured channels have been tried  */
  sent = 1,
  /**  Sending ended with error  */
  sentError = 2,
  /**  The message ends up not being sent. Still  */
  notSending = 3,
}
export enum MsgNotSendingCauseEnum {
  /**  No status, notificationStatus is not notSending. Default value. Set by creation code.  */
  noStatus = 0,
  /**  Seen in app before notification got sent  */
  seenInApp = 1,
  /**  No device registered for recipient. Set by MessageSender.  */
  noPushRegistration = 2,
  /**  Unsent message is no longer visible. Set by MessageSender.   */
  visibleEnded = 3,
  /**  User is removed for whatever reason (missing, deleted, inactivated, erased, ...). Set by MessageSender.  */
  userRemoved = 4,
  /**  msg or msgParticipant were obsoleted.  */
  obsoleted = 5,
  /**  when now > attachedToPlanStart, do not send, except for kind=day.  */
  afterAttachement = 6,
  /**  msg does not exist or is deleted - data error that should not happen.  */
  msgNotFound = 7,
  /**  MessageSender is not configured (in applicationSetting MsgNotificationKinds) to send messages of this kind.  */
  notConfigured = 8,
}
export enum MsgParticipantCauseEnum {
  /**  The message is from this user  */
  creator = 0,
  /**  The message is to this user  */
  recipient = 1,
  /**  The participant is added because of group  */
  group = 2,
}
export enum MsgParticipantTypeEnum {
  /** person1 user */
  employee = 1,
  /** staffuser user */
  planner = 2,
  /** External user via integration httpserver */
  plannerGroup = 30,
}
export enum MsgPriorityEnum {
  /**  Send now. Block user GUI until acknowledged. At logon, when authenticated (note diff to setting BlockAccess which is meant to be shown before logon )  */
  asap = 0,
  /**  Send out normally, usually now, but non-blocking  */
  standard = 1,
  /**  Notifications may be sent later and as summary messages  */
  low = 2,
}
export enum MsgSentStatusEnum {
  /**  Message not attempted sent  */
  noStatus = 0,
  /**  Notification sent ok  */
  ok = 1,
  /**  Channel responded with error  */
  channelError = 2,
  /**  Other errors, notification not sent  */
  otherError = 3,
}
export enum MsgSimpleStatusEnum {
  /**  Not all msgSimpleUser have been handled  */
  unhandled = 0,
  /**  All msgSimpleUser have been handled  */
  handled = 1,
}
export enum MsgSimpleUserStatusEnum {
  /**  Message not handled for this user  */
  unhandled = 0,
  /**  Message sent to this user  */
  sent = 1,
  /**  Message not sent because user has no mobile registered  */
  cancelledNoPhone = 100,
  /**  Message for uset who is not found  */
  cancelledNoSuchUser = 101,
  /**  Message for user with no valid planning card  */
  cancelledNoValidP2 = 102,
  /**  Message for user on unit not SMS enabled  */
  cancelledNotSmsEnabled = 103,
  /**  Message for user with no valid unit  */
  cancelledNoValidUnit = 104,
  /**  Message sending ended in unexpected error (in parenthesis put error.message)  */
  cancelledSystemError = 105,
}
export enum MsgVisibilityEnum {
  /**  Those in msgParticipant can view the message  */
  participant = 0,
  /**  Everyone with access to reference can view the message. MsgParticipant created by need (includes notification recipients).  */
  public = 1,
}
export enum MsgVisibilityOldEnum {
  /**  Planners and the employee can view the message  */
  employee = 0,
  /**  Only planners can view the message  */
  planner = 1,
  /**  Everyone can view the message  */
  public = 2,
}
export enum NotificationStatusEnum {
  /**  Show no notifications. followLevel in [notFollowing,following]  */
  noNotifications = 1,
  /**  Show all notifications. followLevel=followingWithNotifications  */
  allNotifications = 2,
}
export enum OpenStateEnum {
  /**  start and end is known  */
  closed = 0,
  /**  Start missing  */
  openStart = 1,
  /**  End missing  */
  openEnd = 2,
}
export enum OptBooleanEnumEnum {
  /**   false  */
  bFalse = 0,
  /**   true  */
  bTrue = 1,
  /**   none  */
  bNone = 2,
}
export enum OracleFetchStrategyEnum {
  /**  fetch then first 4000 characters, and the length of every lob field - patch the record if the field is to long  */
  simple = 0,
  /**  fetch then first 4000-32000 characters, and the length of every lob field - patch the record if the field is to long  */
  advanced = 1,
  /**  handle lob as long varchar - rerun the sql if the buffersize is to small - has bad oracle performance - is the MsSqlServer way  */
  oldSchool = 2,
}
export enum OrgunitPropertyEnum {
  /** Unit is a planning unit. */
  section = 1,
  /** Unit is a payer. */
  payer = 2,
  /** Unit is a firm/institution */
  institution = 5,
  /** Are the employees allowed to make requests */
  usesEmployeeRequesting = 7,
  /**  */
  isDeputyService = 8,
  /**  */
  usesSimpleMessages = 9,
  /** Push notifications allowed, controlled via web setting NotificationDimensions. */
  usesSMS = 10,
  /**  */
  usesProductionRules = 11,
  /**  */
  productionDepartment = 13,
  /**  */
  productionUnit = 14,
  /** FunctionPlan enabled */
  functionPlanning = 15,
  /** Unit has activated automatic planning */
  automaticPlanning = 17,
  /** Determines if task status feature is enabled for sub-hierarchy. Setting 'taskStatus' must be enabled for entire installation for this property to have effect.; StaffWeb/Mobile: TaskStatus=enabled means that only released tasks are visible. When TaskStatus=disabled then all tasks are immediately visible and enabled in web. */
  productionTaskStatus = 18,
  /** Determines if long term planning is used for sub-hierarchy, ie long term planned tasks are converted to reservations; when planning period transitions to operation/booking. Setting 'taskLongTermFilter'  must be enabled for entire installation for this property to have effect.; TaskShiftSync: When orgunits are using long term planning then shifts can only be extended in operational period, since otherwise shifts would immediately vanish when reservations are constructed from long term planned tasks. Note: this behaviour is superseeded by explicit TaskShiftSync orgunit properties if configured in installation */
  productionLongTermPlanning = 19,
  /** the department og subdepartments uses person workflow (MiF) */
  usesPersonWorkflow = 20,
  /** Unit and all subunits allow synchronization of Shifts from Tasks during PlanStatus Planning period. All operations are legal: creation, deletion, extension and shrinkage */
  taskShiftSyncPlanning = 21,
  /** Unit and all subunits allow synchronization of Shifts from Tasks during PlanStatus Operation period. All operations are legal: creation, deletion, extension and shrinkage */
  taskShiftSyncOperation = 22,
  /** Unit and all subunits only allow extension of shifts and new Shifts during PlanStatus Operation period. This property requires productionTaskShiftSyncPlanStatusOperation is defined. Legal operations: creation and extension */
  taskShiftSyncOp_ExtendOnly = 23,
  /** Are the employees allowed to make time reporting */
  usesTimeReporting = 24,
  /** Are the employees allowed to make time-attendance clocking through the MyPlan-app */
  usesTimeAttendance = 25,
  /** Are the employees allowed to do selfplanning through the MyPlan-app */
  usesEmployeeSelfplanning = 26,
  /** Does the unit use TeamPlan */
  usesTeamPlan = 27,
  /** Only allow swaps if the shifts contain the same amount of norm hours */
  swapNormNeutral = 28,
  /** Only allow exchange_reg candidates which cause no new ruleviolation (for all exchangeTypes). Fallback (when false) to setting.swapRulecheck and setting.handoverRulecheck. Even when the rulecheck is disabled, the planner is still presented with the ruleviolations when accepting an offer. */
  exchangeRuleCheck = 29,
  /** Are the employees allowed to use time registration */
  usesTimeRegistration = 30,
  /** Cooperation point for the units below - attachment to any unit below gives cooperation with all other units below */
  cooperation = 33,
}
export enum OrgunitPropertyPropertyEnum {
  /**  no change for the orgunit field  */
  noRule = 0,
  /**  only select payers for this field  */
  payerUnitOnly = 1,
  /**   only select sections for this field   */
  planningUnitOnly = 2,
  /**  only select production department for this field   */
  productionDepartmentOnly = 3,
  /**  only select production unit for this field   */
  productionUnitOnly = 4,
}
export enum OrgunitPropertyTypeEnum {
  /**   Days where the store open or closed. Id is date (offset) and 0 means closed, 1 means open  */
  closedDays = 1,
  /**  Id=1: PropertyLong: 0: Store is closed on holydays; 1: Store is open all days  */
  storeProperty = 2,
  /**  id=1: PropertyCode - DeputyHours list(MinuteOffsetAfterMidnight), id=2: PropertyLong - AcuteLimit  */
  deputyService = 3,
}
export enum OriginEnum {
  /**  Manually and explicitly constructed by user */
  user = 0,
  /**  Rolled out from Roster Template  */
  roster = 1,
  /**  Instantiated from Definition/Template  */
  definition = 2,
  /**  Automatic computation caused entity  */
  computed = 3,
  /**  Entity is derived from other records  */
  derived = 4,
  /**  Decision engine inferred entity  */
  decisionRule = 5,
  /**  External system owns entity */
  external = 6,
  /**  Consolidation caused entity */
  consolidation = 7,
  /**  Employee caused entity */
  employee = 8,
}
export enum OriginReasonEnum {
  /**  the registration doesnt originates from any specific registration  */
  noOrigin = 1,
  /**  the registration originates from a specific registration   */
  otherRegistration = 2,
}
export enum PasswordChangeFrequenceEnum {
  /**  newer  */
  never = 0,
  /**  Month  */
  month = 30,
  /**  SecondMonth  */
  secondMonth = 60,
  /**  ThirdMonth  */
  thirdMonth = 90,
  /**  halfYear  */
  halfYear = 180,
  /**  year  */
  year = 360,
  /**  2 years  */
  twoYears = 730,
}
export enum PasswordChangeReqEnum {
  /**  Password OK  */
  noChangeRequired = 0,
  /**  passwordExpired  */
  passwordExpired = 1,
  /**  temporaryPasswordUsed  */
  temporaryPasswordUsed = 2,
}
export enum PayPeriodTypeEnum {
  /**  */
  all = -1,
  /**  */
  monthly = 1,
  /**  */
  hourly = 0,
}
export enum PeriodCostPrincipleEnum {
  /**  hour and monthly paided for all working hours  */
  timeBased = 0,
  /**  fixed salary, some supplement may accour. The fixed salary is based on norm - the cost depends on the normhpurs in the period  */
  fixedSalaryNorm = 1,
  /**  fixed salary, some supplement may accour. The fixed salary on based on days/weeks - the cost is the same every calendar day  */
  fixedSalaryDay = 2,
  /**  fixed salary, some supplement may accour. The fixed salary on based on month - the cost is the same every calendar month, so the day cost depends on the month length   */
  fixedSalaryMonth = 3,
}
export enum PeriodCostTypeEnum {
  /**  */
  periodMonth = 1,
}
export enum PersonCheckInStatusEnum {
  /**  Person has not checked in */
  notPresent = 0,
  /**  Person has checked in  */
  present = 1,
  /**  CheckIn state does not make sense, typically because item is historic or planned in future  */
  undefined = 2,
}
export enum PersonDataEnum {
  /**  use person1.name  */
  name = 0,
  /**  use person1.address and personDataPrivacy  */
  address = 1,
  /**  use person1.address2 and personDataPrivacy - and missing in myPlan */
  address2 = 2,
  /**  use person1.zipcode and personDataPrivacy  */
  zipcode = 3,
  /**  use person1.city and personDataPrivacy  */
  city = 4,
  /**  use person1.phone and personDataPrivacy  */
  phone1 = 5,
  /**  use person1.altphone and personDataPrivacy  */
  phone2 = 6,
  /**  use person1.mobilphone and personDataPrivacy and editable */
  phone3 = 7,
  /**  use person1.email and personDataPrivacy and editable  */
  email = 8,
  /**  use person1.birthdate and personDataPrivacy - and missing in myPlan  */
  birthdate = 9,
  /**  use person1.salaryid  */
  salaryid = 20,
  /**  use person1.validitystart  */
  empstart = 21,
  /**  use person1.validityend  */
  empend = 22,
  /**  use person1.relativeList  */
  relatives = 23,
  /**  use person2.Title  */
  title = 100,
  /**  use orgunit intitution  */
  institution = 200,
  /**  use orgunit unit  */
  unit = 201,
}
export enum PersonFictiveEnum {
  /**  ordinary person1  */
  normal = 0,
  /**  person is fictive  */
  fictive = 1,
  /**  old record compute whether person is real  */
  compute = 2,
}
export enum PersonGenderTypeEnum {
  /**  Gender is not known - or both */
  notKnown = 0,
  /**  Female gender */
  female = 1,
  /**  Male gender */
  male = 2,
}
export enum PersongroupPropertyEnum {
  /**  may the members of this group swap shifts between each other  */
  shiftSwap = 2,
  /**  this persongroup uses function planning  */
  functionPlanning = 3,
}
export enum PersonRelationTypeEnum {
  /** Person is Child of related person */
  childOf = 0,
  /**  Person is Spouse of related person */
  spouseOf = 1,
  /**   Person is Parent of related person */
  parentOf = 2,
  /**   Person is Friend of related person */
  friendOf = 3,
}
export enum PersonTypeEnum {
  /**  ordinary person1 (default)  */
  normal = 0,
  /**  May be used in web. Depending on the item type, it is computed if the item has hours (valid HHmm.minuteoffset value 0-1439, or 9999 if empty) and if the user may edit them. not used by activity_period_def  */
  fictive = 1,
}
export enum PersonValidityPeriodTypeEnum {
  /**  Split only person2  */
  planningPeriod = 0,
  /**  Split only person3  */
  payerPeriod = 1,
  /**  Split person2 and person3 */
  bothPeriods = 2,
}
export enum PictureApprovalEnum {
  /**  noApproval  */
  noApproval = 0,
  /**  internalUse  */
  internalUse = 10,
  /**  publicUse  */
  publicUse = 20,
}
export enum PlanEnum {
  /**  used in program call only - not stored  */
  nil = -1,
  /**  The plan for real registrations used for producing salaryinfo  */
  master = 1,
  /**  The registration in this plan is the official planned data - used when alternating from the original plan is importent  */
  planned = 2,
  /**  employee request for registrations  */
  request = 3,
  /**  employee time checkins  */
  checkin = 4,
  /**  Copy of master, used to restore if consolidated request/checkin is rejected  */
  baseline = 5,
  /**  Planner registrations, will be consolidated but not baselined. They usually have a reduced level of sorts and rights.  */
  intermediate = 6,
  /**  not used in the tables, but for completeness  */
  roster = 100,
}
export enum PlanningGranularityEnum {
  /** fine */
  fine = 1,
  /** coarse */
  coarse = 2,
}
export enum PlanStatusEnum {
  /**  Unknown planstatus  */
  mp_unknown = 0,
  /**  Before any available Staff data  */
  closed = 1,
  /**  Data sent to salary systems  */
  realized = 2,
  /**  The planner has approved the plan for the salary system  */
  approved = 3,
  /**  The planner makes final adjustments for the salary system  */
  adjustment = 4,
  /**  Users have reported, planner may take over  */
  userApproved = 5,
  /**  Users may report in this period  */
  checkIn = 6,
  /**  The plan is known, daily operation takes place  */
  operation = 7,
  /**  In this period the user may normally not see data  */
  planning = 8,
  /**  Uses may request (wish) here  */
  request = 9,
  /**  After any available Staff data  */
  future = 10,
}
export enum PredefinedUserEnum {
  /**  System  */
  sys = -4,
  /**  pdc online user  */
  pdc = -5,
  /**  httpserver  */
  httpserver = -7,
  /**  systembridge  */
  systembridge = -8,
  /**  appserver  */
  appserver = -9,
  /**  batch  */
  batch = -10,
  /**  script  */
  script = -12,
  /**  before login  */
  beforelogin = -20,
  /**  User with a one day password  */
  temporary = -100,
  /**  these userids are reserved for pdc supporters  */
  pdcBase = -1000,
  /**  these userids are reserved for sd supporters  */
  sdBase = -2000,
}
export enum PreferenceDefTypeEnum {
  /**  The employee can only opt into this  */
  negativePreferenceOnly = 1,
  /**  The employee can only opt out of this  */
  positivePreferenceOnly = 2,
  /**  The employee can both opt into and out of this  */
  bothPreferencesPossible = 3,
}
export enum PreferenceTypeEnum {
  /**  The employee does not want this  */
  negativePreference = 1,
  /**  The employee wants this  */
  positivePreference = 2,
}
export enum PriceUnitEnum {
  /**  HourlyPrice  */
  hourlyPrice = 1,
  /**  PiecePrice  */
  piecePrice = 2,
}
export enum ProgramVersionCheckEnum {
  /**  */
  noCheck = 1,
  /**  */
  programVersionWarningOnOther = 2,
  /**  */
  programVersionDisableOldVersions = 3,
  /**  */
  programVersionDisableOtherVersions = 4,
}
export enum PublishableEnum {
  /**  */
  isPublishable = 0,
  /**  */
  isAnonymous = 1,
  /**  */
  isHidden = 2,
}
export enum PushProviderTypeEnum {
  /**  OBSOLETE  Apple Push Notification Services  */
  apns = 0,
  /**  Google Firebase Messaging  */
  fcm = 1,
}
export enum QualityFunctionEnum {
  /**  */
  releasePlan = 0,
  /**  */
  acceptPeriod = 1,
  /**  */
  adhoc = 2,
}
export enum ReadonlyUIEnum {
  /**  */
  readonly = 1,
  /**  */
  updateable = 2,
}
export enum RegistrationAllowedEnum {
  /**  The person may perform what ever is the deafult for the planning unit  */
  followsPlanningUnit = 0,
  /**  Can not make any registrations at all  */
  nothing = 10,
  /**  May use the punch-clock interface functionality - only if planning unit is using this facility though.  */
  timeAttendance = 20,
  /**  May use the punch-clock interface functionality, even on unplanned days - only if planning unit is using this facility though.  */
  unplannedTimeAttendance = 30,
  /**  May make time attendance registrations in the past check-in interval.  */
  timeRegistration = 40,
  /**  May make all types of registrations in the past check-in interval.  */
  timeReporting = 50,
  /**  May make registrations in the past check-in interval and future operations interval.  */
  selfPlanning = 60,
}
export enum RegistrationCategoryEnum {
  /**  used in program call only - not stored  */
  nil = 0,
  /**  data stored in roster "dimension/encoding"  */
  roster = 1,
  /**  . - reg_person_status.realizationstart  */
  closedHistoric = 5,
  /**  reg_person_status.realizationstart - reg_person_status.approvalstart  */
  realised = 2,
  /**  reg_person_status.approvalstart - reg_person_status.operationstart  */
  approved = 9,
  /**  reg_person_status.operationstart - reg_person_status.checkinstart  */
  actual = 3,
  /**  reg_person_status.checkinstart - person_status.planningstart  */
  checkin = 10,
  /**  reg_person_status.planningstart - futureStart  */
  planning = 4,
  /**  futureStart - .  */
  closedFuture = 13,
  /**  reg_person_status.productionPlanningStart - futureStart  */
  productionPlanning = 12,
  /**  reg_person_status.operationstart - reg_person_status.planningstart  */
  planned = 6,
  /**  reg_person_status.requeststart - futureStart  */
  request = 7,
  /**  reg_person_status.vacationrequeststart - reg_person_status.planningend  */
  vacationRequest = 14,
}
export enum RegistrationForTotalchangedByEnum {
  /**  Everything is approved (wrt checkin)  */
  employee = 1,
  /**  Something is not approved (wrt checkin)  */
  planner = 2,
}
export enum RegistrationForTotalchangeTypeEnum {
  /**  The registration in its current (master) state, the registration was not planned. The registration is new wrt planid = planned.  */
  insert = 1,
  /**  Deprecated. The registration in its current (master) state, it existed previously, but with different countervalues or period: The registration in the current (master) plan overlaps with an originally planned registration, but it has different countervalues or period.  */
  update = 2,
  /**  The registration in its planned state, it is no longer current (master). The registration is in planid = planned but not in planid = master.  */
  delete = 3,
  /**  The registration is planned and has not changed its countervalues or period since planning.  */
  noopr = 4,
}
export enum RegistrationWithCountersTypeEnum {
  /**  */
  shift = 1,
  /**  */
  availability = 2,
  /**  */
  allowance = 3,
}
export enum RegStatusEnum {
  /**  */
  regStatusNil = 0,
  /**  */
  shift_calc = 1,
  /**  */
  summary_sum = 2,
}
export enum ReportOwnerEnum {
  /**  */
  eraseReport = 1,
  /**  */
  validateReport = 2,
}
export enum RequestAllowedEnum {
  /**  The person may perform what ever is the deafult for the planning unit  */
  followsPlanningUnit = 0,
  /**  Can not make any requests at all  */
  nothing = 1,
  /**  May make requests  */
  requestAllowed = 2,
}
export enum ResourceKindEnum {
  /**  */
  person = 1,
  /**  */
  team = 2,
  /**  */
  equipment = 3,
}
export enum RuleArityEnum {
  /**  */
  definition_rule = 0,
  /**  */
  one_element_rule = 1,
  /**  */
  two_element_rule = 2,
  /**  */
  multiple_element_rule = 100,
}
export enum RuleCurrentPersonGroupsEnum {
  /**  The person's persongroups which are on non-obsolete orgunits in OO, or their ancestors or children. This setting is the default for ruleCurrentPersonGroups, which is used in most cases.  */
  compatible = 0,
  /**  Persongroups (regardless of person group memberships) matching ruleIncludeGroup which are on or below the orgunits O. The orgunits O are OO plus those with info2 <> "" and info2 in OO.info2. This setting is currently unused and it is not supported by the Prolog backend.  */
  workplace = 1,
  /**  Persongroups (regardless of person group memberships) matching ruleIncludeGroup at or below the orgunits orgunits O. The orgunits O are the "firstOrSelf" ancestor of each orgunit in OO with property applicationsetting.rootProperty. This setting is currently only used by PDC  */
  propertyRoot = 2,
  /**  Persongroups (regardless of person group memberships) matching ruleIncludeGroup at or below OO. This setting is the default for ruleBoardGroups, which is used for message groups.  */
  subtree = 3,
  /**  Persongroups (regardless of person group memberships) matching ruleIncludeGroup at or below the orgunits orgunits O. The orgunits O are OO plus each orgunits parent, unless the orgunit or its parent is an institution. This setting is currently unused.  */
  extendedTree = 4,
  /**  The person's persongroups. This setting is currently unused.  */
  all = 5,
  /**  Like "all" but only groups for which there are no other persongroups below the persongroup's orgunit. This setting is currently unused and it is not supported by the Prolog backend  */
  mostSpecific = 6,
}
export enum RuleidgroupEnum {
  /**  */
  error = 0,
  /** We previously had the following enum, which has been replaced by protectedDayOff  absence           2 "@Absence" */
  restingTime = 1,
  /**  */
  protectedDayOff = 3,
  /**  */
  freeWeekend = 4,
  /**  */
  other = 5,
  /**  */
  shiftRules = 6,
  /**  */
  activities = 7,
  /**  */
  fortyEightHours = 8,
}
export enum RuleIncludeGroupEnum {
  /**  Include only person groups that are also orgunits  */
  onlyOrgUnit = 0,
  /**  Include any kind of group  */
  any = 1,
}
export enum RuleIncludeGroupMembersEnum {
  /**     Include only members from the first group in recipient list. This represents legacy behavior and is consequently the default.  */
  first = 0,
  /**  Include memebers of all groups in recipient list.  */
  all = 1,
}
export enum RuleOriginalOrgunitEnum {
  /**  First valid p3 intersecting period  */
  compatible = 0,
  /**  All valid p2s intersecting current period  */
  p2 = 1,
  /**  All valid p3s intersecting current period  */
  p3 = 2,
}
export enum RuleTableIdEnum {
  /**  shift_reg table number  */
  shift_reg = 39,
  /**   availability_reg table number  */
  availability_reg = 25,
  /**   activity_reg table number  */
  activity_reg = 22,
  /**   prd_assignment_reg table number  */
  prd_assignment_reg = 162,
}
export enum SalaryApprovalStatusEnum {
  /**  */
  test = 0,
  /**  Approved and ready to be endorsed  */
  approved = 5,
  /**  */
  disapproved = 2,
  /**  Endorsed and ready to be processed  */
  endorsed = 1,
  /**  Salary data has been processed and file(s) are ready to be extracted  */
  processed = 6,
  /**  File(s) have been extracted  */
  extracted = 7,
  /**  File(s) have been acknowledged by the recieving system. In most implementations acknowledge is auto set when extracted  */
  acknowledged = 3,
  /**  File(s) have not been acknowledged  */
  unacknowledged = 8,
  /**  This approval has been merged into another approval, which then is overlapping.  */
  merged = 4,
}
export enum SalaryfileTypeEnum {
  /**  */
  salary = 1,
  /**  */
  adjustment = 4,
  /**  */
  absence = 2,
  /**  */
  tias = 3,
  /**  */
  bookkeeping = 5,
  /**  */
  kmdVacation = 6,
}
export enum SalarysortGroupEnum {
  /**  */
  nil = 0,
  /**  rådighed/døgnvagt  */
  avail = 1,
  /**  opkald/udkald i forb. m. 1  */
  call = 2,
  /**  arbejde  */
  work = 3,
  /**  omlagt arbejde   */
  change = 4,
  /**  afspadsering   */
  timeoff = 5,
  /**  ferie  */
  holiday = 6,
  /**  andet fravær  */
  absence = 7,
  /**  */
  signal = 8,
  /**  ikke-tidsafhængige tillæg  */
  special = 9,
  /**  flyttet arbejde - brug ikke mere  */
  change2 = 14,
  /**  omlagt arbejde annulleret del  */
  change_off = 17,
  /**  vagtændring  */
  shiftchange = 24,
  /**  vagtændring annulleret del  */
  shiftchange_off = 27,
  /**  vagtbytte  */
  shiftswap = 34,
  /**   vagtbytte annulleret del  */
  shiftswap_off = 37,
  /**  bytbart fravær, f.eks. pauser  */
  pause = 73,
  /**  bytbart fravær/tillæg med tider som ikke korrigerer ovenstående tj.linies tider  */
  pauseOverlapped = 74,
  /**   afspadsering på døgn/rådighedsvagt  */
  avail_timeoff = 81,
  /**   fravær på døgn/rådighedsvagt  */
  avail_absence = 82,
  /**  Diæt lønart - til brug i rejseafregning dialog, ikke i vagt  */
  paidAllowance = 101,
}
export enum SalarysortPrefixEnum {
  /**  timed duty on shift_reg not affected by salarysortGroup.avail or clockcode / timedDuty / dutyhp  */
  prefixNone = 0,
  /**  timed duty on shift_reg of at least 24 hours affected by salarysortGroup.avail / timedDuty / dutyhp  */
  prefixFullDayShift = 1,
  /**  timed duty on shift_reg of less than 24 hours affected by salarysortGroup.avail / timedDuty / dutyhp  */
  prefixAvailableShift = 2,
  /**  empty duty: salarysort and optionally payer : if lartgrp <> shiftchange then emptyDuty / dutyep (written as _ _ SALARYSORT _ [PAYER]): if lartgrp = shiftchange then changeDuty / dutchsc (written as _ _ SALARYSORT [DAY])  */
  prefixWithoutTime = 3,
  /**  timed duty on availability_reg / timedDuty / dutyhp  */
  prefixAvail = 4,
  /**  allowance where the number is specified in the start field / allowanceDuty / dutysp (written as NUMBER _ SALARYSORT _ [PAYER])  */
  prefixNumber = 5,
  /**  allowance where the number is specified in the end field or the number field / allowanceDuty / dutysp (written as _ _ SALARYSORT NUMBER [PAYER] or _ NUMBER SALARYSORT _ [PAYER])  */
  prefixUnsigned = 6,
}
export enum SalarySystemEnum {
  /**  */
  salarySystem_kmdLon_2 = 3,
  /**  SamTeld old salarysystem (Sam~teld HP3000) - no longer in use  */
  salarySystem_samteldLon = 5,
  /**  */
  salarySystem_silkLon2 = 9,
  /**  */
  salarySystem_samteldXMLLon = 10,
  /**  For COOP SE  */
  salarySystem_HR_PLUS8 = 11,
  /**  For Lindex, the system is also known as Personec  */
  salarySystem_TietoEnator = 13,
  /**  Used by Lindex FI  */
  salarySystem_LindexFI = 15,
  /**  */
  salarySystem_PDC = 16,
  /**  Used by Lindex SE  */
  salarySystem_SvenskLön = 18,
  /**  No longer in use - was used by DSB  */
  salarySystem_PDCXML = 19,
  /**  Bluegarden Norway Posten  */
  salarySystem_Pagalon_2 = 21,
  /**  */
  salarySystem_PDC_Absencehours = 22,
  /**  */
  salarySystem_agDA = 23,
  /**  No longer in use  */
  salarySystem_Boryspil = 24,
  /**  */
  salarySystem_Wisma = 25,
  /**  Variant of agDA used by Flügger  */
  salarySystem_agDA_2 = 26,
  /**  TV2 - Dynamics AX (Axapta)  */
  salarySystem_TV2Dynamics = 27,
  /**  */
  salarySystem_KMDperspektiv = 28,
  /**  DSB : SAP  */
  salarySystem_DSB = 29,
  /**  MolsLinien  */
  salarySystem_ASPECT4 = 30,
  /**  PeopleSoft/St. Elizabeth (Canada)  */
  salarySystem_PeopleSoft = 31,
  /**  Lessor 5 (PDC variant) (Mariehjem)  */
  salarySystem_Lessor5 = 33,
  /**  KMD OPUS - Used by Argo & DDH. Udfases d. 1/10-2024  */
  salarySystem_kmdOpus = 34,
  /**  Variant of agDA used by Falck SE, but will be phased out, and replaced by HR+  */
  salarySystem_agDA_3 = 35,
  /**  SLS (Statens Løn System), used by Kriminalforsorgen  */
  salarySystem_SLS = 36,
  /**  KMD Payroll Cloud (SAP), used by Flügger DK and SE  */
  salarySystem_KmdPayrollCloud = 37,
  /**  TietoEVRY variant used by Posten No  */
  salarySystem_TietoEVRY_Posten = 38,
  /**  For Falck SE  */
  salarySystem_HR_PLUS8_ext = 39,
  /**  KMD OPUS Web - Used by Argo & DDH  */
  salarySystem_kmdOpusWeb = 40,
}
export enum ScheduleJobStatusTypeEnum {
  /**  Job executed succesfully (default)  */
  success = 0,
  /**  Job failed  */
  failed = 1,
  /**  Job status is undefined, ie not executed  */
  undefined = 3,
  /**  Job is queued for execution  */
  queued = 4,
  /**  Job is currently running  */
  running = 5,
}
export enum SeqTypeEnum {
  /**  When orders aren't event-based the sequence type is NONE */
  seqType_none = 0,
  /**  Start event is left end of seq part */
  seqType_start = 1,
  /**  End event is right end of seq part */
  seqType_end = 2,
  /**  Both start and end event are boundries of seq part */
  seqType_return = 3,
  /**  Seq part is a movement from A to B  */
  seqType_transition = 4,
  /**  An in-between seq part, neither start nor end nor movement */
  seqType_intermediate = 5,
}
export enum SettingTypeEnum {
  /**  Must be set explicitly in database  */
  mandatory = 1,
  /**  Mandatory values with a default in the program  */
  default = 2,
  /**  Optional values  */
  optional = 3,
  /**  Recursive settings used via predicate setting_prefix_nd  */
  recursive = 4,
}
export enum SharedCalendarUpdateStatusEnum {
  /** Calendar file does not exist and we could not create it. */
  calendarCreationFailed = -10000,
  /**  The calendar has failed enough times to require notification, it has been sent and should not be re-sent   */
  calendarUpdateFailedNotified = -2,
  /** Calendar file exists but we could not update it ,  Should be included in some cleanup/reattempt/maintenance job. implement more fine-toothed error enums in space -1 to -99  */
  calendarUpdateFailed = -1,
  /** Nothing went wrong, calendar was created or updated succesfully  */
  calendarUpdated = 0,
  /** The relevant calendar file has been deleted. Either because the user manually requested it, or the business logic mandated it, This should always be the case for pdcdeleted != 0  */
  calendarDeleted = 1,
  /** The calendar has been activated but not yet generated , used to remove responsibility for generating calendars from the web service to the batch job.  */
  calendarCreationPending = 2,
}
export enum SharedCalendarWebserviceDisplayStatusEnum {
  /**  The toggle is not available to the user, this is usually dictated by settings in the server, or user information  */
  notAvailable = 0,
  /**  default value if shared calendar is available  */
  isNotShared = 1,
  /**  the user has elected to make their shared calendar available as an ics file  */
  isShared = 2,
  /**  Murphy's enum, if for one reason or another the calendar becomes invalid and the user needs to be notified. This status only changes if the user manually disables the calendar or some recovery service fixes it.  */
  calendarDamaged = 3,
}
export enum ShiftAddDefFixedTypeEnum {
  /**  Proportional. Count the number of hours  */
  proportional = 0,
  /**  Fast: Count as 1 regardless of the number of hours  */
  fixed = 1,
  /**  Fast besk.dec.afh.: Count as occupation decimal (0..1) regardless of the number of hours  */
  fixedoccdec = 2,
  /**  Ugenorm: Count as weeknorm regardless of the number of hours  */
  weeknorm = 3,
  /**  Fast besk.dec.afh.*Ugenorm: Count as weeknorm * occupation decimal regardless of the number of hours  */
  fixedoccdecweeknorm = 4,
  /**  Variabel besk.dec.afh.*Ugenorm: Count as weeknorm * occupation decimal using number of hours as factor  */
  variableoccdecweeknorm = 5,
  /**  Variabel Full Ugenorm: Count as weeknorm decimal using number of hours as factor  */
  variableweeknorm = 6,
  /**  Variabel Ugenorm/besk.dec.afh.: Count as weeknorm / occupation decimal using number of hours as factor  */
  variableinverseoccdecweeknorm = 7,
  /**  Fixed Ugenorm/besk.dec.afh.: Count as weeknorm / occupation decimal regardless of the number of hours  */
  fixedinverseoccdecweeknorm = 8,
  /**  Variabel Ugenorm/besk.dec.afh.: Count as weeknorm / occupation decimal using number of hours as factor  */
  variableinverseoccdecdaynormwithmax = 9,
}
export enum ShiftaddLimitConfigEnum {
  /**  'addstart','addmin' og 'addmax' apply per dutyline  */
  perDuty = 0,
  /**  'addstart','addmin' og 'addmax' apply per shift  */
  perShift = 1,
  /**  specified in the setting shiftaddstartminmax, by default its perDuty  */
  useSetting = 2,
}
export enum ShiftCounterCalcTypeEnum {
  /**  */
  shift_CalcType = 1,
  /**  */
  avail_CalcType = 2,
}
export enum ShiftCounterSettlingEnum {
  /**  Settling is undefined  */
  shiftCounterSettlingNil = 0,
  /**  This counter is payed  */
  cash = 1,
  /**  This counter is used for time off in lieu (afspadsering)  */
  timeoff = 2,
  /**  Til afspadsering, indgår i beregningen af maks.  */
  timeoff_nonpayable = 3,
  /**  Til afspadsering, indgår i beregning af maks. og udbetal over maks. SD  */
  timeoff_payable = 4,
  /**  For pooling counters on certain reports  */
  pool = 5,
}
export enum ShiftCounterTypeEnum {
  /**  */
  shiftCounterTypeNil = 0,
  /**  Normtime. Usually with shiftcounterUnit.hourUnit. The account dimension applies by default. Included in totalplanhours. Can be used for cost details. Behaves as if shiftcounter_def.displayInShift = true  */
  norm = 1,
  /**  Holiday. Usually with shiftcounterUnit.hourUnit or shiftcounterUnit.dayUnit. Included in the vacation summary statements. Can be used for cost details.   */
  holi = 2,
  /**  Supplement. Usually with shiftcounterUnit.hourUnit or shiftcounterUnit.dayUnit. The account dimension applies by default.  */
  supp = 3,
  /**  Number. Usually with currencyUnit. The account dimension applies by default. Can be used for cost details.  */
  numb = 4,
  /**  Cost. Usually with currencyUnit. Can set shiftcounter_def.costdetails. Behaves as if shiftcounter_def.displayInShift = true. Subjected to special read access checks.   */
  cost = 7,
  /**  Statistical. Usually with shiftcounterUnit.hourUnit, but not always. Handled specially wrt clockcodes (and differently again if the shiftcounterid = 0 or the factor is negative).  */
  shiftCounterTypeElse = 9,
}
export enum ShiftCounterUnitEnum {
  /**  shiftcounter unit is unknown – should never be the case – not alloved for new shiftcounters  */
  unknownUnit = 0,
  /**  Hours  */
  hourUnit = 1,
  /**  Days  */
  dayUnit = 2,
  /**  Currency  */
  currencyUnit = 3,
  /**  Minutes  */
  minutUnit = 4,
  /**  0 or 1  */
  booleanUnit = 5,
  /**  Absolute amount of something which has no specific kind of unit  */
  quantityUnit = 6,
  /**  Unitless. Having no units of measurement, such as a ratio or percentage of two numbers which have the same unit  */
  fractionUnit = 7,
}
export enum ShiftTypeUsabilityEnum {
  /**  Ordinary shifttype/availabilitytype selectable by any user  */
  ordinary = 0,
  /**  Shifttype/Availabilitytype may only be used through external input, or by admin users  */
  externalUse = 1,
  /**  Shifttype/Availabilitytype may not be used. It is only for internal use  */
  internalUse = 2,
}
export enum ShowUIEnum {
  /**  */
  show = 1,
  /**  */
  hide = 2,
}
export enum SnapshotDeletedEnum {
  /** Export every row, no matter what pdcdeleted says */
  allRows = 1,
  /** Export rows with pdcdeleted = 0 and include rows which have been pdcdeleted in the last 90 days */
  last90Days = 2,
  /** Only export rows with pdcdeleted = 0 */
  no = 3,
  /** Only export rows with pdcdeleted = 0, and clear pdcsession and pdclasttrans */
  noAndClearPdcSession = 4,
}
export enum SpecialColumnEnum {
  /**  Ikke tilstede  */
  notPresent = 1,
  /** Ikke planlagt  */
  notPlanned = 2,
  /** Andet arbejde  */
  otherWork = 3,
  /** Andet fravær  */
  otherAbsence = 4,
  /** Anden funktion  */
  otherFunction = 5,
}
export enum SpecialVacationEarningTypeEnum {
  /**  Nothing earned  */
  nil = 0,
  /**  Proportionally month by month, rounded to two dec. Max. full amount during one year  */
  proportional2dec = 1,
  /**  Proportionally month by month full amount during one year  */
  proportional = 2,
}
export enum StaffuserAccountStatusEnum {
  /**  account is open  */
  normal = 0,
  /**  account is deleted and can never be reused - data is keept for logging purposes  */
  deleted = 1,
  /**  account is closed/paused and can be reactivated  */
  paused = 2,
}
export enum StaffUserTypeEnum {
  /**  Normal users. In systems where users are loaded from external system, they are loaded as defaultUser  */
  defaultUser = 0,
  /**  The user is NOT administered by external system, the value is only enabled when there are no integration to external systems  */
  localUser = 1,
  /**  this user is built in Staffplan user, which is used by supporters only  */
  builtInUser = 2,
  /**  The user can be ONLY used to login webservices. Authenticattion is done via windows AD */
  serviceUser = 3,
}
export enum StatementStatusEnum {
  /**  1 : obsolete From an outdated import  */
  statusObsolete = 1,
  /**  2: latest Newest statement for period  */
  statusNewest = 2,
}
export enum StepRelationEnum {
  /**  */
  stepGeq = 1,
  /**  */
  stepGt = 2,
}
export enum SubstitutionMethodEnum {
  /**  Keep ones own  */
  keep = 0,
  /**  Assume the other persons  */
  assume = 1,
  /**  Use the otherwise specified  */
  specify = 2,
}
export enum SwapIsUsedEnum {
  /**  Swap and handoverrequests are not used anywhere  */
  no = 0,
  /**  Swaps and handoverrequests can be used (and viewed in MyPlan) on persongroups with the shiftSwap property  */
  onlySwapPg = 1,
  /**  Swaps can be used in on persongroups with the shiftSwap property. Handoverrequests can be used everywhere  */
  everywhere = 2,
}
export enum SwapPolicyEnum {
  /**  */
  inheritFromHierarchy = 1,
  /**  */
  interveneNever = 2,
  /**  */
  interveneAlways = 3,
  /**  */
  interveneOnlyEmployeeRequests = 4,
}
export enum SwapStartRuleEnumEnum {
  /**  Calculate it -> udbud morgen kan ses næste dag. Udbud aften kan ses i over morgen  */
  nextDay = 1,
  /**  Use the value in setting SwapTimeBuffer  */
  fixed = 2,
}
export enum SwapStatusEnum {
  /**  */
  undefined = 0,
  /**  the request and its offer is accepted  */
  accomplished = 1,
  /**  awaits swappers / offers */
  awaitsAnswers = 2,
  /**  awaits planner approval (it might still be possible to come with more swapper offers)  */
  awaitsPlanner = 3,
  /**  */
  awaitsYou = 4,
  /**  Cancelled by requester, swapper, planner, or another swapper (not the one in the offer element)  */
  canceledBy = 5,
}
export enum SynchronizationWarningLevelEnum {
  /**  */
  noLevel = 1,
  /**  */
  informalLevel = 2,
  /**  */
  warningLevel = 3,
  /**  */
  errorLevel = 4,
}
export enum TableTypeEnum {
  /**  */
  databaseTable = 1,
  /**  metatable not directly from database table  */
  baseTable = 2,
  /**  deriveTable  */
  deriveTable = 3,
}
export enum TaskAlertTextEnumEnum {
  /**  */
  unknownAlert = -1,
  /**  */
  illegalResourceAssignment_2 = 1,
  /**  */
  partiallyIllegalResourceAssignment_3 = 2,
  /**  */
  resourceUnavailable_2 = 3,
  /**  */
  resourceNotCheckedIn_2 = 4,
  /**  */
  resourceUsed_3 = 5,
  /**  */
  resourceOverloaded_5 = 6,
  /**  */
  resourceOutOfScope_1 = 7,
  /**  */
  resourceOutOfTaskScope_2 = 8,
  /**  */
  resourceUnavailablewForPayer_3 = 9,
  /**  */
  resourceReservedElseWhere_3 = 10,
  /**  */
  notAssigned_1 = 11,
  /**  */
  notFullyAssigned_1 = 12,
  /**  */
  underAssignedWorkload_3 = 13,
  /**  */
  overAssignedWorkload_3 = 14,
  /**  */
  equipQualConstraint_2 = 15,
  /**  */
  equipQualConstraint_3 = 16,
  /**  */
  flexDurationZero = 17,
  /**  */
  routeNotFound_2 = 18,
  /**  */
  routeDurationInsuffient_4 = 19,
  /**  */
  transportTime_UnknownPreviousLocation_2 = 20,
  /**  */
  transportTime_UnknownBaseLocation_1 = 21,
  /**  */
  transportTime_Insufficient_5 = 22,
  /**  */
  transportTime_RouteNotFound_2 = 23,
  /**  */
  transportTime_NoRouteMap_1 = 24,
  /**  */
  extOrderChangeCausedDifferentRulesToFire = 25,
  /**  */
  extOrderRepositionedButStatusFrozen_1 = 26,
  /**  */
  extResourceInvalid_2 = 27,
  /**  */
  extCriticalDurConstraintViolated = 28,
  /**  */
  extTimeConstraintViolated = 29,
  /**  */
  extTimeConstraintTaskMissing_1 = 30,
  /**  */
  extTimeRestrictedToOrderScope_1 = 31,
}
export enum TaskStatusEnum {
  /** created */
  created = 0,
  /** scheduled */
  scheduled = 2,
  /** assigned */
  assigned = 3,
  /** released */
  released = 4,
  /** acknowledged */
  acknowledged = 5,
  /** started */
  started = 6,
  /** completed */
  completed = 7,
  /** cancelled */
  cancelled = 8,
}
export enum TeamAssignStrategyEnum {
  /**  Team assignment is disallowed  */
  noTeams = 1,
  /**  Team assignment is allowed, but only required resources from Team are booked  */
  assignTeams = 2,
  /**  Team assignment is allowed and all members are always booked, ie members not explicitly required by Task will be booked anonymously without a RC  */
  assignAlwaysFullTeams = 3,
}
export enum TeamShiftStatusEnum {
  /**  Arbejde med aktiviteter. original  */
  planned = 1,
  /**  Kræver handling. absence registered, but not handled: the person is absent, has activites, and the shift has at least no corresponding copyrequest which are not cancelled  */
  actionRequired = 2,
  /**  Fraværende. absent and no activities  */
  absent = 3,
  /**  Udbudt. offered: the person is absent, the shift has at least one corresponding copyrequest which is not cancelled, and none of the copyrequests are accepted or have any offers  */
  offered = 4,
  /**  Erstattet. absent, no activities, and the shift has a corresponding copyrequest which is accepted (check excpangeRegParts)  */
  replaced = 5,
  /**  Afløser. a substitute: the person is not absent, the shift has a corresponding copyrequest which is accepted (check the exchange_reg, not the exchangeRegParts)  */
  substitute = 6,
  /**  Arbejde. No activities and not absent  */
  noActivities = 7,
  /**  Vagtbytte. The shift has swapoffers that await planenr planner  */
  swapActionRequired = 8,
  /**  Vagtudbud. ???: The shift has takeoffers that await planner approval  */
  offerActionRequired = 9,
}
export enum TeamUsageConstraintEnum {
  /**  Team utilization is not restricted in any way.   */
  noRestriction = 1,
  /**  If some team members are left idle while others are assigned, then this constraint is violated and Team alert issued  */
  partialUsageNotAllowed = 2,
}
export enum TemplateTypeEnumEnum {
  /**  Individual/Personal roster  */
  individual = 0,
  /**  Collective roster  */
  collective = 1,
}
export enum TimeAttendanceDeviationEnum {
  /**  Unplanned work  */
  unplanned = 0,
  /**  Start before planned  */
  startEarly = 1,
  /**  Start later than planned */
  startLate = 2,
  /**  End before planned  */
  endEarly = 3,
  /**  End after planned  */
  endLate = 4,
}
export enum TimeAttendanceRefTypeEnum {
  /**  It concerns a shift  */
  shift = 0,
  /**  It concerns an absence  */
  absence = 1,
  /**  It concerns an assignment to a task  */
  empAssignment = 2,
  /**  It concerns an activity    */
  activity = 3,
}
export enum TimeAttendanceTypeEnum {
  /**  Clocked in  */
  clockin = 0,
  /**  Clocked out  */
  clockout = 1,
}
export enum TimingTypeEnum {
  /**  Timing Operation "recognized" by the end user  */
  ui = 0,
  /**  Internal timing  */
  detail = 1,
  /**  Timing of the entire program  */
  fullProgram = 2,
  /**  webRequest to the appServer made by the staffweb  */
  webRequest = 3,
  /**  Web Operation "recognized" by the end user  */
  web = 4,
}
export enum TotalSchemeEnum {
  /**  Old school Total tree  */
  tree = 0,
  /**  Total trees, registrations and counters (orig. for TV2)  */
  registrations = 1,
}
export enum TotalScopeEnum {
  /**  */
  all = 1,
  /**  */
  iPhone = 2,
  /**  */
  mobile = 3,
  /**  */
  web = 4,
  /**  Check in via 3rd party tool. Terminal,...  */
  checkInTerminal = 5,
  /**  For norm balance on request pages  */
  mobileNorm = 6,
}
export enum TotalTreeNodeTypeEnum {
  /**   ?  */
  undefined = -1,
  /**   A full treenode with type, label, date, value, precision, unit, Childrenlanel and children  */
  treeNode = 0,
  /**   Section header with type,Label and children  */
  sectionNode = 1,
  /**   Total header with totalid, period and children  */
  totalNode = 2,
}
export enum TransactionTimePrecisionEnum {
  /**  */
  minutePrecision = 60,
  /**  */
  dayPrecision = 1440,
  /**  */
  noPrecision = 9999,
}
export enum TransmitPeriodEnum {
  /**  Transmit absence as one record per day  */
  byDay = 0,
  /**  Transmit absence as one record per period  */
  byPeriod = 1,
}
export enum UiUserTypeEnum {
  /**  planner  */
  planner = 0,
  /**  simple user  */
  simple = 1,
  /**  advanced  */
  advanced = 2,
}
export enum UnavailabilityDegreeEnum {
  /**  */
  present = 0,
  /**  */
  callIn = 1,
  /**  */
  noCallIn = 2,
}
export enum UpdatePersonDataEnum {
  /**  person1.mobilphone  */
  phone3 = 7,
  /**  person1.email  */
  email = 8,
  /**  person1.relatives  */
  relatives = 23,
}
export enum UseDateTimeTypeEnum {
  /**  Tidspunktet skal fastholdes  */
  frozenTime = 1,
  /**  Datoen skal fastholdes, tiden skal vedligeholdes  */
  frozenDate = 2,
  /**  Tidspunktet skal vedligeholdes  */
  unfrozen = 3,
}
export enum UseInWebEnum {
  /**  Must not be used in web. (default)  */
  notInUse = 0,
  /**  May be used in web. Depending on the item type, it is computed if the item has hours (valid HHmm.minuteoffset value 0-1439, or 9999 if empty) and if the user may edit them. not used by activity_period_def  */
  inUseComputed = 1,
  /**  May be used in web. The user must not edit the hours. If there are no hours in the item definition (value 9999 in Start and End), registrations are stored with 0-24 but display as blank in the GUI.  */
  inUseNoEdit = 2,
  /**  May be used in web. The user may edit the hours. If there are no hours in the item definition (value 9999 in Start and End), the user must supply the values.  */
  inUseEdit = 3,
}
export enum UseInWebSelfPlanEnum {
  /**  Must not be used in web. (default)  */
  notInUse = 0,
  /**  May be used in web. The user must not edit the hours. If there are no hours in the item definition (value 9999 in Start and End), registrations are stored with 0-24 but display as blank in the GUI.  */
  inUseNoEdit = 1,
  /**  May be used in web. The user may edit the hours. If there are no hours in the item definition (value 9999 in Start and End), the user must supply the values.  */
  inUseEdit = 2,
  /**  May be used in web, but only for persons explicitly mentioned in prd_task_def_person. The user may edit the hours. If there are no hours in the item definition (value 9999 in Start and End), the user must supply the values.  */
  inUseEditOnlySelfPlan = 3,
}
export enum UsePayersOutsideAccessEnum {
  /**  noOutsideAccess  */
  noOutsideAccess = 0,
  /**  institutionAccess  */
  institutionAccess = 1,
  /**  the entire installation  */
  fullAccess = 2,
}
export enum UserAccountStatusEnum {
  /**  webuser currently not used  */
  nil = 0,
  /**  OK  */
  ok = 1,
  /**  */
  changePasswordOnNextLogin = 2,
  /**  */
  userLocked = 3,
  /**  */
  expired = 4,
}
export enum UserLoginTypeEnum {
  /** person1 user */
  employee = 1,
  /** staffuser user */
  planner = 2,
}
export enum UserTypeCatalogEnum {
  /** person1 user */
  employee = 1,
  /** staffuser user */
  planner = 2,
  /** type is planner ! builtin pdc/sd user  or supporter */
  supporter = 3,
  /** auxPerson */
  auxPerson = 10,
  /** External user via integration httpserver */
  external_ = 4,
  /** External user via integration httpserver */
  plannerGroup = 30,
}
export enum ValidationErrorTypeEnum {
  /**  The general installation info, is only show if other problems are shown  */
  generalInfo = 0,
  /**  The program reports minor issues  */
  minorInfo = 10,
  /**  The program gives recommendation  */
  recommendation = 40,
  /**  The program gives warning  */
  validationWarning = 60,
  /**  The program gives warning - not it's not an error */
  mandatoryWarning = 70,
  /**  The program gives warning  */
  minorError = 80,
  /**  The program stops  */
  severeError = 100,
}
export enum WebMetaTypeEnum {
  /**  */
  web_sortkey = 1,
  /**  */
  web_color = 2,
  /**  fraction size is parameter1  */
  web_float = 3,
  /**  */
  web_duration = 4,
  /**  enum name is parameter1  */
  web_enum = 5,
  /**  */
  web_boolean = 6,
  /**  length is parameter1  */
  web_text = 7,
  /**  start/end is parameter1, precision is parameter2: date/minut/second/msecond   */
  web_date = 8,
  /**  just an integer   */
  web_number = 9,
  /**  table is parameter1  */
  web_objectList = 20,
  /**  table is parameter1  */
  web_fieldReference = 30,
  /**  parameter1 is valuetype, parameter2 is metatype  */
  web_unknown = 99,
}
export enum WebmethodVoidPlaceholderEnum {
  /**  When the datamodel requires something as return, but the business logic does not return anything (void), pass along this dummy value. It does not mean anything, it just "fills the void" {^_^}  */
  voidDummy = 1,
  /**  Yes this is confusing. Sorry [-_-]  */
  aDummy_IntroducedBecauseEpDefCannotHandleSingleElementDomains = 0,
}
export enum WebSimpleTypeEnum {
  /**  */
  web_integer = 1,
  /**  */
  web_string = 2,
  /**  */
  web_real = 3,
  /**  */
  web_date = 4,
  /**  we send boolean as boolean - not integer  */
  web_boolean = 5,
  /**  we objectlist as list of JSON objects   */
  web_objectList = 20,
  /**  we send boolean as boolean - not integer  */
  web_unknown = 99,
}
export enum WorkdayStatusEnum {
  /**   No emp-workday, and no workdaystatus. Means emp has never registered here.  */
  noRegistrations = -1,
  /**  Registrations not processed (there is undeleted invalidation) (in db with no invalidation means unmergeable)  */
  unprocessed = 0,
  /**  Registrations approved by planner  */
  approved = 1,
  /**  Registrations approved by auto-consolidation (appserver)  */
  preapproved = 2,
  /**  Registrations rejected by planner  */
  rejected = 3,
  /**  Processing of workday failed. Cause of problem stated in remark field of workdayStatus_reg  */
  error = 4,
  /**  Planner has finally approved availability request. Not applicable to Checkin regs  */
  vacationApproved = 5,
  /**  Planner has previously approved availability request, but has now compromised day-off with planned work. Not applicable to Checkin regs   */
  vacationConflict = 6,
  /**  Planner has revoked a previously approved availability request, because work is required after all. Not applicable to Checkin regs  */
  vacationRevoked = 7,
}
export enum WorkplanQualityMeasureEnum {
  /**  workrule  */
  workrules = 0,
  /**  keyfigure  */
  keyfigure = 1,
}
export enum WorkruleEnumEnum {
  /**  */
  error = 0,
  /** Priority for hour11_break1we is a variable, It increases by the count of violations */
  hour11_break1we = 1,
  /**  */
  hour11_break2day = 2,
  /** Priority for hour11_break4we is a variable, It increases by the count of violations */
  hour11_break4we = 3,
  /**  */
  hour11_night_rule = 4,
  /**  */
  hour8_rule = 5,
  /**  */
  activity_max_length = 6,
  /**  */
  activity_min_length = 7,
  /**  */
  duty_length_rule = 8,
  /**  */
  free_weekend = 9,
  /**  */
  freeweekend_run_3week = 10,
  /** Priority for too_many_hours is actually variable, it increases by 1 for every 10 hours over the norm */
  too_many_hours = 11,
  /** Priority for max_workdays_per_week is a variable, It increases by the number of violating weeks */
  max_workdays_per_week = 12,
  /**  */
  not_qualified = 13,
  /**  */
  overlappingPresenceShifts = 14,
  /**  */
  protected_freedays = 15,
  /**  */
  reg_freeperiod = 16,
  /**  */
  sameday_sameshift = 17,
  /**  */
  vacation_freeweekend = 18,
  /**  */
  workdays_freedays = 19,
  /** Priority for calendarweek_freedays is a variable, It increases by the count of violations */
  calendarweek_freedays = 20,
  /**  */
  not_qualified_for_activity = 21,
  /**  */
  x_FreeDays_After_N_LongShifts = 22,
  /**  */
  x_FreeDays_After_N_DoubleShifts = 23,
  /**  */
  protected_From_To_DoubleShifts = 24,
  /**  */
  long_shift_def = 25,
  /**  */
  double_shift_def = 26,
  /**  */
  seafarer_dailyRest = 27,
  /**  */
  seafarer_weeklyRest = 28,
  /**  */
  rest_freedays = 29,
  /**  */
  hour11_break2day_excludingDoubleShifts = 30,
  /** Priority for eu48_maxhours is actually variable, it increases by 1 for each hour over the limit */
  eu48_maxhours = 31,
}
export enum WorkRuleInvalidReasonEnum {
  /**  TBC */
  reason1 = 0,
}
export enum WrCalculatedByEnum {
  /**  */
  shiftStartEndTime = 1,
  /**  */
  shiftStartEndDay = 2,
}
export enum WsAttendanceQueryTypeEnum {
  /**  only work  */
  queryOnlyWork = 0,
  /**  work and absence  */
  queryBothWorkAndAbsence = 1,
}
export enum WsTimeRegEventTypeEnum {
  /**  Import clock-in and clock-out event in one transaction  */
  create = 0,
  /**  Update clock-in and clock-out event in one transaction  */
  update = 1,
  /**  Remove time registration and associated registrations (e.g. shift, availability).  */
  delete = 2,
}
